import {
  createSlice,
  createAsyncThunk,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import { union } from "lodash";
import { GetComponentsScenarioEmployee } from "../../../application/use_cases/getComponentsScenarioEmployee";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../../domain/entities/Component";
import { createComponent, getComponentsByScenario } from "../../helpers";
import {
  updateComponents,
  updateComponent,
} from "../../helpers/ComponentHelpers";
import { ComponentAxiosRepository } from "../../implementation/componentAxiosRepository";
import { dataToCamelCase } from "../../implementation/helpers";

interface ComponentState {
  statusComponents: string;
  statusComponentsByScenario: string;
  components: Component[] | [];
  componentsByScenario: ComponentsScenarioEmployee[];
}

interface UpdateComponents {
  componentList?: ComponentsScenarioEmployee[];
  scenarioId: number;
}

interface UpdateComponent {
  componentEmployee: ComponentsScenarioEmployee;
  scenarioId: number;
}

const initialState: ComponentState = {
  components: [],
  componentsByScenario: [],
  statusComponents: "",
  statusComponentsByScenario: "",
};

export const getComponentsByScenarioThunk = createAsyncThunk(
  "components/allComponents",
  async (scenarioId: number) => {
    const components = await getComponentsByScenario(scenarioId);
    return components;
  },
);

export const getComponentsScenarioEmployee = createAsyncThunk(
  "components/componentsScenarioEmployee",
  async (scenarioId: number) => {
    const componentRepository = new ComponentAxiosRepository();
    const getComponentUseCase = new GetComponentsScenarioEmployee(
      componentRepository,
    );
    const componentList = await getComponentUseCase.run(scenarioId);
    return componentList;
  },
);

export const updateComponentsThunk = createAsyncThunk(
  "components/updateComponents",
  async ({ scenarioId }: UpdateComponents, { getState }) => {
    const state: any = getState();
    const componentsToSave = state.componentReducer.componentsByScenario;
    try {
      const result = await updateComponents(componentsToSave, scenarioId);
      const components: ComponentsScenarioEmployee[] = [];
      for (const componentResponse of result) {
        const component = dataToCamelCase(componentResponse as any);
        components.push(component as unknown as ComponentsScenarioEmployee);
      }
      return components;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const updateComponentThunk = createAsyncThunk(
  "components/updateComponent",
  async ({ scenarioId, componentEmployee }: UpdateComponent, { getState }) => {
    try {
      const result = await updateComponent(componentEmployee, scenarioId);
      const component = dataToCamelCase(result as any);
      return component as unknown as ComponentsScenarioEmployee;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const createComponentThunk = createAsyncThunk(
  "components/createComponentThunk",
  async (
    { scenarioId, componentName, subComponents, successCallback }: any,
    { getState },
  ) => {
    try {
      const componentCreated = await createComponent(
        scenarioId,
        componentName,
        subComponents,
      );
      successCallback(true);
      return componentCreated;
    } catch (error) {
      successCallback(false, String(error));
      console.log(error);
      return null;
    }
  },
);

export const componentSlice = createSlice({
  name: "componentsSlice",
  initialState,
  reducers: {
    componentsUpdate: (
      state,
      action: PayloadAction<ComponentsScenarioEmployee[]>,
    ) => {
      if (action.payload !== null) {
        const currentComponents = current(state.componentsByScenario);
        const filteredComponents = currentComponents.filter(
          (component) => component.employeeId !== action.payload[0].employeeId,
        );
        const components = union(
          filteredComponents,
          action.payload,
        ) as unknown as ComponentsScenarioEmployee[];
        state.componentsByScenario = components;
      } else {
        console.log("Error updating components");
      }
    },
    scoreComponentsUpdate: (
      state,
      action: PayloadAction<ComponentsScenarioEmployee>,
    ) => {
      if (action.payload !== null) {
        const currentComponents = current(state.componentsByScenario);
        const newComponents: ComponentsScenarioEmployee[] = [];

        currentComponents.forEach((comp) => {
          if (
            !(
              comp.componentId === action.payload.componentId &&
              comp.employeeId === action.payload.employeeId
            )
          ) {
            newComponents.push(comp);
          }
        });

        const components = union(newComponents, [
          action.payload,
        ]) as unknown as ComponentsScenarioEmployee[];
        state.componentsByScenario = components;
      } else {
        console.log("Error updating components");
      }
    },
    updateComponentsWeights: (state, action: PayloadAction<Component[]>) => {
      if (action.payload !== null) {
        state.components = action.payload;
      } else {
        console.log("Error updating components weight");
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getComponentsByScenarioThunk.pending, (state, action) => {
        state.statusComponents = "loading";
      })
      .addCase(getComponentsByScenarioThunk.fulfilled, (state, action) => {
        state.statusComponents = "succeeded";
        state.components = action.payload;
      })
      .addCase(getComponentsByScenarioThunk.rejected, (state, action) => {
        state.statusComponents = "failed";
      });

    builder
      .addCase(getComponentsScenarioEmployee.pending, (state, action) => {
        state.statusComponentsByScenario = "loading";
      })
      .addCase(getComponentsScenarioEmployee.fulfilled, (state, action) => {
        state.statusComponentsByScenario = "succeeded";
        state.componentsByScenario = action.payload;
      })
      .addCase(getComponentsScenarioEmployee.rejected, (state, action) => {
        state.statusComponentsByScenario = "failed";
      });

    builder
      .addCase(updateComponentsThunk.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.statusComponentsByScenario = "succeeded";
        } else {
          state.statusComponentsByScenario = "failed";
        }
      })
      .addCase(updateComponentsThunk.rejected, (state, action) => {
        state.statusComponentsByScenario = "failed";
      });
  },
});

export const {
  componentsUpdate,
  scoreComponentsUpdate,
  updateComponentsWeights,
} = componentSlice.actions;

export default componentSlice.reducer;
