import { CircularProgress, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

export interface CircularProgressProps {
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  disableShrink?: boolean;
  size?: number | string;
  sx?: SxProps<Theme>;
  thickness?: number;
  value?: number;
  variant?: "determinate" | "indeterminate";
}

export default function CircularProgressAtom({
  color,
  disableShrink,
  size,
  sx,
  thickness,
  value,
  variant,
}: CircularProgressProps) {
  return (
    <CircularProgress
      color={color}
      disableShrink={disableShrink}
      size={size}
      sx={sx}
      thickness={thickness}
      value={value}
      variant={variant}
    />
  );
}

CircularProgressAtom.defaultProps = {
  color: "primary",
  disableShrink: false,
  size: 40,
  thickness: 3.6,
  value: 0,
  variant: "indeterminate",
};
