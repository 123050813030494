import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import ActivateEmployeeModal from "./ActivateEmployeeModal";
import { Employee } from "../../../domain/entities/Employee";
import {
  activeDeactiveEmployee,
  getEmployees,
} from "../../../infrastructure/redux/features/employeeSlice";
import { useTypedDispatch } from "../../../infrastructure/redux/store";
import { changeStateIfAuthenticated } from "../../../utils/authChecker";
import { useNavigate } from "react-router";
import Button from "../../../atoms/Button";

interface Props {
  employee: Employee;
  scenarioId: number;
  refreshPage: () => void;
}

const ActivateEmployee = ({ employee, scenarioId, refreshPage }: Props) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { id, status } = employee;
  const employeeId = id ?? "";

  const reloadWhenDeactivate = () => {
    setShowConfirmationModal(false);
  };

  const activateUser = async () => {
    if (!status) {
      await dispatch(
        activeDeactiveEmployee({ employeeId, scenarioId, status: !status }),
      );
      void dispatch(getEmployees(scenarioId));
    }
  };

  return (
    <div>
      <Row>
        <Col md="auto">
          <Button
            className="button-primary-orange"
            onClick={() => {
              void (async () => {
                await changeStateIfAuthenticated({
                  myState: setShowConfirmationModal,
                  stateValue: true,
                  myNavigate: navigate,
                });
              })();
            }}
          >
            Activate User
          </Button>
          <ActivateEmployeeModal
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            activateUser={activateUser}
            show={showConfirmationModal}
            onClose={reloadWhenDeactivate}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ActivateEmployee;
