import UnidemensionalLayout from "../../atoms/UnidimensionalLayout";
import TitleAtom from "../../atoms/Title";
import ContainerAtom from "../../atoms/Container";
import AtomicButton from "../../atoms/Button";
import "./styles.css";
import BasicTabs from "../../molecules/BasicTabs";
import FeedBackRoundSectionBase from "../../organisms/FeedBackRoundSection";
import QuestionSection from "../../organisms/QuestionSection";
import FeedBackRoundInfo from "../../organisms/FeedBackRoundInfo";
import WarningEvaluationModal from "../../molecules/WarningEvaluationModal";
import { ItemContext } from "../../utils/feedbackRoundContext";
import { useContext, useState, useEffect } from "react";
import { evaluationServices } from "../../services/evaluationServices";
import { toast } from "react-toastify";
import { Question, QuestionsAllcategory } from "../../domain/entities/Question";
import { getUserPermissions } from "../../infrastructure/authorization";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FeedbackRoundCompletionStatus } from "../../domain/constants/FeedbackRoundCompletionStatus";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import LoadingMolecule from "../../molecules/Loading";
import { RemovedQuestionContext } from "../../utils/removeQuestionsContext";

const FeedBackRoundScreenTemp = () => {
  const navigate = useNavigate();
  const modalTitle = "Feedback round creation failed";
  const [modalBody, setModalBody] = useState<JSX.Element>();
  const { items, ClearContext, addItem } = useContext(ItemContext);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [feedbackRoundName, setFeedbackRoundName] = useState<string>("");
  const [selectedStartDate, setSelectedStartDate] = useState<string>("");
  const [selectedEndDate, setSelectedEndDate] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [clearData, setClearData] = useState(false);
  const [externalEmployee, setExternalEmployee] =
    useState<EvaluationEmployee>();
  const [feedbackRound, setFeedbackRound] = useState<FeedbackRound>();
  const [isRendered, setIsRendered] = useState<boolean>(true);
  const [isDraftDiscarded, setIsDraftDiscarded] = useState<boolean>(false);
  const { removedQuestions, ClearRemovedQuestionsContext } = useContext(
    RemovedQuestionContext,
  );
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setSelectedTab(newValue);
  };

  const fetchExternalEmployee = async () => {
    const getUserAuth = await getUserPermissions();
    const employee = await evaluationServices.getExternalEmployee(
      getUserAuth.email,
    );
    return employee;
  };

  const fetchDraftFeedbackRound = async () => {
    const draftFeedbackRound = await evaluationServices.getActiveFeedbackRound(
      "draft",
    );
    return draftFeedbackRound;
  };

  const fetchEvaluationQuestions = async (
    feedbackRoundId: string,
  ): Promise<QuestionsAllcategory> => {
    const evaluationQuestions: QuestionsAllcategory =
      await evaluationServices.getEvaluationQuestions(feedbackRoundId);
    return evaluationQuestions;
  };

  useEffect(() => {
    const fetchData = async () => {
      const draftFeedbackRound = await fetchDraftFeedbackRound();
      if (draftFeedbackRound !== undefined) {
        const evaluationQuestions = await fetchEvaluationQuestions(
          draftFeedbackRound.id,
        );
        ClearContext();
        await Promise.all([
          evaluationQuestions.peerQuestions.map((peerQuestion) =>
            addItem(peerQuestion),
          ),
          evaluationQuestions.selfQuestions.map((selfQuestion) =>
            addItem(selfQuestion),
          ),
        ]);
      }
      if (isDraftDiscarded) {
        ClearContext();
        setIsDraftDiscarded(false);
      }
      setFeedbackRound(draftFeedbackRound);
    };

    Promise.all([fetchData(), fetchExternalEmployee()])
      .then(([_, employee]) => {
        setExternalEmployee(employee);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsRendered(false);
      });
  }, [isRendered]);

  const DEFAULT_TABS = [
    {
      tabLabel: "Self evaluations",
      tabChildren: (
        <QuestionSection
          sectionName="self"
          userId={externalEmployee?.externalId ?? ""}
        />
      ),
    },
    {
      tabLabel: "Peer Evaluations",
      tabChildren: (
        <QuestionSection
          sectionName="peer"
          userId={externalEmployee?.externalId ?? ""}
        />
      ),
    },
  ];

  const lowerLimitValidation = (input: string | undefined) => {
    return Number(input) === 1;
  };

  const upperLimitValidation = (
    lowerLimit: string | undefined,
    upperLimit: string | undefined,
  ) => {
    return Number(lowerLimit) >= Number(upperLimit);
  };

  const isEmpty = (str: string | undefined) => {
    return str === "" || str === undefined;
  };

  const onCloseWarningEvaluationModal = () => {
    setShowWarningModal(false);
  };

  const buildFeedbackRound = (activeToShow: FeedbackRoundCompletionStatus) => {
    const isFeedbackRoundNotUndefined = feedbackRound !== undefined;
    const newFeedbackRound = {
      id: isFeedbackRoundNotUndefined ? feedbackRound.id : "",
      name:
        feedbackRoundName === "" && isFeedbackRoundNotUndefined
          ? feedbackRound?.name
          : feedbackRoundName,
      startDate:
        selectedStartDate === "" && isFeedbackRoundNotUndefined
          ? feedbackRound?.startDate
          : moment(selectedStartDate.toString()).format("YYYY-MM-DD HH:mm:ss"),
      endDate:
        selectedEndDate === "" && isFeedbackRoundNotUndefined
          ? feedbackRound?.endDate
          : moment(selectedEndDate.toString()).format("YYYY-MM-DD HH:mm:ss"),
      createdBy: isFeedbackRoundNotUndefined
        ? feedbackRound.createdBy
        : externalEmployee?.externalId ?? "",
      currentEvaluationsPerformed: 0,
      expectedEvaluationsToPerform: 0,
      questions: items as Question[],
      status: true,
      createdAt: isFeedbackRoundNotUndefined
        ? feedbackRound.createdAt
        : new Date().toUTCString(),
      activeToShow,
      completionPercentage: 0,
      shareFeedback: false,
      updatedBy: isFeedbackRoundNotUndefined
        ? externalEmployee?.externalId
        : "",
      updatedAt: isFeedbackRoundNotUndefined
        ? moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        : "",
    };
    return newFeedbackRound;
  };

  const handleSaveFeedbackRound = () => {
    setIsLoading(true);
    const isScoreValid: boolean[] = [];
    const areQuestionsEmpty: boolean[] = [];
    const areScoresEmpty: boolean[] = [];
    let isFeedbackRoundNameEmpty = isEmpty(feedbackRoundName);
    isFeedbackRoundNameEmpty = isFeedbackRoundNameEmpty
      ? isEmpty(feedbackRound?.name)
      : isFeedbackRoundNameEmpty;
    items.forEach((question) => {
      areQuestionsEmpty.push(isEmpty(question.description));
      if (question.category === "rating") {
        isScoreValid.push(
          lowerLimitValidation(question.scoreRangeFrom) &&
            !upperLimitValidation(
              question.scoreRangeFrom,
              question.scoreRangeTo,
            ),
        );
        areScoresEmpty.push(isEmpty(question.scoreRangeFrom));
        areScoresEmpty.push(isEmpty(question.scoreRangeTo));
      }
    });
    if (
      areQuestionsEmpty.includes(true) ||
      areScoresEmpty.includes(true) ||
      isFeedbackRoundNameEmpty
    ) {
      setModalBody(
        <p>
          There are empty mandatory fields. Please fill out all input fields to
          create a feedback round.
        </p>,
      );
      setShowWarningModal(true);
      setIsLoading(false);
      return;
    }

    if (isScoreValid.includes(false)) {
      setModalBody(
        <p>
          The score range minimum starts at 1 and the maximum should be greater
          than the minimum value.
        </p>,
      );
      setShowWarningModal(true);
      setIsLoading(false);
      return;
    }

    evaluationServices
      .createFeedBackRound(
        buildFeedbackRound(FeedbackRoundCompletionStatus.PENDING),
        false,
      )
      .then(() => {
        toast.success("Feedback round created successfully");
        ClearContext();
        setFeedbackRoundName("");
        setClearData(true);
        navigate("/your-feedback-rounds");
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveDraftFeedbackRound = () => {
    if (removedQuestions.length > 0) {
      removedQuestions.forEach((removedQuestion) => {
        evaluationServices
          .deleteDraftQuestions(
            feedbackRound?.id ?? "",
            removedQuestion.id ?? "",
            removedQuestion.type ?? "",
          )
          .catch(() => {});
      });
      ClearRemovedQuestionsContext();
    }
    evaluationServices
      .createFeedBackRound(
        buildFeedbackRound(FeedbackRoundCompletionStatus.DRAFT),
        true,
      )
      .then(() => {
        toast.success("Draft saved successfully");
        setIsRendered(true);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDiscardDraft = () => {
    evaluationServices.deleteDraft(feedbackRound?.id ?? "").finally(() => {
      toast.success("Draft discarded successfully");
      setIsRendered(true);
      setIsDraftDiscarded(true);
    });
  };

  return (
    <ContainerAtom>
      <UnidemensionalLayout className="layout-spaces" alignItems="flex-start">
        <LoadingMolecule condition={isRendered} />
        <TitleAtom variant="h4" gutterBottom={true}>
          Feedback Round
        </TitleAtom>
        <FeedBackRoundSectionBase
          evaluationTitle="Feedback round information"
          className="section-space"
        >
          {!isRendered && (
            <>
              <FeedBackRoundInfo
                setFeedbackRoundName={setFeedbackRoundName}
                setSelectEndDate={setSelectedEndDate}
                setSelectStartDate={setSelectedStartDate}
                selectStartDate={selectedStartDate}
                clearDataFeedBackRoundInfo={clearData}
                feedbackRound={feedbackRound}
              />
              <TitleAtom variant="h5" gutterBottom={true}>
                Questions
              </TitleAtom>
              <p>Each question has a limit of 250 characters</p>
              <BasicTabs
                tabInfo={DEFAULT_TABS}
                selectedTab={selectedTab}
                setSelectedTab={handleChange}
              ></BasicTabs>
            </>
          )}
        </FeedBackRoundSectionBase>
        <ContainerAtom disableGutters className="btns-container-display">
          <AtomicButton
            className="button-primary-green"
            onClick={handleSaveDraftFeedbackRound}
            disabled={isLoading}
            dataTestid="saveDraftFeedbackRoundBtn"
          >
            Save draft
          </AtomicButton>
          <AtomicButton
            className="button-primary-orange discard-btn-margin"
            onClick={handleDiscardDraft}
            disabled={isLoading}
            dataTestid="discardDraftFeedbackRoundBtn"
          >
            Discard draft
          </AtomicButton>
          <AtomicButton
            className="button-primary-light-blue create-btn-margin"
            onClick={handleSaveFeedbackRound}
            disabled={isLoading}
            dataTestid="createFeedbackRoundBtn"
          >
            Create Feedback Round
          </AtomicButton>
        </ContainerAtom>
        <WarningEvaluationModal
          modalTitle={modalTitle}
          modalBody={modalBody}
          show={showWarningModal}
          onClose={onCloseWarningEvaluationModal}
        />
      </UnidemensionalLayout>
    </ContainerAtom>
  );
};

export default FeedBackRoundScreenTemp;
