import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useUserAPI } from "../../../context/userAPIContext";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { navigateIfAuthenticated } from "../../../utils/authChecker";
import Button from "../../../atoms/Button";
import EnvManager from "../../../config/envManager";

import * as CryptoTS from "crypto-ts";

const HomeComponent = () => {
  const { checkUserAuthentication } = useUserAPI();
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkUserAuthentication();
    getIsAdmin();
  }, []);

  function getIsAdmin() {
    const userStored = CryptoTS.AES.decrypt(
      sessionStorage.getItem("user") ?? "",
      EnvManager.SESSION_STORAGE_KEY,
    ).toString(CryptoTS.enc.Utf8);
    const userStoredAsJson = JSON.parse(userStored ?? "");
    const userRoles: [string] = userStoredAsJson.roles.performance;
    if (userRoles.some((rol: string) => rol === "admin")) {
      setIsAdmin(true);
    }
  }

  return (
    <Container className="vh-100 text-center">
      <Row className="position-relative top-50 start-50 translate-middle">
        {isAdmin && (
          <Col>
            <Button
              className="button-primary-light-blue"
              dataTestid="admin"
              onClick={() => {
                void (async () => {
                  await navigateIfAuthenticated("/feedback-round", navigate);
                })();
              }}
            >
              Admin
            </Button>{" "}
          </Col>
        )}
        <Col>
          <Button
            className="button-primary-light-blue"
            dataTestid="evaluations"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated("/evaluations", navigate);
              })();
            }}
          >
            Evaluations
          </Button>{" "}
        </Col>
        {isAdmin && (
          <Col>
            <Button
              className="button-primary-light-blue"
              dataTestid="bonus"
              onClick={() => {
                void (async () => {
                  await navigateIfAuthenticated("/homeBonus", navigate);
                })();
              }}
            >
              Bonus
            </Button>{" "}
          </Col>
        )}
      </Row>
    </Container>
  );
};
export default HomeComponent;
