import { Answer } from "../../domain/entities/Answer";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";
import { Question } from "../../domain/entities/Question";

import AccordionMolecule from "../../molecules/Accordion";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import EditableAnswerRenderer from "../EditableAnswerRenderer";

import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { AccordionStates } from "../../templates/FeedbackRoundResultsScreen/FeedbackRoundResultsScreen";
import { evaluationServices } from "../../services/evaluationServices";
import LoadingMolecule from "../../molecules/Loading";

export interface PeerReviewsProps {
  questions: Question[];
  answers: Answer[];
  evaluatorEmployees: EvaluationEmployee[];
  selectedEmployee: EvaluationEmployee;
  evaluatorsRole: "giver" | "receiver";
  feedbackRoundShareFeedback: boolean;
  isOpen?: boolean;
  accordionStates: AccordionStates;
  setAccordionStates: React.Dispatch<React.SetStateAction<AccordionStates>>;
}

export default function PeerReviewsRenderer({
  questions,
  answers,
  evaluatorEmployees,
  selectedEmployee,
  evaluatorsRole,
  feedbackRoundShareFeedback,
  isOpen,
  accordionStates,
  setAccordionStates,
}: PeerReviewsProps) {
  const [editedAnswers, setEditedAnswers] = useState<[Answer[]]>([[]]);
  const [isLoading, setIsLoading] = useState(true);

  const reviewEmployees = evaluatorEmployees.filter(
    (evaluator) => evaluator.externalId !== selectedEmployee.externalId,
  );

  const toggleAccordion = (employeeId: string) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [employeeId]: !prevState[`${employeeId}`],
    }));
  };

  const fetchEditedAnswers = async (): Promise<Answer[]> => {
    const reviewEmployee = selectedEmployee.externalId;
    const employeeExternalId = reviewEmployees.map(
      (employee) => employee.externalId,
    );
    const editedAnswers = await evaluationServices.getEditedAnswers(
      questions[0]?.feedbackRoundId ?? "",
      evaluatorsRole === "giver" ? [reviewEmployee] : employeeExternalId,
    );
    return editedAnswers;
  };

  const getGiverAndReceiverEmployeeIds = (
    evaluatorEmployee: EvaluationEmployee,
  ) => {
    let giverEmployeeId = selectedEmployee.externalId;
    let receiverEmployeeId = evaluatorEmployee.externalId;
    if (evaluatorsRole === "giver") {
      giverEmployeeId = evaluatorEmployee.externalId;
      receiverEmployeeId = selectedEmployee.externalId;
    }
    return { giverEmployeeId, receiverEmployeeId };
  };

  const markEmployeesWithEditedAnswers = (editAnswers: [Answer[]]) => {
    const filteredEditedAnswers = editAnswers?.filter(
      (editedAnswer) => editedAnswer.length > 0,
    );
    reviewEmployees.map((employee) => {
      return questions.map((question) => {
        return filteredEditedAnswers?.filter((editedAnswer) => {
          return editedAnswer.filter((edited) => {
            if (
              edited.questionId === String(question.id) &&
              edited.receiverUserId ===
                getGiverAndReceiverEmployeeIds(employee).receiverEmployeeId &&
              edited.senderUserId ===
                getGiverAndReceiverEmployeeIds(employee).giverEmployeeId
            ) {
              employee.isEdited = true;
              return true;
            }
            return false;
          })[0];
        })[0];
      });
    });
  };

  useEffect(() => {
    const fetchAnswers = async () => {
      await fetchEditedAnswers()
        .then((editedAnswers: any) => {
          setEditedAnswers(editedAnswers);
          markEmployeesWithEditedAnswers(editedAnswers);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    void fetchAnswers();
  }, [answers]);

  const filteredEditedAnswers = () => {
    const filteredEditedAnswers = editedAnswers?.filter(
      (editedAnswers) => editedAnswers.length > 0,
    );
    return filteredEditedAnswers;
  };

  return (
    <>
      {isLoading ? (
        <LoadingMolecule condition={isLoading} />
      ) : (
        reviewEmployees.map((employee, index) => {
          const accordionTitle = (
            <div className={styles.flexContainer}>
              <span>
                <b>Peer:</b> {employee.name}
              </span>
              <b key={index} hidden={employee.isEdited === undefined}>
                (edited)
              </b>
            </div>
          );
          const accordionChildren = (
            <UnidimensionalLayout
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <EditableAnswerRenderer
                questions={questions}
                answers={answers}
                selectedEmployee={selectedEmployee}
                evaluatorEmployee={employee}
                shareFeedback={feedbackRoundShareFeedback}
                usersWithEditableAnswers={filteredEditedAnswers()}
                getGiverAndReceiverEmployeeIds={getGiverAndReceiverEmployeeIds}
              />
            </UnidimensionalLayout>
          );
          return (
            <AccordionMolecule
              titleProps={{
                children: accordionTitle,
                dataTestId: `answerReviewTitle-${employee.externalId}`,
              }}
              detailProps={{
                children: accordionChildren,
              }}
              key={`employee-${employee.externalId}`}
              expanded={accordionStates[employee.externalId] || isOpen}
              onChange={() => toggleAccordion(employee.externalId)}
            />
          );
        })
      )}
    </>
  );
}
