import AtomicButton from "../../atoms/Button";
import { Answer } from "../../domain/entities/Answer";
import { Question } from "../../domain/entities/Question";
import WritingQuestion from "../../molecules/WritingQuestion";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import styles from "./styles.module.css";
import { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { evaluationServices } from "../../services/evaluationServices";
import { toast } from "react-toastify";

export interface EditedAnswersProps {
  question: Question;
  testId: string;
  key: string;
  answer: Answer;
  editedAnswer: Answer[];
  shareFeedback: boolean;
  giverEmployeeId: string;
}

const EditedAnswers = ({
  question,
  testId,
  key,
  answer,
  editedAnswer,
  shareFeedback,
  giverEmployeeId,
}: EditedAnswersProps) => {
  const [editedAnswers, setEditedAnswers] = useState<boolean>(false);
  const [textAnswer, setTextAnswer] = useState<string>("");

  function createHandleEditedAnswerClick(message: string) {
    return async () => {
      try {
        await evaluationServices.putEditedAnswers(
          [
            {
              ...answer,
              textResponse: textAnswer,
            },
          ],
          question.feedbackRoundId ?? "",
        );
        if (message !== "") {
          toast.success(message);
        }
      } catch (err: any) {
        toast.error(err.message);
      }
    };
  }

  const handleSaveEditedAnswer = createHandleEditedAnswerClick(
    "Edited answer saved",
  );

  const defaultEditedAnswer = () => {
    return editedAnswer
      ?.map((edited) => {
        if (
          edited.questionId === question.id &&
          edited.senderUserId === giverEmployeeId
        ) {
          return edited.textResponse !== undefined
            ? edited.textResponse
            : answer.textResponse;
        }
        return "";
      })
      .join("");
  };

  return (
    <div>
      <div className={styles.titleContainer}>
        <WritingQuestion
          label={question.description}
          maxRows={3}
          key={key}
          dataTestId={`textAnswer-${testId}`}
          defaultValue={answer.textResponse}
          disabled={true}
        />
        <AtomicButton
          dataTestid="editFeedback"
          className={styles.thirdSpace + " button-primary-orange"}
          onClick={() => setEditedAnswers(!editedAnswers)}
          disabled={
            editedAnswers || editedAnswer !== undefined || shareFeedback
          }
        >
          <CreateOutlinedIcon />
        </AtomicButton>
      </div>
      {editedAnswers || editedAnswer !== undefined ? (
        <div className={styles.editedInputAnswer}>
          <WritingQuestion
            label={
              editedAnswer !== undefined
                ? question.description + " (edited)"
                : question.description
            }
            maxRows={3}
            key={key + "edited"}
            dataTestId={`textAnswer-${2}`}
            defaultValue={defaultEditedAnswer() ?? answer.textResponse}
            disabled={shareFeedback}
            onChange={(e) => setTextAnswer(e.target.value)}
          />
          <AtomicButton
            dataTestid="editSaveFeedback"
            className={
              styles.editedSaveAnswerButtons + " button-primary-orange"
            }
            disabled={shareFeedback}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSaveEditedAnswer}
          >
            <SaveIcon />
          </AtomicButton>
          <AtomicButton
            dataTestid="editCloseFeedback"
            className={
              styles.editedCancelAnswerButtons + " button-primary-orange"
            }
            onClick={() => setEditedAnswers(!editedAnswers)}
            disabled={editedAnswer !== undefined}
          >
            <CloseIcon />
          </AtomicButton>
        </div>
      ) : null}
    </div>
  );
};

export default EditedAnswers;
