import { useContext } from "react";
import AtomicButton from "../../atoms/Button";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import FeedBackRoundQuestion from "../FeedBackRoundQuestion";
import "./style.css";
import { ItemContext } from "../../utils/feedbackRoundContext";
import { Question } from "../../domain/entities/Question";
import { v4 as uuidv4 } from "uuid";

export interface QuestionSectionProps {
  sectionName: string;
  userId: string;
}

export default function QuestionSection({
  sectionName,
  userId,
}: QuestionSectionProps) {
  const { items: questions, addItem, updateItems } = useContext(ItemContext);

  function updateQuestionPositions() {
    updateItems((prevQuestions: Array<Partial<Question>>) => {
      const questionsOfSection = prevQuestions.filter(
        (question) => question.type === sectionName,
      );
      const updatedQuestions = questionsOfSection.map((question, index) => {
        return {
          ...question,
          positionInOrdering: index + 1,
        };
      });
      return updatedQuestions;
    });
  }

  return (
    <>
      <UnidimensionalLayout direction="column" alignItems="stretch">
        {questions
          .filter((question) => question.type === sectionName)
          .map((question, index) => {
            return (
              <FeedBackRoundQuestion
                questionNumber={index + 1}
                key={index}
                type={sectionName}
                question={question}
                updateQuestionPositions={updateQuestionPositions}
                userId={userId}
              />
            );
          })}
      </UnidimensionalLayout>
      <AtomicButton
        onClick={() => {
          const newQuestion: Partial<Question> = {
            id: uuidv4(),
            type: sectionName,
            category: "text",
            positionInOrdering:
              questions.filter((question) => question.type === sectionName)
                .length + 1,
          };
          addItem(newQuestion);
        }}
        dataTestid="addQuestionTestId"
        className="button-primary-orange add-btn-placement"
      >
        Add question
      </AtomicButton>
    </>
  );
}
