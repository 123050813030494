import { Col, Row } from "react-bootstrap";
import Button from "../../../atoms/Button";
import Modal from "../../../atoms/Modals";
import { deleteComponent } from "../../../infrastructure/helpers";
import "../../styles/componentView/createComponentModal.css";
import "../../styles/confirmationModal.css";

interface Props {
  id: number;
  name: string;
  show: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export default function ConfirmationModal({
  id,
  name,
  show,
  onClose,
  onAccept,
}: Props) {
  const handleSubmit = () => {
    deleteComponent(id).catch((error) => {
      console.log(error);
    });
  };

  const modalBody = (
    <>
      <Row id="confirmationModalRow">
        This component is already assigned to several employees.
      </Row>
      <Row id="confirmationModalRow">
        Are you sure you want to delete {name}?
      </Row>
      <Row style={{ padding: 10 }}>
        <Col className="text-center">
          <Button
            dataTestid="onAcceptDeleteModal"
            className="button-primary-light-blue"
            onClick={handleSubmit}
            onClickCapture={onAccept}
          >
            Yes
          </Button>
        </Col>
        <Col className="text-center">
          <Button
            dataTestid="onCloseDeleteModal"
            className="button-primary-light-blue"
            onClick={onClose}
          >
            No
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Modal
      open={show}
      handleClose={onClose}
      dataTestid="confirmationModal"
      modalContent={modalBody}
      classNameTitle="modal-title"
    />
  );
}
