import { Col, Navbar, Row } from "react-bootstrap";
import IoetLogo from "../IoetLogo";
import { useAppSelector } from "../../../../infrastructure/redux/hooks";
import { logoutUser } from "../../../../infrastructure/authentication";
import { useNavigate } from "react-router-dom";
import { useUserAPI } from "../../../../context/userAPIContext";
import Button from "../../../../atoms/Button";

function HeaderStructure({ children }: { children?: JSX.Element }) {
  const { checkUserAuthentication } = useUserAPI();
  const navigate = useNavigate();
  const currentScenario = useAppSelector(
    (state) => state.scenarioReducer.scenario,
  );

  const handleLogout = async () => {
    await logoutUser();
    checkUserAuthentication();
    navigate("/login");
  };

  return (
    <div className="container-fluid shadow-sm p-3">
      <Row>
        <Col>
          <Row>
            <Col md="auto">
              <Navbar.Brand href="/home">
                <IoetLogo />
              </Navbar.Brand>
              <br></br>
              <h6 data-testid="selectedScenarioName">
                {currentScenario?.name ?? ""}
              </h6>
            </Col>
          </Row>
        </Col>
        <Col md="auto">
          <br></br>
          {children}
        </Col>
        <Col>
          <Button
            className="button-primary-light-blue d-inline-block float-end edge-button"
            onClick={() => {
              void handleLogout();
            }}
          >
            Logout
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default HeaderStructure;
