import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  getComponentsByScenario,
  checkComponentById,
  deleteComponent,
  updateWeightComponents,
} from "../../../infrastructure/helpers";
import { useAppSelector } from "../../../infrastructure/redux/hooks";
import { Component } from "../../../domain/entities/Component";
import "../../styles/componentView/componentView.css";
import "../../styles/index.css";
import CreateComponentModal from "./CreateComponentModal";
import ConfirmationModal from "./ConfirmationModal";
import EditComponentModal from "./EditComponentModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  actionIfAuthenticated,
  changeStateIfAuthenticated,
} from "../../../utils/authChecker";
import { useNavigate } from "react-router";
import Table from "../../../atoms/Tables/Index";
import { getUserPermissions } from "../../../infrastructure/authorization";
import Button from "../../../atoms/Button";
import InputAtom from "../../../atoms/Input";

const ComponentView = () => {
  const navigate = useNavigate();
  const currentScenario = useAppSelector(
    (state) => state.scenarioReducer.scenario,
  );
  const [myComponents, setMyComponents] = useState<Component[]>([]);
  const [componentToEdit, setComponentToEdit] = useState<Component>({
    id: 0,
    name: "Placeholder",
    description: "",
    weight: 0,
    subComponents: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletedComponentId, setDeletedComponentId] = useState(0);
  const [deletedComponentName, setDeletedComponentName] = useState("");

  const onCloseDeleteModal = () => {
    setShowConfirmationModal(false);
  };

  const onCloseEditModal = () => {
    setShowModalEdit(false);
  };

  const onCloseCreateModal = () => {
    setShowModal(false);
  };

  const reloadComponents = () => {
    loadComponents()
      .then(() => {
        toast.success("Component created successfully");
        setShowModal(false);
        void loadComponents();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const reloadComponentsWhenDelete = () => {
    loadComponents()
      .then(() => {
        setShowConfirmationModal(false);
        setDeletedComponentName("");
        void loadComponents();
        toast.success("Component deleted successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const reloadComponentsEdit = () => {
    loadComponents()
      .then(() => {
        setShowModalEdit(false);
        void loadComponents();
        toast.success("Component edited successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const loadComponents = async () => {
    setShowModal(false);
    setShowModalEdit(false);
    const componentList = await getComponentsByScenario(
      currentScenario?.id ?? 0,
    );
    await setMyComponents(componentList);
  };

  function setIdComponentToDelete(componentId: number, componentName: string) {
    checkComponentById(componentId)
      .then((response) => {
        if (response > 0) {
          setDeletedComponentId(componentId);
          setDeletedComponentName(componentName);
          setShowConfirmationModal(true);
        } else {
          deleteComponent(componentId)
            .then(() => {
              toast.success("Component deleted successfully");
              void loadComponents();
            })
            .catch(() => {
              toast.error("Error deleting component");
            });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  useEffect(() => {
    loadComponents().catch((error) => {
      toast.error(error);
    });
  }, []);

  const weightSum = myComponents.reduce(
    (total: number, component: Component) => {
      const weight: number = component.weight;
      return (total = total + weight);
    },
    0,
  );

  function editComponent(componentId: number) {
    const componentEdit = myComponents.find((component) => {
      return component.id === componentId;
    });
    setComponentToEdit(
      componentEdit ?? {
        id: 0,
        name: "Placeholder2",
        description: "",
        weight: 0,
        subComponents: [],
      },
    );
    setShowModalEdit(true);
  }

  function updateWeightValue(value: string, componentId: number) {
    const weight = +value;

    if (weight >= 0) {
      const updatedWeightComponents = myComponents.map((component) =>
        component.id === componentId ? { ...component, weight } : component,
      );
      setMyComponents(updatedWeightComponents);
    }
  }

  function handleSubmitWeight() {
    updateWeightComponents(myComponents)
      .then((response) => {
        void loadComponents();
        toast.success(response as unknown as string);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  const columns = [
    { field: "name", headerName: "Scenario Name", width: 350 },
    {
      field: "weight",
      headerName: "Weight",
      flex: 0.3,
      minWidth: 200,
      renderCell: ({ row }: { row: Component }) => (
        <InputAtom
          size="small"
          dataTestid="inputWeight"
          type="number"
          min="1"
          max="100"
          key={row.id}
          onChange={(event) => {
            updateWeightValue(event.target.value, row.id);
          }}
          value={row.weight?.toString()}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      renderCell: ({ row }: { row: Component }) => (
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            dataTestid="editImageIcon"
            onClick={() => {
              void (async () => {
                const getUserAuth = await getUserPermissions();
                if (getUserAuth != null) {
                  editComponent(row.id);
                } else {
                  navigate("/login");
                }
              })();
            }}
          >
            Edit
          </Button>
          <Button
            dataTestid="deleteImageIcon"
            onClick={() => {
              setIdComponentToDelete(row.id, row.name);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Container className="mt-5">
        <Row>
          <Col xs={3} />
          <Col xs={4} className="title-col">
            <h3>COMPONENT VIEW</h3>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              className="button-primary-light-blue"
              dataTestid="addComponent"
              onClick={() => {
                void (async () => {
                  await changeStateIfAuthenticated({
                    myState: setShowModal,
                    stateValue: true,
                    myNavigate: navigate,
                  });
                })();
              }}
            >
              Add Component
            </Button>{" "}
            <Button
              className="button-primary-light-blue"
              dataTestid="saveWeightButton"
              onClick={() => {
                void (async () => {
                  await actionIfAuthenticated({
                    myFunction: handleSubmitWeight,
                    myNavigate: navigate,
                  });
                })();
              }}
              disabled={
                weightSum !== 100 ||
                myComponents.some((comp) => comp.weight <= 0)
              }
            >
              Save Weights
            </Button>{" "}
          </Col>
        </Row>
        {weightSum !== 100 ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <span className="error">❌ Weight total value must be 100%</span>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="d-flex justify-content-center">
              <span className="error">✅ Weight Total 100%</span>
            </Col>
          </Row>
        )}
        <CreateComponentModal
          show={showModal}
          onClose={onCloseCreateModal}
          onAccept={reloadComponents}
          onRefresh={loadComponents}
        />

        <ConfirmationModal
          id={deletedComponentId}
          name={deletedComponentName}
          show={showConfirmationModal}
          onClose={onCloseDeleteModal}
          onAccept={reloadComponentsWhenDelete}
        />
        <EditComponentModal
          disabled
          show={showModalEdit}
          data={componentToEdit}
          onClose={onCloseEditModal}
          onAccept={reloadComponentsEdit}
          onRefresh={loadComponents}
        />
      </Container>
      <Container className="abs-center-table text-center mt-3 position-relative">
        <div style={{ width: "70%", height: "90%" }}>
          <Table
            columns={columns}
            rows={myComponents}
            sortingOrder={["asc", "desc"]}
          />
        </div>
      </Container>
    </>
  );
};

export default ComponentView;
