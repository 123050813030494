import { useLocation } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const path = location.pathname;
  const legend = `Copyright © ${currentYear} - ioet, Inc. All rights reserved`;

  switch (path) {
    case "/login":
      return (
        <footer data-testid="footer" className="p-3 text-center">
          {legend}
        </footer>
      );
    default:
      return (
        <footer data-testid="footer" className="bg-light p-3 text-center">
          {legend}
        </footer>
      );
  }
}
