import { ScenarioRepository } from "../../domain/repositories/scenarioRepository";
import { BonusCalculationResponse } from "../../domain/entities/scenario";

export class GetBonusCalculation {
  private readonly scenarioRepository: ScenarioRepository;

  public constructor(scenarioRepository: ScenarioRepository) {
    this.scenarioRepository = scenarioRepository;
  }

  public async run(
    scenarioId: number,
    performanceAvailable: number,
    percentageProrated: number,
  ): Promise<BonusCalculationResponse> {
    const bonusCalculation = await this.scenarioRepository.getBonusCalculation(
      scenarioId,
      performanceAvailable,
      percentageProrated,
    );
    return bonusCalculation;
  }
}
