import {
  DateValidationError,
  DateView,
  DateViewRendererProps,
  FieldSelectedSections,
  LocalizationProvider,
  PickersInputComponentLocaleText,
} from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledEngineProvider, SxProps, Theme } from "@mui/material";
import {
  FieldChangeHandlerContext,
  PickerViewRendererLookup,
} from "@mui/x-date-pickers/internals";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const InputVariant = {
  STANDARD: "standard",
  FILLED: "filled",
  OUTLINED: "outlined",
};

export const InputSize = {
  SMALL: "small",
  MEDIUM: "medium",
  NORMAL: undefined,
};

export const DEFAULT_YEARS_PER_ROW_DESKTOP = 4;

export interface DatePickerAtomProps {
  autoFocus: boolean;
  className: string;
  closeOnSelect: boolean;
  dayOfWeekFormatter: (day: string) => string;
  defaultCalendarMonth?: any;
  defaultValue?: any;
  desktopModeMediaQuery: string;
  disabled: boolean;
  disableFuture: boolean;
  disableHighlightToday: boolean;
  disableOpenPicker: boolean;
  disablePast: boolean;
  displayWeekNumber: boolean;
  fixedWeekNumber?: number;
  format?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  label?: React.ReactNode;
  loading: boolean;
  localeText?: PickersInputComponentLocaleText<unknown>;
  maxDate?: any;
  minDate?: any;
  monthsPerRow: 3 | 4;
  onAccept?: (value: unknown) => void;
  onChange?: (value: any, context: FieldChangeHandlerContext<any>) => void;
  onClose?: () => void;
  onError?: (error: DateValidationError, value: unknown) => void;
  onMonthChange?: (month: unknown) => void;
  onOpen?: () => void;
  onSelectedSectionsChange?: (newValue: FieldSelectedSections) => void;
  onViewChange?: (view: DateView) => void;
  onYearChange?: (year: unknown) => void;
  openTo?: "day" | "month" | "year";
  orientation?: "landscape" | "portrait";
  reduceAnimations?: boolean;
  renderLoading: () => React.ReactNode;
  selectedSections?:
    | "all"
    | "day"
    | "hours"
    | "meridiem"
    | "minutes"
    | "month"
    | "seconds"
    | "weekDay"
    | "year"
    | number
    | { endIndex: number; startIndex: number };
  shouldDisableDate?: (day: unknown) => boolean;
  shouldDisableMonth?: (month: unknown) => boolean;
  shouldDisableYear?: ((year: unknown) => boolean) | undefined;
  showDaysOutsideCurrentMonth: boolean;
  slotProps: Object;
  slots: Object;
  sx?: SxProps<Theme>;
  value?: any;
  view?: "day" | "month" | "year";
  viewRenderers?: Partial<
    PickerViewRendererLookup<
      any,
      DateView,
      DateViewRendererProps<any, DateView>,
      {}
    >
  >;
  views?: Array<"day" | "month" | "year">;
  yearsPerRow?: 4 | 3;
  dataTestId?: string;
}

export default function DatePickerAtom({
  autoFocus,
  className,
  closeOnSelect,
  dayOfWeekFormatter,
  defaultCalendarMonth,
  defaultValue,
  desktopModeMediaQuery,
  disabled,
  disableFuture,
  disableHighlightToday,
  disableOpenPicker,
  disablePast,
  displayWeekNumber,
  fixedWeekNumber,
  format,
  inputRef,
  label,
  loading,
  localeText,
  maxDate,
  minDate,
  monthsPerRow,
  onAccept,
  onChange,
  onClose,
  onError,
  onMonthChange,
  onOpen,
  onSelectedSectionsChange,
  onViewChange,
  onYearChange,
  openTo,
  orientation,
  reduceAnimations,
  renderLoading,
  selectedSections,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  showDaysOutsideCurrentMonth,
  slotProps,
  slots,
  sx,
  value,
  view,
  viewRenderers,
  views,
  yearsPerRow,
  dataTestId,
}: DatePickerAtomProps) {
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          autoFocus={autoFocus}
          className={className}
          closeOnSelect={closeOnSelect}
          dayOfWeekFormatter={dayOfWeekFormatter}
          defaultCalendarMonth={defaultCalendarMonth}
          defaultValue={defaultValue}
          desktopModeMediaQuery={desktopModeMediaQuery}
          disabled={disabled}
          disableFuture={disableFuture}
          disableHighlightToday={disableHighlightToday}
          disableOpenPicker={disableOpenPicker}
          disablePast={disablePast}
          displayWeekNumber={displayWeekNumber}
          fixedWeekNumber={fixedWeekNumber}
          format={format}
          inputRef={inputRef}
          label={label}
          loading={loading}
          localeText={localeText}
          maxDate={maxDate}
          minDate={minDate}
          monthsPerRow={monthsPerRow}
          onAccept={onAccept}
          onChange={onChange}
          onClose={onClose}
          onError={onError}
          onMonthChange={onMonthChange}
          onOpen={onOpen}
          onSelectedSectionsChange={onSelectedSectionsChange}
          onViewChange={onViewChange}
          onYearChange={onYearChange}
          openTo={openTo}
          orientation={orientation}
          reduceAnimations={reduceAnimations}
          renderLoading={renderLoading}
          selectedSections={selectedSections}
          shouldDisableDate={shouldDisableDate}
          shouldDisableMonth={shouldDisableMonth}
          shouldDisableYear={shouldDisableYear}
          showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
          slotProps={slotProps}
          slots={slots}
          sx={sx}
          value={value}
          view={view}
          viewRenderers={viewRenderers}
          views={views}
          yearsPerRow={yearsPerRow}
          data-testid={dataTestId}
        />
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

DatePickerAtom.defaultProps = {
  autoFocus: false,
  className: "",
  closeOnSelect: true,
  dayOfWeekFormatter: (day: string) => day.charAt(0).toUpperCase(),
  defaultCalendarMonth: undefined,
  defaultValue: undefined,
  desktopModeMediaQuery: "@media (pointer: fine)",
  disabled: false,
  disableFuture: false,
  disableHighlightToday: false,
  disableOpenPicker: false,
  disablePast: false,
  displayWeekNumber: false,
  fixedWeekNumber: undefined,
  format: undefined,
  inputRef: undefined,
  label: undefined,
  loading: false,
  localeText: undefined,
  maxDate: undefined,
  minDate: undefined,
  monthsPerRow: 3,
  onAccept: undefined,
  onChange: undefined,
  onClose: undefined,
  onError: undefined,
  onMonthChange: undefined,
  onOpen: undefined,
  onSelectedSectionsChange: undefined,
  onViewChange: undefined,
  onYearChange: undefined,
  openTo: undefined,
  orientation: undefined,
  reduceAnimations: undefined,
  renderLoading: () => <span data-mui-test="loading-progress">...</span>,
  selectedSections: undefined,
  shouldDisableDate: undefined,
  shouldDisableMonth: undefined,
  shouldDisableYear: undefined,
  showDaysOutsideCurrentMonth: false,
  slotProps: {},
  slots: {},
  sx: undefined,
  value: undefined,
  view: undefined,
  viewRenderers: undefined,
  views: undefined,
  yearsPerRow: DEFAULT_YEARS_PER_ROW_DESKTOP,
  dataTestId: "",
};
