import { configureStore } from "@reduxjs/toolkit";
import scenarioReducer from "./features/scenarioSlice";
import componentReducer from "./features/componentsSlice";
import employeeReducer from "./features/employeeSlice";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    scenarioReducer,
    componentReducer,
    employeeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useTypedDispatch = () => {
  return useDispatch<AppDispatch>();
};
