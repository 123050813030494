import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  StyledEngineProvider,
} from "@mui/material";
import React from "react";
import "./styles.css";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  dataTestid: string;
  open: boolean;
  modalTitle: string;
  modalContent: React.ReactNode;
  modalActions: React.ReactNode;
  handleClose: () => void;
  classNameTitle: string;
  classNameContent: string;
  bootstrapTitleStyle: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  classNameTitle: string;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, classNameTitle, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className={classNameTitle}>
      {children}
      <IconButton
        data-testid="CloseIcon"
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

const Modal = (props: Props) => {
  const {
    dataTestid,
    open,
    modalTitle,
    modalContent,
    modalActions,
    handleClose,
    classNameTitle,
    bootstrapTitleStyle,
    classNameContent,
  } = props;
  return (
    <StyledEngineProvider injectFirst>
      <Dialog
        open={open}
        onClose={handleClose}
        data-testid={dataTestid}
        aria-labelledby={`general-title-${dataTestid}`}
        aria-describedby={`general-description-${dataTestid}`}
        fullWidth={bootstrapTitleStyle}
        maxWidth={bootstrapTitleStyle ? "md" : undefined}
      >
        {modalTitle !== null ? (
          bootstrapTitleStyle ? (
            <BootstrapDialogTitle
              id={`dialog-title-${dataTestid}`}
              onClose={handleClose}
              classNameTitle={classNameTitle}
            >
              {modalTitle}
            </BootstrapDialogTitle>
          ) : (
            <DialogTitle
              id={`dialog-title-${dataTestid}`}
              className={classNameTitle}
            >
              {modalTitle}
            </DialogTitle>
          )
        ) : null}
        {modalContent !== undefined && (
          <DialogContent dividers className={classNameContent}>
            {modalContent}
          </DialogContent>
        )}
        {modalActions !== null && <DialogActions>{modalActions}</DialogActions>}
      </Dialog>
    </StyledEngineProvider>
  );
};

Modal.defaultProps = {
  dataTestid: "modalId",
  open: true,
  handleClose: null,
  modalTitle: null,
  modalContent: undefined,
  modalActions: null,
  classNameTitle: "",
  bootstrapTitleStyle: false,
  classNameContent: "",
};

export default Modal;
