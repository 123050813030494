import { Answer } from "../../domain/entities/Answer";
import { Question } from "../../domain/entities/Question";
import RatingQuestion from "../../molecules/RatingQuestion";
import WritingQuestion from "../../molecules/WritingQuestion";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";

export interface QuestionRendererProps {
  questions: Question[];
  employeeId?: string;
  dataTestId: string;
  answers: Answer[];
  setAnswers: React.Dispatch<React.SetStateAction<Answer[]>>;
  senderUser: EvaluationEmployee;
  disabled?: boolean;
  newPeerAnswers?: Answer[];
  newSelfAnswers?: Answer[];
}

export default function QuestionRenderer({
  questions,
  employeeId,
  dataTestId,
  answers,
  setAnswers,
  senderUser,
  disabled,
  newPeerAnswers,
  newSelfAnswers,
}: QuestionRendererProps) {
  function isMatching(
    answer: Answer,
    questionId?: string,
    employeeId?: string,
  ) {
    if (answer.receiverUserId === answer.senderUserId) {
      return questionId === answer.questionId;
    }
    return (
      questionId === answer.questionId && employeeId === answer.receiverUserId
    );
  }
  function getResponse(
    answers: Answer[],
    response: (
      value: Answer,
      index: number,
      array: Answer[],
    ) => number | string | undefined,
    questionId?: string,
    employeeId?: string,
  ) {
    return answers
      .filter((answer) => isMatching(answer, questionId, employeeId))
      .map(response)
      .join("");
  }

  function getCurrentDateTime() {
    const millisecondsInMinute = 60000;
    const timezoneOffset =
      new Date().getTimezoneOffset() * millisecondsInMinute;
    return new Date(Date.now() - timezoneOffset)
      .toISOString()
      .replace("T", " ")
      .slice(0, -5);
  }

  const handleChangeAnswer = (
    index: number,
    textResponse: string,
    scoreResponse: number,
    senderUser: EvaluationEmployee,
    response: (
      value: Answer,
      index: number,
      array: Answer[],
    ) => number | string | undefined,
    questionId?: string,
    employeeId?: string,
    optionalEvaluation?: string,
  ) => {
    const reply = getResponse(answers, response, questionId, employeeId);
    const hasResponse = reply !== "";
    const answer = {
      questionId,
      textResponse,
      senderUserId: senderUser.externalId,
      receiverUserId:
        employeeId !== undefined ? employeeId : senderUser.externalId,
      createdBy: senderUser.name,
      updatedBy: hasResponse ? senderUser.name : "",
      updatedAt: hasResponse ? getCurrentDateTime() : "",
      scoreResponse,
      optional: optionalEvaluation === "optionalEvaluation",
    };
    setAnswers((previousAnswers) => {
      const updatedAnswers = [...previousAnswers];
      const currentAnswerIndex = Object.prototype.hasOwnProperty.call(
        updatedAnswers,
        index,
      )
        ? Math.max(...Object.keys(updatedAnswers).map(Number)) + 1
        : index;
      updatedAnswers[`${currentAnswerIndex}`] = answer;
      return updatedAnswers.reverse().filter((answer, index, self) => {
        const foundIndex = self.findIndex(
          (currentAnswer) =>
            currentAnswer !== undefined &&
            currentAnswer.questionId === answer?.questionId &&
            currentAnswer.receiverUserId === answer?.receiverUserId,
        );
        return foundIndex === index;
      });
    });
  };

  const onBlur = () => {
    const saveAnswersToLocalStorage = (
      key: string,
      answers: Answer[] | undefined,
    ) => {
      if (answers !== undefined) {
        const localStorageData = answers.map((answer: Answer) => ({
          senderUserId: answer.senderUserId,
          receiverUserId: answer.receiverUserId,
          questionId: answer.questionId,
          createdBy: answer.createdBy,
          updatedAt: answer.updatedAt,
          updatedBy: answer.updatedBy,
          textResponse: answer.textResponse,
          scoreResponse: answer.scoreResponse,
          optional: answer.optional,
        }));
        localStorage.setItem(key, JSON.stringify(localStorageData));
      }
    };

    saveAnswersToLocalStorage("storedSelfData", newSelfAnswers);
    saveAnswersToLocalStorage("storedPeerData", newPeerAnswers);
  };

  return (
    <>
      {questions.map((question, index) => {
        const key = `q${index}Employee${employeeId ?? ""}`;
        const testId = `${dataTestId}-q${index}`;
        const questionId = question.id;
        const className = question.className;

        switch (question.category) {
          case "text":
            return (
              <WritingQuestion
                label={question.description}
                placeholder={"Type your thoughts"}
                maxRows={3}
                key={key}
                className={className}
                dataTestId={testId}
                defaultValue={getResponse(
                  answers,
                  (answer) => answer.textResponse,
                  questionId,
                  employeeId,
                )}
                disabled={disabled}
                onChange={(event) => {
                  handleChangeAnswer(
                    index,
                    event.target.value,
                    0,
                    senderUser,
                    (answer) => answer.textResponse,
                    questionId,
                    employeeId,
                    dataTestId,
                  );
                }}
                onBlur={onBlur}
              />
            );
          case "rating":
            return (
              <RatingQuestion
                key={key}
                questionTitle={question.description}
                dataTestId={dataTestId}
                className={className}
                score={Number(
                  getResponse(
                    answers,
                    (answer) => answer.scoreResponse,
                    questionId,
                    employeeId,
                  ),
                )}
                maxRating={Number(question.scoreRangeTo)}
                questionId={questionId}
                questionIndex={index}
                employeeId={employeeId}
                handleChangeAnswer={handleChangeAnswer}
                senderUser={senderUser}
                disabled={disabled}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
}

QuestionRenderer.defaultProps = {
  employeeId: undefined,
  dataTestId: "questionSection",
};
