import Typography from "@mui/material/Typography";

export interface LegendProps {
  children: React.ReactNode;
  classes?: Object;
}

export default function LegendAtom({ children, classes }: LegendProps) {
  return (
    <Typography component="legend" classes={classes}>
      {children}
    </Typography>
  );
}

LegendAtom.defaultProps = {
  classes: {},
};
