import { EmployeeRepository } from "../../domain/repositories/employeeRepository";
import { Employee } from "../../domain/entities/Employee";

export class GetEmployees {
  private readonly employeeRepository: EmployeeRepository;

  public constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  public async run(scenarioId: number): Promise<Employee[]> {
    return await this.employeeRepository.getEmployees(scenarioId);
  }
}
