import Typography from "@mui/material/Typography";

export interface TitleProps {
  children: React.ReactNode;
  noWrap?: boolean;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "caption";
  variantMapping?: Object;
  gutterBottom?: boolean;
  className?: string;
  style?: React.CSSProperties;
  dataTestId?: string;
}

export default function TitleAtom({
  children,
  noWrap,
  variant,
  variantMapping,
  gutterBottom,
  className,
  style,
  dataTestId,
}: TitleProps) {
  return (
    <Typography
      variant={variant}
      noWrap={noWrap}
      variantMapping={variantMapping}
      gutterBottom={gutterBottom}
      style={style}
      className={className}
      data-testid={dataTestId}
    >
      {children}
    </Typography>
  );
}

TitleAtom.defaultProps = {
  children: "",
  noWrap: false,
  variant: "caption",
  variantMapping: {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
  },
  gutterBottom: false,
  className: undefined,
  style: {},
  dataTestId: "titleAtom",
};
