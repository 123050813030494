import { useContext } from "react";
import TitleAtom from "../../atoms/Title";
import "./styles.css";
import InputAtom from "../../atoms/Input";
import RadioGroupBase, { RadioGroupProps } from "../../molecules/RadioGroup";
import AtomicButton from "../../atoms/Button";
import ScoringRangeSelector from "../../molecules/ScoringRangeSelector";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import { ItemContext } from "../../utils/feedbackRoundContext";
import { Question } from "../../domain/entities/Question";
import moment from "moment";
import { RemovedQuestionContext } from "../../utils/removeQuestionsContext";

export interface FeedBackRoundQuestionProps {
  questionNumber: number;
  containerId?: string;
  type: string;
  question: Partial<Question>;
  updateQuestionPositions: Function;
  userId: string;
}

export default function FeedBackRoundQuestion({
  questionNumber,
  type,
  question,
  updateQuestionPositions,
  userId,
}: FeedBackRoundQuestionProps) {
  const { updateItem, removeItem } = useContext(ItemContext);
  const { addRemovedQuestion } = useContext(RemovedQuestionContext);

  const QUESTION_TYPE_RADIO_OPTIONS: RadioGroupProps = {
    legend: "Question type",
    name: "questionType",
    options: [
      {
        value: "text",
        label: "Text Input",
        labelPlacement: "end",
      },
      {
        value: "rating",
        label: "Score",
        labelPlacement: "end",
      },
    ],
  };

  const SHARING_RADIO_OPTIONS: RadioGroupProps = {
    legend: "Select sharing options",
    name: "sharingOptions",
    options:
      type === "peer"
        ? [
            {
              value: "shareAverage",
              label: "Share average",
              labelPlacement: "end",
            },
            {
              value: "shareIndividual",
              label: "Share individual score",
              labelPlacement: "end",
            },
          ]
        : [
            {
              value: "shareIndividual",
              label: "Share individual score",
              labelPlacement: "end",
            },
          ],
  };

  return (
    <div className="grid-container-round-question">
      <TitleAtom
        variant="h6"
        style={{
          gridRow: "1/2",
          gridColumn: "1/2",
          justifySelf: "start",
          alignSelf: "center",
        }}
      >
        Question {questionNumber}
      </TitleAtom>
      <AtomicButton
        className="button-primary-orange button-placement"
        size="small"
        onClick={() => {
          addRemovedQuestion(question, removeItem, updateQuestionPositions);
        }}
      >
        Delete
      </AtomicButton>
      <InputAtom
        placeholder="Question text"
        dataTestid={"question" + type + questionNumber.toString()}
        required={true}
        className="input-placement"
        onChange={(event) => {
          const isQuestionFRNotUndefined =
            question.feedbackRoundId !== undefined;
          updateItem({
            ...question,
            description: event.target.value,
            type,
            updatedAt: isQuestionFRNotUndefined
              ? moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
              : "",
            updatedBy: isQuestionFRNotUndefined ? userId : "",
          });
        }}
        value={question.description}
      />
      <RadioGroupBase
        {...QUESTION_TYPE_RADIO_OPTIONS}
        className="radio-type-placement"
        value={question.category != null ? question.category : "text"}
        onChange={(event) => {
          updateItem({
            ...question,
            category: event.target.value,
            sharingScoreOptions:
              event.target.value === "rating" ? "shareIndividual" : "",
          });
        }}
        row={true}
      />
      {question.category === "rating" ? (
        <UnidimensionalLayout
          className="grid-scoring-range-container"
          direction="row"
          justifyContent="flex-start"
          spacing={1}
        >
          <ScoringRangeSelector
            fromValue={question.scoreRangeFrom as string}
            toValue={question.scoreRangeTo as string}
            from={(event) => {
              updateItem({
                ...question,
                scoreRangeFrom: event as string,
              });
            }}
            to={(event) => {
              updateItem({
                ...question,
                scoreRangeTo: event as string,
              });
            }}
          />
          <RadioGroupBase
            {...SHARING_RADIO_OPTIONS}
            className="radio-sharing-placement"
            value={
              question.sharingScoreOptions != null
                ? question.sharingScoreOptions
                : "shareIndividual"
            }
            onChange={(event) => {
              updateItem({
                ...question,
                sharingScoreOptions: event.target.value,
              });
            }}
          />
        </UnidimensionalLayout>
      ) : (
        <></>
      )}
    </div>
  );
}

FeedBackRoundQuestion.defaultProps = {
  containerId: undefined,
  onDelete: () => {},
};
