import { ComponentRepository } from "../../domain/repositories/componentRepository";
import { AxiosResponse } from "../../domain/entities/Component";
import { SubComponent } from "../../domain/entities/subComponents";

export class CreateComponent {
  private readonly componentRepository: ComponentRepository;

  public constructor(componentRepository: ComponentRepository) {
    this.componentRepository = componentRepository;
  }

  public async run(
    scenarioId: number,
    name: string,
    subComponents: SubComponent[],
  ): Promise<AxiosResponse> {
    return await this.componentRepository.create(
      scenarioId,
      name,
      subComponents,
    );
  }
}
