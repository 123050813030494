import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import {
  Component,
  SubComponentsScenarioEmployee,
} from "../../../domain/entities/Component";
import { SubComponent } from "../../../domain/entities/subComponents";

interface Props {
  subComponents: SubComponent[];
  subComponentsForEmployee?: SubComponentsScenarioEmployee[];
  onChangeSubComponents: Function;
}

export function updateSubComponentNotes(
  ComponentList: React.MutableRefObject<Component[]>,
  value: string,
  componentIndex: number,
  subIndex: number,
) {
  const currentValues = [...ComponentList.current];

  try {
    currentValues[`${componentIndex}`].subComponents[
      `${subIndex}`
    ].description = value;
    ComponentList.current = currentValues;
  } catch (error) {
    console.log(error);
  }
}

const SubComponentListComponent = ({
  subComponents,
  subComponentsForEmployee,
  onChangeSubComponents,
}: Props) => {
  if (parseInt(subComponents?.length?.toString() ?? "0") > 0) {
    return (
      <Container>
        <Row>
          <Col className="col-align-left">
            <h4>Subcomponents</h4>
          </Col>
          <hr />
        </Row>
        {subComponents?.map((subcomponent, subComponentIndex) => {
          const subCopmponentData = subComponentsForEmployee?.find(
            (subComp) => subComp.subComponentId === subcomponent.id,
          );
          return (
            <Container key={subcomponent.name}>
              <Row>
                <Col className="col-align-left">
                  <h6>Subcomponente {subcomponent.name}</h6>
                </Col>
                <Col>
                  <span className="component-span">
                    <textarea
                      className="component-input"
                      aria-label={subcomponent.name}
                      defaultValue={subCopmponentData?.comment}
                      onChange={(event) =>
                        onChangeSubComponents(event, subComponentIndex)
                      }
                    />
                  </span>
                </Col>
              </Row>
            </Container>
          );
        })}
      </Container>
    );
  } else {
    return <Row></Row>;
  }
};

export default SubComponentListComponent;
