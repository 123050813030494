import ContainerAtom from "../../atoms/Container";
import TitleAtom from "../../atoms/Title";
import { Answer } from "../../domain/entities/Answer";
import { Question } from "../../domain/entities/Question";
import ReceivedAnswers from "../ReceivedAnswers";
import "./styles.css";

interface AnswerContainerProps {
  answers: Answer[];
  questions: Question[];
  title: string;
  className: string;
  message: string;
}

const AnswerContainerOrganism = ({
  answers,
  questions,
  title,
  className,
  message,
}: AnswerContainerProps) => {
  return (
    <ContainerAtom className={className} disableGutters>
      <TitleAtom variant="h4" gutterBottom className="started-spacing">
        {title}
      </TitleAtom>
      {answers.length > 0 && questions.length > 0 ? (
        <ReceivedAnswers receivedAnswers={answers} questions={questions} />
      ) : (
        <p className="centered-spacing">{message}</p>
      )}
    </ContainerAtom>
  );
};

export default AnswerContainerOrganism;
