import { useState } from "react";
import { createScenarioThunk } from "../../../infrastructure/redux/features/scenarioSlice";
import { useNavigate } from "react-router-dom";
import { useTypedDispatch } from "../../../infrastructure/redux/store";
import Modal from "../../../atoms/Modals";
import { useForm } from "react-hook-form";
import FormScenarioModalMolecule from "../../../molecules/FormScenarioModal";
import "../../styles/scenario/createScenarioModal.css";

export default function CreateScenarioModal(props: any) {
  const [responseAxios, setResponseAxios] = useState("");

  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { reset: resetForm } = useForm();

  const scenarioCreatedCallBack = (messageResponse: string = "") => {
    setResponseAxios(messageResponse);
    if (messageResponse.length === 0) {
      props.onCreate();
      navigate("/scenarioSelected");
    }
  };

  const dispatchChanges = (data: any) => {
    void dispatch(
      createScenarioThunk({
        scenarioName: data.scenarioNameInput,
        callBack: scenarioCreatedCallBack,
      }),
    );
    resetForm();
  };

  const modalBody = (
    <FormScenarioModalMolecule
      submitForm={dispatchChanges}
      formClassName="full-width-horizontal-form"
      controllerName="scenarioNameInput"
      setResponseError={setResponseAxios}
      divClassName="column-div"
      responseError={responseAxios}
      buttonClassName="button-primary-light-blue float-end"
      button="Create"
    ></FormScenarioModalMolecule>
  );

  return (
    <Modal
      open={props.show}
      handleClose={props.close}
      dataTestid="createScenarioModal"
      modalTitle="Create New Scenario"
      modalContent={modalBody}
      classNameTitle="modal-title"
      bootstrapTitleStyle={true}
    />
  );
}
