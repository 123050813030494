import { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import EmployeeListComponent from "./EmployeeListComponent";
import EmployeeDetailComponent from "./EmployeeDetailComponent";
import {
  getComponentsByScenarioThunk,
  getComponentsScenarioEmployee,
} from "../../../infrastructure/redux/features/componentsSlice";
import { getEmployees } from "../../../infrastructure/redux/features/employeeSlice";
import {
  useTypedSelector,
  useTypedDispatch,
} from "../../../infrastructure/redux/store";
import LoadingComponent from "../LoadingComponent";

const EmployeeDetailContainer = () => {
  const dispatch = useTypedDispatch();

  const components = useTypedSelector(
    (state) => state.componentReducer.components,
  );
  const employeeList = useTypedSelector(
    (state) => state.employeeReducer.employees,
  );
  const componentsByScenario = useTypedSelector(
    (state) => state.componentReducer.componentsByScenario,
  );
  const loadingEmployees = useTypedSelector(
    (state) => state.employeeReducer.status,
  );
  const loadingComponents = useTypedSelector(
    (state) => state.componentReducer.statusComponents,
  );
  const loadingComponentsByScenario = useTypedSelector(
    (state) => state.componentReducer.statusComponentsByScenario,
  );
  const scenario = useTypedSelector((state) => state.scenarioReducer.scenario);

  const [currentEmployee, setCurrentEmployee] = useState(employeeList[0]);

  useEffect(() => {
    if (components.length <= 0)
      void dispatch(getComponentsByScenarioThunk(scenario?.id ?? 0));
    if (employeeList.length <= 0)
      void dispatch(getEmployees(scenario?.id ?? 0));
    if (componentsByScenario.length <= 0)
      void dispatch(getComponentsScenarioEmployee(scenario?.id ?? 0));
  }, []);

  useEffect(() => {
    setCurrentEmployee(employeeList[0]);
  }, [employeeList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentEmployee]);

  if (loadingEmployees === "loading") return <LoadingComponent />;

  return (
    <Container>
      <Row>
        <Col lg="4" sm="2">
          <EmployeeListComponent
            currentEmployee={currentEmployee}
            employeeList={employeeList}
            selectEmployee={setCurrentEmployee}
            componentsByScenario={componentsByScenario}
            allComponents={components}
          />
        </Col>
        <Col>
          <EmployeeDetailComponent
            employee={currentEmployee}
            components={components}
            loadingComponents={loadingComponents}
            componentsByScenario={componentsByScenario}
            loadingComponentsByScenario={loadingComponentsByScenario}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeDetailContainer;
