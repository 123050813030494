import React from "react";
import InputAtom from "../../atoms/Input";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import Button from "../../atoms/Button";

interface FormScenarioModalProps {
  submitForm: SubmitHandler<FieldValues>;
  formClassName: string;
  controllerName: string;
  setResponseError: React.Dispatch<React.SetStateAction<string>>;
  divClassName: string;
  responseError: string;
  buttonClassName: string;
  button: string | React.ReactElement;
  inputAtomClassName?: string;
  currentScenarioName?: string;
}

export default function FormScenarioModalMolecule({
  submitForm,
  formClassName,
  controllerName,
  setResponseError,
  divClassName,
  responseError,
  buttonClassName,
  button,
  inputAtomClassName,
  currentScenarioName,
}: FormScenarioModalProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const isScenarioNameInput = controllerName === "scenarioNameInput";
  const error = isScenarioNameInput
    ? Boolean(errors?.scenarioNameInput)
    : Boolean(errors?.editedScenarioNameInput);
  const helperText = isScenarioNameInput
    ? errors.scenarioNameInput?.message?.toString()
    : errors.editedScenarioNameInput?.message?.toString();

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(submitForm)}
      data-testid="scenario-form"
      className={formClassName}
    >
      <Controller
        control={control}
        name={controllerName}
        rules={{
          onChange: () => setResponseError(""),
          required: {
            value: true,
            message: "Please enter a name",
          },
          pattern: {
            value: /[A-Za-z0-9_\-#]{3,}/,
            message: "Please enter a valid name of scenario",
          },
        }}
        render={({ field: { onChange, name } }) => {
          return (
            <div className={divClassName}>
              <InputAtom
                name={name}
                className={inputAtomClassName}
                label="Scenario name"
                onChange={onChange}
                dataTestid="text-input"
                type="text"
                placeholder="ex: bonus-dec-2022-#12sd"
                autoFocus={true}
                error={error}
                helperText={helperText}
                defaultValue={currentScenarioName}
              />
              {responseError.length > 0 && (
                <span
                  className="error-message"
                  data-testid="newScenarioErrorMessage"
                >
                  {responseError}
                </span>
              )}
            </div>
          );
        }}
      />
      <Button
        dataTestid="newScenarioButton"
        type="submit"
        className={buttonClassName}
      >
        {button}
      </Button>
    </form>
  );
}
