import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import Button from "../../atoms/Button";

export interface NextAndPreviousActionProps {
  onNext: () => void;
  onPrevious: () => void;
  nextLabel: string;
  previousLabel: string;
  className?: string;
  disabled: {
    previous: boolean;
    next: boolean;
  };
}

export default function NextAndPreviousActions({
  onNext,
  onPrevious,
  nextLabel,
  previousLabel,
  className,
  disabled,
}: NextAndPreviousActionProps) {
  const { previous: isPrevDisabled, next: isNextDisabled } = disabled;
  return (
    <UnidimensionalLayout
      direction="row"
      spacing={1}
      className={className ?? ""}
    >
      <Button
        onClick={onPrevious}
        className="button-primary-light-blue"
        disabled={isPrevDisabled}
      >
        {previousLabel}
      </Button>
      <Button
        onClick={onNext}
        className="button-primary-light-blue"
        disabled={isNextDisabled}
      >
        {nextLabel}
      </Button>
    </UnidimensionalLayout>
  );
}

NextAndPreviousActions.defaultProps = {
  disabled: {
    previous: false,
    next: false,
  },
};
