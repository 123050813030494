import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridSlotsComponent,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowId,
  GridSortDirection,
} from "@mui/x-data-grid";
import AtomicButton from "../Button";
import { useState } from "react";
import "./Style.css";

export interface Props {
  columns: GridColDef[];
  rows: GridRowsProp;
  sortingOrder: GridSortDirection[];
  pageSize?: number;
  components?: Partial<GridSlotsComponent> | undefined;
  autoHeight?: boolean;
  className?: string;
  onSelectionModelChangeCallback?: (data: any) => void;
  checkboxSelection?: boolean;
  disableSelectionOnClick?: boolean;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
  approveButton?: () => void;
  denyButton?: () => void;
}

const Table = (props: Props) => {
  const {
    columns,
    rows,
    sortingOrder,
    pageSize,
    components,
    autoHeight,
    className,
    onSelectionModelChangeCallback,
    checkboxSelection = false,
    disableSelectionOnClick = true,
    getRowHeight,
    approveButton,
    denyButton,
  } = props;

  const [rowSelection, setRowSelection] = useState<GridRowId[]>([]);

  return (
    <>
      {rowSelection?.length > 0 && (
        <div className="container-bulk-actions bg-light">
          <span>{`${rowSelection?.length} selected`}</span>
          <div className="container-buttons">
            <AtomicButton
              type="button"
              size="small"
              className="button-primary-green"
              onClick={approveButton}
            >
              Approve selected
            </AtomicButton>

            <AtomicButton
              type="button"
              size="small"
              className="button-primary-orange"
              onClick={denyButton}
            >
              Deny selected
            </AtomicButton>
          </div>
        </div>
      )}
      <DataGrid
        {...props}
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        components={components}
        autoHeight={autoHeight}
        className={className}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick={disableSelectionOnClick}
        onSelectionModelChange={(newRowSelectionModel) => {
          setRowSelection(newRowSelectionModel);
          onSelectionModelChangeCallback?.(newRowSelectionModel);
        }}
        getRowHeight={getRowHeight}
        sortingOrder={sortingOrder}
      />
    </>
  );
};

export default Table;
