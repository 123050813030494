import {
  ListItemText,
  ListItemTextClasses,
  SxProps,
  Theme,
  TypographyProps,
} from "@mui/material";

interface ListItemTextProps {
  primary: string;
  onClick: () => void;
  classes?: Partial<ListItemTextClasses>;
  disableTypography?: boolean;
  inset?: boolean;
  primaryTypographyProps?: TypographyProps<
    "span",
    {
      component?: "span";
    }
  >;
  secondary?: React.ReactNode;
  secondaryTypographyProps?: TypographyProps<
    "p",
    {
      component?: "p";
    }
  >;
  sx?: SxProps<Theme>;
}

const ListItemTextAtom = ({
  primary,
  onClick,
  classes,
  disableTypography,
  inset,
  primaryTypographyProps,
  secondary,
  secondaryTypographyProps,
  sx,
}: ListItemTextProps) => {
  return (
    <ListItemText
      primary={primary}
      onClick={onClick}
      classes={classes}
      disableTypography={disableTypography}
      inset={inset}
      primaryTypographyProps={primaryTypographyProps}
      secondary={secondary}
      secondaryTypographyProps={secondaryTypographyProps}
      sx={sx}
    />
  );
};

export default ListItemTextAtom;
