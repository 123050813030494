import { useNavigate } from "react-router";
import { navigateIfAuthenticated } from "../../../utils/authChecker";

const ScenarioHeader = () => {
  const navigate = useNavigate();
  return (
    <div>
      <a
        className="redirect"
        href="/"
        data-testid="navigateToHome"
        onClick={() => {
          void (async () => {
            await navigateIfAuthenticated("/", navigate);
          })();
        }}
      >
        Home
      </a>
    </div>
  );
};

export default ScenarioHeader;
