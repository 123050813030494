import { ScenarioRepository } from "../../domain/repositories/scenarioRepository";
import {
  Scenario,
  BonusCalculationResponse,
} from "../../domain/entities/scenario";
import PerformanceApi from "./performanceApi";

export class ScenarioAxiosRepository implements ScenarioRepository {
  private readonly performanceApi = new PerformanceApi();

  async edit(id: number, name: string): Promise<Scenario> {
    const response = await this.performanceApi.editScenario(id, name);

    const editedScenario: Scenario = {
      id: response.body.id,
      name: response.body.name,
      generalBonus: response.body.general_bonus,
      performanceBonus: response.body.performance_bonus,
      payrollProrated: response.body.payroll_prorated,
      creationDate: new Date(response.body.creation_date).toLocaleDateString(),
      updatedDate: new Date(response.body.updated_date).toLocaleDateString(),
      createdBy: response.body.created_by,
      updatedBy: response.body.updated_by,
    };
    return editedScenario;
  }

  async create(name: string): Promise<Scenario> {
    const resp = await this.performanceApi.createScenario(name);
    const createdScenario: Scenario = {
      id: resp.body.id,
      name: resp.body.name,
      generalBonus: resp.body.general_bonus,
      performanceBonus: resp.body.performance_bonus,
      payrollProrated: resp.body.payroll_prorated,
      creationDate: new Date(resp.body.creation_date).toLocaleDateString(),
      updatedDate: new Date(resp.body.updated_date).toLocaleDateString(),

      createdBy: resp.body.created_by,
      updatedBy: resp.body.updated_by,
    };
    return createdScenario;
  }

  async editPreferences(
    id: number,
    generalBonus: number,
    performanceBonus: number,
    payrollProrated: number,
  ): Promise<Scenario> {
    const response = await this.performanceApi.editPreferences(
      id,
      generalBonus,
      performanceBonus,
      payrollProrated,
    );

    const editedScenario: Scenario = {
      id: response.body.id,
      name: response.body.name,
      generalBonus: response.body.general_bonus,
      performanceBonus: response.body.performance_bonus,
      payrollProrated: response.body.payroll_prorated,
      creationDate: new Date(response.body.creation_date).toLocaleDateString(),
      updatedDate: new Date(response.body.updated_date).toLocaleDateString(),

      createdBy: response.body.created_by,
      updatedBy: response.body.updated_by,
    };
    return editedScenario;
  }

  async get(id: number): Promise<Scenario> {
    const response = await this.performanceApi.getScenario(id);
    const createdScenario: Scenario = {
      id: response.body?.id,
      name: response.body?.name,
      generalBonus: response.body?.general_bonus,
      performanceBonus: response.body?.performance_bonus,
      payrollProrated: response.body?.payroll_prorated,
      creationDate: new Date(response.body?.creation_date).toLocaleDateString(),
      updatedDate: new Date(response.body?.updated_date).toLocaleDateString(),

      createdBy: response.body?.created_by,
      updatedBy: response.body?.updated_by,
    };
    return createdScenario;
  }

  async getScenarios(): Promise<Scenario[]> {
    const scenariosResponse = await this.performanceApi.getScenarios();
    const responseScenarios: Scenario[] = scenariosResponse.body.map(
      (scenario: any) => {
        return {
          id: scenario.id,
          name: scenario.name,
          generalBonus: scenario.general_bonus,
          performanceBonus: scenario.performance_bonus,
          payrollProrated: scenario.payroll_prorated,
          creationDate: new Date(scenario.creation_date).toLocaleDateString(),
          updatedDate: new Date(scenario.updated_date).toLocaleDateString(),
          createdBy: scenario.created_by,
          updatedBy: scenario.updated_date,
        };
      },
    );
    return responseScenarios;
  }

  async getBonusCalculation(
    scenarioId: number,
    performanceAvailable: number,
    percentageProrated: number,
  ): Promise<BonusCalculationResponse> {
    const response = await this.performanceApi.getBonusCalculation(
      scenarioId,
      performanceAvailable,
      percentageProrated,
    );
    return response.body;
  }

  async deleteScenario(
    scenarioId: number,
    scenarioName: string,
  ): Promise<string> {
    const resp = await this.performanceApi.deleteScenario(
      scenarioId,
      scenarioName,
    );
    const componentResponse: string = resp.message;
    return componentResponse;
  }
}
