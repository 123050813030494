import { getUserPermissions } from "../infrastructure/authorization";
import { NavigateFunction } from "react-router-dom";

export const navigateIfAuthenticated = async (
  navigatedRoute: string,
  myNavigate: NavigateFunction,
  props?: object,
) => {
  const getUserAuth = await getUserPermissions();
  if (getUserAuth != null) {
    myNavigate(navigatedRoute);
  } else {
    myNavigate("/login");
  }
};

interface AuthFunction {
  myFunction: any;
  functionValue?: any;
  myNavigate: NavigateFunction;
}

export const actionIfAuthenticated = async ({
  myFunction,
  myNavigate,
}: AuthFunction) => {
  const getUserAuth = await getUserPermissions();
  if (getUserAuth != null) {
    myFunction();
  } else {
    myNavigate("/login");
  }
};

interface AuthState {
  myState: React.Dispatch<React.SetStateAction<any>>;
  stateValue: any;
  myNavigate: NavigateFunction;
}

export const changeStateIfAuthenticated = async ({
  myState,
  stateValue,
  myNavigate,
}: AuthState) => {
  const getUserAuth = await getUserPermissions();
  if (getUserAuth != null) {
    myState(stateValue);
  } else {
    myNavigate("/login");
  }
};
