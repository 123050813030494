import PaperSurfaceAtom from "../../atoms/PaperSurface";
import TitleAndCaptionMolecule from "../../molecules/TitleAndCaption";
import UnidemensionalLayout from "../../atoms/UnidimensionalLayout";
import "./styles.css";

export interface EvaluationSectionProps {
  evaluationTitle: string;
  evaluationDescription: string;
  className?: string;
  children?: React.ReactNode;
}

export default function EvaluationSectionBase({
  evaluationTitle,
  evaluationDescription,
  className,
  children,
}: EvaluationSectionProps) {
  return (
    <PaperSurfaceAtom
      className={`grid-container ${className ?? ""}`}
      square={true}
      variant="outlined"
      elevation={0}
    >
      <TitleAndCaptionMolecule
        titleText={evaluationTitle}
        caption={evaluationDescription}
        style={{
          gridColumn: "1/2",
          gridRow: "1/2",
          justifySelf: "start",
          alignSelf: "self-start",
          fontSize: "14px",
          marginBottom: "14px",
        }}
      />
      <UnidemensionalLayout
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        style={{
          gridColumn: "1/3",
          gridRow: "2/3",
          paddingTop: "1%",
        }}
      >
        {children}
      </UnidemensionalLayout>
    </PaperSurfaceAtom>
  );
}
