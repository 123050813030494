import Button from "../../atoms/Button";
import "./styles.css";

interface FormBonusPreferencesProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  bonusInputField: (
    describedText: string,
    state: string,
    setState?: React.Dispatch<React.SetStateAction<string>>,
  ) => JSX.Element;
  performanceBonus: string;
  setPerformanceBonus: React.Dispatch<React.SetStateAction<string>>;
  bonusFormat: (value: string | number | undefined) => string;
  generalBonusAvailable: number | undefined;
  generalBonusPercentage: string;
  setGeneralBonusPercentage: React.Dispatch<React.SetStateAction<string>>;
  totalBonus: string;
}

export default function FormBonusPreferencesMolecule({
  handleSubmit,
  bonusInputField,
  performanceBonus,
  setPerformanceBonus,
  bonusFormat,
  generalBonusAvailable,
  generalBonusPercentage,
  setGeneralBonusPercentage,
  totalBonus,
}: FormBonusPreferencesProps) {
  return (
    <form onSubmit={handleSubmit}>
      <div id="table">
        {bonusInputField(
          "$ Performance bonus available:",
          performanceBonus,
          setPerformanceBonus,
        )}
        <div className="row-preference">
          <p>$ General bonus:</p>
          <p data-testid="generalBonus" className="text-value-preference">
            {bonusFormat(generalBonusAvailable)}
          </p>
        </div>

        {bonusInputField(
          "General bonus, % of payroll:",
          generalBonusPercentage,
          setGeneralBonusPercentage,
        )}

        <div className="row-preference">
          <p>$ Total Bonus:</p>
          <p data-testid="totalBonus" className="text-value-preference">
            {bonusFormat(totalBonus)}
          </p>
        </div>
        <div className="button-preference">
          <Button
            dataTestid="saveBonusPreferences"
            className="button-secondary"
            type="submit"
            disabled={generalBonusPercentage === ""}
          >
            Save Preferences
          </Button>
        </div>
      </div>
    </form>
  );
}
