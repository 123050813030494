import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { navigateIfAuthenticated } from "../../../utils/authChecker";

const ComponentViewHeader = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Row>
        <Col md="auto">
          <a
            className="redirect"
            data-testid="navigateToHome"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated("/", navigate);
              })();
            }}
          >
            Home
          </a>
        </Col>
        <Col md="auto">
          <a
            className="redirect"
            data-testid="navigateToScenarioMenu"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated("/scenarioSelected", navigate);
              })();
            }}
          >
            Scenario menu
          </a>
        </Col>
        <Col md="auto">
          <a
            data-testid="navigateToOptions"
            className="redirect"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated("/", navigate);
              })();
            }}
          >
            Options
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default ComponentViewHeader;
