import TextField from "@mui/material/TextField";
import { StyledEngineProvider } from "@mui/material";
import "./styles.css";

export const TextAreaColors = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
};

export const TextAreaSize = {
  SMALL: "small",
  MEDIUM: "medium",
};

export const TextAreaVariant = {
  STANDARD: "standard",
  FILLED: "filled",
  OUTLINED: "outlined",
};

export const DEFAULT_ROW_NUMBER = 1;

export interface TextAreaProps {
  autoFocus?: boolean;
  classes?: Object;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  defaultValue?: any;
  disabled?: boolean;
  error?: boolean;
  FormHelperTextProps?: Object;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  label?: React.ReactNode;
  maxRows?: number | string;
  minRows?: number | string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number | string;
  size: "small" | "medium";
  value?: any;
  variant: "standard" | "filled" | "outlined";
  style?: React.CSSProperties;
  InputProps?: Object;
  inputProps?: Object;
  dataTestId?: string;
  className?: string;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
}

export default function TextAreaAtom({
  autoFocus,
  classes,
  color,
  defaultValue,
  disabled,
  error,
  FormHelperTextProps,
  fullWidth,
  helperText,
  id,
  label,
  maxRows,
  minRows,
  name,
  placeholder,
  required,
  rows,
  size,
  value,
  variant,
  style,
  dataTestId,
  InputProps,
  inputProps,
  className,
  onBlur,
  onChange,
}: TextAreaProps) {
  return (
    <StyledEngineProvider injectFirst>
      <TextField
        multiline
        InputProps={InputProps}
        inputProps={inputProps}
        rows={rows}
        autoFocus={autoFocus}
        classes={classes}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        FormHelperTextProps={FormHelperTextProps}
        fullWidth={fullWidth}
        helperText={helperText}
        id={id}
        label={label}
        maxRows={maxRows}
        minRows={minRows}
        className={className}
        name={name}
        placeholder={placeholder}
        required={required}
        size={size}
        value={value}
        style={style}
        color={color}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={dataTestId}
      />
    </StyledEngineProvider>
  );
}

TextAreaAtom.defaultProps = {
  autoFocus: false,
  classes: {},
  color: TextAreaColors.PRIMARY,
  defaultValue: undefined,
  disabled: false,
  error: false,
  FormHelperTextProps: {},
  fullWidth: false,
  helperText: undefined,
  id: undefined,
  label: undefined,
  maxRows: undefined,
  minRows: undefined,
  name: undefined,
  placeholder: undefined,
  required: false,
  rows: undefined,
  size: TextAreaSize.SMALL,
  value: undefined,
  variant: TextAreaVariant.OUTLINED,
  style: {},
  dataTestId: "textAreaInput",
  InputProps: {},
  inputProps: {},
  onChange: () => {},
  onBlur: () => {},
};
