import React, { useEffect, useState } from "react";
import { editScenarioPreferecesThunk } from "../../infrastructure/redux/features/scenarioSlice";
import { useAppSelector } from "../../infrastructure/redux/hooks";
import { useTypedDispatch } from "../../infrastructure/redux/store";
import FormBonusPreferencesMolecule from "../../molecules/FormBonusPreferences";
import BonusInputFieldMolecule from "../../molecules/BonusInputField";
import ContainerBonusPreferencesMolecule from "../../molecules/ContainerBonusPreferences";

interface BonusPreferencesProps {
  generalBonusAvailable: number | undefined;
  onNotify: (message: { success: string; error: string }) => void;
}

const BonusPreferences = ({
  generalBonusAvailable,
  onNotify,
}: BonusPreferencesProps) => {
  const dispatch = useTypedDispatch();
  const currentScenario = useAppSelector(
    (state) => state.scenarioReducer.scenario,
  );

  const [performanceBonus, setPerformanceBonus] = useState("");
  const [generalBonusPercentage, setGeneralBonusPercentage] = useState("");
  const [generalBonus, setGeneralBonus] = useState("0");
  const [totalBonus, setTotalBonus] = useState("0");
  const message = { success: "", error: "" };

  const showSuccessMessage = () => {
    message.success = "scenario updated successfully";
    onNotify(message);
  };

  useEffect(() => {
    if (currentScenario != null) {
      setPerformanceBonus(String(currentScenario.performanceBonus));
      setGeneralBonus(String(currentScenario.generalBonus));
      setGeneralBonusPercentage(String(currentScenario.payrollProrated));
    }
  }, []);

  useEffect(() => {
    setGeneralBonus(String(generalBonusAvailable));
    const newPerformanceBonus = performanceBonus.replace(/,/g, "");
    const total =
      (Number(newPerformanceBonus) | 0) + (Number(generalBonusAvailable) | 0);
    setTotalBonus(total.toString());
  }, [performanceBonus, generalBonusAvailable]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newPerformanceBonus = performanceBonus.replace(/,/g, "");
    const newGeneralBonus = generalBonusPercentage.replace(/,/g, "");
    void dispatch(
      editScenarioPreferecesThunk({
        scenarioId: Number(currentScenario?.id),
        generalBonus: Number(generalBonus),
        newPerformanceBonus: Number(newPerformanceBonus),
        newGeneralBonus: Number(newGeneralBonus),
        successCallback: showSuccessMessage,
      }),
    );
  };

  const bonusFormat = (value: string | number | undefined) => {
    const newValue = parseFloat(Number(value).toFixed(2));
    return new Intl.NumberFormat().format(newValue);
  };

  return (
    <ContainerBonusPreferencesMolecule
      form={
        <FormBonusPreferencesMolecule
          handleSubmit={handleSubmit}
          bonusInputField={BonusInputFieldMolecule}
          performanceBonus={performanceBonus}
          setPerformanceBonus={setPerformanceBonus}
          bonusFormat={bonusFormat}
          generalBonusAvailable={generalBonusAvailable}
          generalBonusPercentage={generalBonusPercentage}
          setGeneralBonusPercentage={setGeneralBonusPercentage}
          totalBonus={totalBonus}
        ></FormBonusPreferencesMolecule>
      }
    ></ContainerBonusPreferencesMolecule>
  );
};

export default BonusPreferences;
