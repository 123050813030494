import { Answer } from "../../domain/entities/Answer";
import { Question } from "../../domain/entities/Question";
import RatingQuestion from "../../molecules/RatingQuestion";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";
import EditedAnswers from "../EditedAnswers";
import WritingQuestion from "../../molecules/WritingQuestion";

export interface EditableAnswerRendererProps {
  questions: Question[];
  answers: Answer[];
  selectedEmployee: EvaluationEmployee;
  evaluatorEmployee: EvaluationEmployee;
  shareFeedback: boolean;
  usersWithEditableAnswers: Answer[][];
  getGiverAndReceiverEmployeeIds: (evaluatorEmployee: EvaluationEmployee) => {
    giverEmployeeId: string;
    receiverEmployeeId: string;
  };
}

export default function EditableAnswerRenderer({
  questions,
  answers,
  selectedEmployee,
  evaluatorEmployee,
  shareFeedback,
  usersWithEditableAnswers,
  getGiverAndReceiverEmployeeIds,
}: EditableAnswerRendererProps) {
  const generateKeyAndTestId = (questionId: string) => {
    const { giverEmployeeId, receiverEmployeeId } =
      getGiverAndReceiverEmployeeIds(evaluatorEmployee);
    const generatedId = `${questionId}-${receiverEmployeeId}-${giverEmployeeId}`;
    return {
      key: generatedId,
      testId: generatedId,
    };
  };

  const filteredAnswers = answers.filter((answer) => {
    const { giverEmployeeId, receiverEmployeeId } =
      getGiverAndReceiverEmployeeIds(evaluatorEmployee);
    return (
      answer.senderUserId === giverEmployeeId &&
      answer.receiverUserId === receiverEmployeeId
    );
  });

  return (
    <>
      {questions.map((question, index) => {
        const { giverEmployeeId } =
          getGiverAndReceiverEmployeeIds(evaluatorEmployee);
        const { key, testId } = generateKeyAndTestId(String(question.id));
        const answer = filteredAnswers.filter(
          (answer) => answer.questionId === String(question.id),
        )[0];
        const editedAnswer = usersWithEditableAnswers?.filter(
          (editedAnswer) => {
            return editedAnswer.filter((edited) => {
              if (
                edited.questionId === String(question.id) &&
                edited.receiverUserId ===
                  getGiverAndReceiverEmployeeIds(evaluatorEmployee)
                    .receiverEmployeeId &&
                edited.senderUserId ===
                  getGiverAndReceiverEmployeeIds(evaluatorEmployee)
                    .giverEmployeeId
              ) {
                evaluatorEmployee.isEdited = true;
                return true;
              }
              return false;
            })[0];
          },
        )[0];
        let category = question.category;
        if (answer === undefined) {
          category = "N/A";
        }
        switch (category) {
          case "text":
            return (
              <EditedAnswers
                question={question}
                testId={testId}
                key={key}
                answer={answer}
                editedAnswer={editedAnswer}
                shareFeedback={shareFeedback}
                giverEmployeeId={
                  getGiverAndReceiverEmployeeIds(evaluatorEmployee)
                    .giverEmployeeId
                }
              />
            );
          case "rating":
            return (
              <RatingQuestion
                questionTitle={question.description}
                maxRating={Number(question.scoreRangeTo)}
                handleChangeAnswer={() => {}}
                score={Number(answer.scoreResponse)}
                questionIndex={index}
                key={key}
                dataTestId={`scoringAnswer-${testId}`}
                senderUser={
                  selectedEmployee.externalId === giverEmployeeId
                    ? selectedEmployee
                    : evaluatorEmployee
                }
                questionId={String(question.id)}
                disabled={true}
              />
            );
          case "N/A":
            return (
              <WritingQuestion
                label={question.description}
                maxRows={3}
                key={key}
                dataTestId={`textAnswer-${testId}`}
                defaultValue="N/A"
                disabled={true}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
}
