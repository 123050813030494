import AccordionWrapper from "../../atoms/AccordionWrapper/index";
import AccordionSummary, {
  AccordionSummaryAtomProps,
} from "../../atoms/AccordionSummary/index";
import AccordionDetails, {
  AccordionDetailProps,
} from "../../atoms/AccordionDetails/index";

export interface AccordionMoleculeProps {
  titleProps?: AccordionSummaryAtomProps;
  detailProps: AccordionDetailProps;
  classes?: Object;
  defaultExpanded?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  expanded?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
}

export default function AccordionMolecule({
  titleProps,
  detailProps,
  classes,
  defaultExpanded,
  disabled,
  className,
  style,
  expanded,
  onChange,
}: AccordionMoleculeProps) {
  return (
    <AccordionWrapper
      classes={classes}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      className={className}
      style={style}
      expanded={expanded}
      onChange={onChange}
    >
      {titleProps !== undefined && <AccordionSummary {...titleProps} />}
      <AccordionDetails {...detailProps} />
    </AccordionWrapper>
  );
}
