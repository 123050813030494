import SelectionToggleAutocomplete from "../../molecules/SelectionToggleAutocomplete";
import AccordionMolecule from "../../molecules/Accordion";
import QuestionRenderer from "../QuestionRenderer";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import AtomicButton from "../../atoms/Button";
import TitleAtom from "../../atoms/Title";
import "./styles.css";
import { toast } from "react-toastify";
import { Question } from "../../domain/entities/Question";
import { Answer } from "../../domain/entities/Answer";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";
export interface OptionalEvaluationProps {
  questions: Question[];
  peerCatalog: Array<{
    name: string;
    externalId: string;
  }>;
  answers: Answer[];
  setAnswers: React.Dispatch<React.SetStateAction<Answer[]>>;
  senderUser: EvaluationEmployee;
  disabled?: boolean;
  additionalPeersWithAnswers: EvaluationEmployee[];
  additionalPeers: EvaluationEmployee[];
  setAdditionalPeers: React.Dispatch<
    React.SetStateAction<EvaluationEmployee[]>
  >;
  showTitle?: boolean;
  newPeerAnswers?: Answer[];
}

export default function OptionalEvaluations({
  questions,
  peerCatalog,
  answers,
  setAnswers,
  senderUser,
  disabled,
  additionalPeersWithAnswers,
  additionalPeers,
  setAdditionalPeers,
  showTitle,
  newPeerAnswers,
}: OptionalEvaluationProps) {
  const selectionOptions = peerCatalog
    .filter(
      (peer) =>
        peer.name !== senderUser?.name ||
        peer.externalId !== senderUser?.externalId,
    )
    .filter(
      (peer) =>
        !additionalPeersWithAnswers.some(
          (additionalWithAnswer) => additionalWithAnswer.name === peer.name,
        ),
    )
    .filter(
      (peer) =>
        !additionalPeers.some((additional) => additional.name === peer.name),
    )
    .map((peer) => ({
      label: peer.name,
      value: peer.externalId,
    }));

  const addAdditionalPeer = (externalId: string) => {
    if (externalId !== "") {
      const peer = peerCatalog.find((peer) => peer.externalId === externalId);
      if (
        peer !== undefined &&
        !additionalPeers.some(
          (storedPeer) => storedPeer.externalId === peer.externalId,
        )
      ) {
        setAdditionalPeers((previousArray) => {
          return [peer, ...previousArray];
        });
      }
    } else {
      toast.error("Please select a peer to add it");
    }
  };

  const removeAdditionalPeer = (externalId: string) => {
    const remainingPeers = additionalPeers.filter(
      (peer) => peer.externalId !== externalId,
    );
    setAdditionalPeers([...remainingPeers]);
  };

  return (
    <>
      {(showTitle !== undefined ? showTitle : false) && (
        <TitleAtom
          variant="h6"
          gutterBottom={true}
          style={{
            marginTop: "20px",
          }}
        >
          Additional evaluations (optional)
        </TitleAtom>
      )}
      <SelectionToggleAutocomplete
        onAdd={addAdditionalPeer}
        disabled={disabled}
        options={selectionOptions.sort(
          (a, b) => -b.label.localeCompare(a.label),
        )}
      />
      <div data-testid="new-answers" style={{ marginBottom: "20px" }}>
        {additionalPeers.map((peer, index) => {
          const title = (
            <div className="title-container">
              <span className="first-space">
                <b>Peer:</b> {peer.name}
              </span>
              <AtomicButton
                className="button-primary-orange third-space"
                onClick={() => removeAdditionalPeer(peer.externalId)}
                dataTestid={"deleteAdditionalEvaluation"}
                disabled={disabled}
              >
                Delete
              </AtomicButton>
            </div>
          );
          const children = (
            <UnidimensionalLayout
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <QuestionRenderer
                questions={questions}
                employeeId={peer.externalId}
                dataTestId={"optionalEvaluation"}
                answers={answers}
                setAnswers={setAnswers}
                senderUser={senderUser}
                disabled={disabled}
                newPeerAnswers={newPeerAnswers}
              />
            </UnidimensionalLayout>
          );
          return (
            <div
              key={`peer-${peer.externalId}-${peer.name}`}
              className={peer.className}
            >
              <AccordionMolecule
                titleProps={{
                  children: title,
                  dataTestId: "additionalAccordion",
                }}
                detailProps={{
                  children,
                }}
                key={`peer-${peer.externalId}-${peer.name}`}
                defaultExpanded={true}
              />
            </div>
          );
        })}
      </div>

      {additionalPeersWithAnswers.length > 0 && (
        <div data-testid="saved-answers">
          {additionalPeersWithAnswers.map((peer, index) => {
            const title = (
              <div className="title-container">
                <span className="first-space">
                  <b>Peer:</b> {peer.name}
                </span>
              </div>
            );
            const children = (
              <UnidimensionalLayout
                direction="column"
                justifyContent="center"
                alignItems="stretch"
              >
                <QuestionRenderer
                  questions={questions}
                  employeeId={peer.externalId}
                  dataTestId={"optionalEvaluation"}
                  answers={answers}
                  setAnswers={setAnswers}
                  senderUser={senderUser}
                  disabled={disabled}
                  newPeerAnswers={newPeerAnswers}
                />
              </UnidimensionalLayout>
            );
            return (
              <div
                key={`peer-${peer.externalId}-${peer.name}`}
                className={peer.className}
              >
                <AccordionMolecule
                  titleProps={{
                    children: title,
                    dataTestId: "additionalAccordion",
                  }}
                  detailProps={{
                    children,
                  }}
                  key={`peer-${peer.externalId}-${peer.name}`}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
