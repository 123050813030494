import { performanceUrls } from "../../config/performanceUrls";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../domain/entities/Component";
import { SubComponent } from "../../domain/entities/subComponents";
import ApiHelper from "./apiHelper";
import { dataToSnakeCase, toSnakeCase } from "./helpers";

export default class PerformanceApi {
  private readonly resolveScenario = (path: string) => {
    return `${performanceUrls.scenario}${path}`;
  };

  private readonly resolveEmployee = (path: string) => {
    return `${performanceUrls.employee}${path}`;
  };

  private readonly resolveComponent = (path: string) => {
    return `${performanceUrls.component}${path}`;
  };

  private readonly api = new ApiHelper();

  // Scenario *************************************************************

  public async createScenario(name: string) {
    const payload = {
      name,
    };
    const response = await this.api.request(
      "post",
      this.resolveScenario("/scenarios"),
      payload,
    );
    if (response.statusCode !== undefined) return response;
    throw new Error(response);
  }

  public async editScenario(id: number, name: string) {
    const payload = {
      name,
    };
    const response = await this.api.request(
      "put",
      this.resolveScenario(`/scenarios?scenario_id=${id}`),
      payload,
    );
    if (response.statusCode !== undefined) return response;
    throw new Error(response);
  }

  public async editPreferences(
    id: number,
    generalBonus: number,
    performanceBonus: number,
    payrollProrated: number,
  ) {
    const payload = {
      id,
      general_bonus: generalBonus,
      performance_bonus: performanceBonus,
      payroll_prorated: payrollProrated,
    };
    const response = await this.api.request(
      "put",
      this.resolveScenario("/edit_preferences"),
      payload,
    );
    return response;
  }

  public async getScenario(id: number) {
    const response = await this.api.request(
      "get",
      this.resolveScenario(`/${id}`),
    );
    return response;
  }

  public async getScenarios() {
    const response = await this.api.request("get", this.resolveScenario(""));
    return response;
  }

  public async getBonusCalculation(
    scenarioId: number,
    performanceAvailable: number,
    percentageProrated: number,
  ) {
    const payload = {
      performance_bonus_available: performanceAvailable,
      percentage_prorated: percentageProrated,
    };
    const response = await this.api.request(
      "post",
      this.resolveScenario(`/calculation?scenario_id=${scenarioId}`),
      payload,
    );
    return response;
  }

  public async deleteScenario(scenarioId: number, scenarioName: string) {
    const payload = {
      scenario_name: scenarioName,
    };
    const response = await this.api.request(
      "delete",
      this.resolveScenario(`/scenarios?scenario_id=${scenarioId}`),
      payload,
    );
    return response;
  }

  // Employee ***************************************************************

  async getEmployees(scenarioId: number) {
    const response = await this.api.request(
      "get",
      this.resolveEmployee(`/all/?scenario_id=${scenarioId}`),
    );
    return response;
  }

  async updateEmployeeStatus(
    employeeId: string,
    scenarioId: number,
    status: boolean,
  ) {
    const payload = {
      employee_external_id: employeeId,
      scenario_id: scenarioId,
      status,
    };
    const response = await this.api.request(
      "put",
      this.resolveEmployee(`/status`),
      payload,
    );
    return response;
  }

  async insertEmployees(scenarioId: number) {
    const response = await this.api.request(
      "post",
      this.resolveEmployee(`/load/?scenario_id=${scenarioId}`),
    );

    return response;
  }

  // Component **************************************************************

  async createComponent(
    scenarioId: number,
    name: string,
    subcomponent: SubComponent[],
  ) {
    const payload = {
      scenario_id: scenarioId,
      component: {
        name,
        created_by: "Astrid",
        sub_components: subcomponent,
        weight: 0,
        description: "",
      },
    };
    const response = await this.api.request(
      "post",
      this.resolveComponent(""),
      payload,
    );
    if (response.statusCode !== undefined) return response;
    throw new Error(response);
  }

  async updateComponents(componentList: any[], scenarioId: number) {
    const componentListToSave: ComponentsScenarioEmployee[] = [];
    for (const compoment of componentList) {
      const componentConvert = dataToSnakeCase(compoment);
      componentListToSave.push(
        componentConvert as unknown as ComponentsScenarioEmployee,
      );
    }
    const payload = {
      action: "update_component",
      scenario_id: scenarioId,
      components_list: componentListToSave,
    };
    const response = await this.api.request(
      "put",
      this.resolveComponent(`?scenario_id=${scenarioId}`),
      payload,
    );
    return response.body;
  }

  async updateComponent(
    component: ComponentsScenarioEmployee,
    scenarioId: number,
  ) {
    const payload = {
      components_list: [dataToSnakeCase(component as any)],
    };
    const response = await this.api.request(
      "put",
      this.resolveComponent(`?scenario_id=${scenarioId}`),
      payload,
    );
    return response.body;
  }

  async updateWeightComponents(componentList: Component[]) {
    const componentObj = componentList.map((component: Component) => {
      return toSnakeCase(component);
    });

    const component = componentObj.reduce((acc, { id, weight }) => {
      const obj = { id, weight };
      acc.push(obj);
      return acc;
    }, []);

    const payload = {
      data_list: component,
    };
    const response = await this.api.request(
      "put",
      this.resolveComponent("/weight"),
      payload,
    );
    if (response.statusCode !== undefined) return response.message;
    throw new Error(response);
  }

  async editComponent(
    componentId: number,
    name: string,
    subcomponent: SubComponent[],
    scenarioId: number,
  ) {
    const payload = {
      component: {
        id: componentId,
        name,
        updated_by: "Astrid",
        sub_components: subcomponent,
        status: true,
      },
    };
    const response = await this.api.request(
      "put",
      this.resolveComponent(`/name?scenario_id=${scenarioId}`),
      payload,
    );
    if (response.statusCode !== undefined) return response;
    throw new Error(response);
  }

  async getComponentsByScenario(scenarioId: number) {
    const response = await this.api.request(
      "get",
      this.resolveComponent(`?scenario_id=${scenarioId}`),
    );
    return response;
  }

  async getComponentsScenarioEmployee(scenarioId: number) {
    const response = await this.api.request(
      "get",
      this.resolveComponent(`/scenario_employee?scenario_id=${scenarioId}`),
    );
    return response;
  }

  async checkComponentById(componentId: number) {
    const response = await this.api.request(
      "get",
      this.resolveComponent(`/check_usage?component_id=${componentId}`),
    );
    return response;
  }

  async deleteComponent(componentId: number) {
    const response = await this.api.request(
      "delete",
      this.resolveComponent(`?component_id=${componentId}`),
    );
    if (response.statusCode !== undefined) return response.message;
    throw new Error(response);
  }
}
