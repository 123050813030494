import { toast } from "react-toastify";

export const onNotify = (message: { success: string; error: string }) => {
  const { success, error } = message;

  if (success.length > 0) {
    toast.success(success);
  }
  if (error.length > 0) {
    toast.error(error);
  }
};

export const checkLoaderContainerStatus = (
  currentStatus: string = "",
): boolean[] => {
  return [currentStatus === "loading"];
};
