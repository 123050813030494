import AccordionMolecule from "../../molecules/Accordion";
import QuestionRenderer from "../QuestionRenderer";
import UnidemensionalLayout from "../../atoms/UnidimensionalLayout";
import "./styles.css";
import { Question } from "../../domain/entities/Question";
import { ProjectPeers } from "../../domain/entities/Project";
import { Answer } from "../../domain/entities/Answer";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";

export interface PeerQuestionariesProps {
  questions: Question[];
  peersToEvaluate: ProjectPeers[];
  answers: Answer[];
  setAnswers: React.Dispatch<React.SetStateAction<Answer[]>>;
  senderUser: EvaluationEmployee;
  disabled?: boolean;
  newPeerAnswers?: Answer[];
}

export default function PeerQuestionaries({
  questions,
  peersToEvaluate,
  answers,
  setAnswers,
  senderUser,
  disabled,
  newPeerAnswers,
}: PeerQuestionariesProps) {
  return (
    <>
      <>
        {peersToEvaluate.map((projectPeers, index) => {
          const title = (
            <div className="title-container">
              <span className="first-space">
                <b>Peer:</b> {projectPeers.employee.name}
              </span>
              <span className="second-space">
                <b>Project(s):</b>{" "}
                {projectPeers.projects
                  .map((projectPeers) => projectPeers.name)
                  .join(", ")}
              </span>
            </div>
          );
          const children = (
            <UnidemensionalLayout
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <QuestionRenderer
                questions={questions}
                employeeId={projectPeers.employee.externalId}
                dataTestId="peerEvaluation"
                answers={answers}
                setAnswers={setAnswers}
                senderUser={senderUser}
                disabled={disabled}
                newPeerAnswers={newPeerAnswers}
              />
            </UnidemensionalLayout>
          );
          return (
            <AccordionMolecule
              titleProps={{
                children: title,
                dataTestId: "peerAccordion",
              }}
              detailProps={{
                children,
              }}
              key={`peer-${projectPeers.employee.externalId}-${projectPeers.employee.name}`}
            />
          );
        })}
      </>
    </>
  );
}
