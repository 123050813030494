import { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "../styles/App.scss";
import Body from "./layout/Body";
import Header from "./layout/Header";
import { UserAPIContextProvider } from "../../context/userAPIContext";
import Footer from "../../organisms/Footer";

function App(): JSX.Element {
  return (
    <Fragment>
      <UserAPIContextProvider>
        <Router>
          <Header />
          <Body />
          <Footer />
        </Router>
      </UserAPIContextProvider>
    </Fragment>
  );
}

export default App;
