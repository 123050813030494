import { useEffect, useState } from "react";
import { Scenario } from "../../../domain/entities/scenario";
import { useAppSelector } from "../../../infrastructure/redux/hooks";
import {
  getScenariosThunk,
  scenarioUpdate,
} from "../../../infrastructure/redux/features/scenarioSlice";
import { Container } from "react-bootstrap";
import ConfirmationModal from "./ConfirmationModal";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderContainer from "../Loader/LoaderContainer";
import { getUserPermissions } from "../../../infrastructure/authorization";
import {
  useTypedDispatch,
  useTypedSelector,
} from "../../../infrastructure/redux/store";
import { checkLoaderContainerStatus } from "../../helpers/notification-utils";
import Table from "../../../atoms/Tables/Index";
import Button from "../../../atoms/Button";

const ListScenarios = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletedScenarioId, setDeletedScenarioId] = useState(0);
  const [deletedScenarioName, setDeletedScenarioName] = useState("");
  const navigate = useNavigate();

  const scenarios = useAppSelector((state) => state.scenarioReducer.scenarios);
  const scenarioStateStatus = useTypedSelector(
    (state) => state.scenarioReducer.status,
  );

  const dispatch = useTypedDispatch();

  useEffect(() => {
    void dispatch(getScenariosThunk({ sortOrder: "desc" }));
  }, []);

  const onCloseDeleteModal = () => {
    setShowConfirmationModal(false);
  };

  function setScenarioToUse(scenario: Scenario) {
    dispatch(scenarioUpdate(scenario));
  }

  const showConfirmationToast = (confirmation: string = "") => {
    if (confirmation === "deleted") {
      toast.success(
        `Scenario ${deletedScenarioName} was successfully eliminated`,
      );
    } else toast.error(`Could not delete ${deletedScenarioName} scenario`);
  };

  const formatDate = (scenarioDate: string | undefined) => {
    if (scenarioDate !== undefined) {
      return new Date(scenarioDate).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
    return ``;
  };

  function setIdScenarioToDelete(scenarioId: number, scenarioName: string) {
    setDeletedScenarioId(scenarioId);
    setDeletedScenarioName(scenarioName);
    setShowConfirmationModal(true);
  }

  const columns = [
    {
      field: "name",
      headerName: "Scenario Name",
      width: 350,
      renderCell: ({ row }: { row: Scenario }) => (
        <Link
          to="/scenarioSelected"
          state={{ id: row.id }}
          data-testid="select-scenario-item"
          onClick={() => setScenarioToUse(row)}
        >
          {row.name}
        </Link>
      ),
    },
    { field: "creationDate", headerName: "Date added", width: 300 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: { row: Scenario }) => (
        <Button
          dataTestid="delete"
          onClick={() => {
            void (async () => {
              const getUserAuth = await getUserPermissions();
              if (getUserAuth != null) {
                setIdScenarioToDelete(row.id, row.name ?? "");
              } else {
                navigate("/login");
              }
            })();
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const getAllScenarios = (scenarios: Scenario[] | null) => {
    if (scenarios != null) {
      return scenarios?.map((scenario) => {
        return {
          id: scenario.id,
          name: scenario.name,
          creationDate: formatDate(scenario.creationDate),
          generalBonus: scenario.generalBonus,
          performanceBonus: scenario.performanceBonus,
          payrollProrated: scenario.payrollProrated,
          status: scenario.status,
        };
      });
    }
    return [];
  };

  return (
    <LoaderContainer
      conditions={checkLoaderContainerStatus(scenarioStateStatus)}
      loadingMessage="Loading..."
    >
      <Container className="abs-center-table text-center mt-3 position-relative">
        <div style={{ width: "80vh", height: "90%" }}>
          <Table
            columns={columns}
            rows={getAllScenarios(scenarios)}
            sortingOrder={["asc", "desc"]}
          />
        </div>
        <ConfirmationModal
          id={deletedScenarioId}
          name={deletedScenarioName}
          show={showConfirmationModal}
          confirmationToast={showConfirmationToast}
          onClose={onCloseDeleteModal}
          onAccept={() => {
            setShowConfirmationModal(false);
          }}
        />
      </Container>
    </LoaderContainer>
  );
};

export default ListScenarios;
