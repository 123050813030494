import { ScenarioRepository } from "../../domain/repositories/scenarioRepository";
import { Scenario } from "../../domain/entities/scenario";

export class GetScenarios {
  private readonly scenarioRepository: ScenarioRepository;

  public constructor(scenarioRepository: ScenarioRepository) {
    this.scenarioRepository = scenarioRepository;
  }

  public async run(): Promise<Scenario[]> {
    return await this.scenarioRepository.getScenarios();
  }
}
