import Table from "../../atoms/Tables/Index";
import AtomicButton from "../../atoms/Button";
import ContainerAtom from "../../atoms/Container";
import PaperSurfaceAtom from "../../atoms/PaperSurface";
import TitleAtom from "../../atoms/Title";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import LoadingMolecule from "../../molecules/Loading";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { evaluationServices } from "../../services/evaluationServices";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import { Evaluation } from "../../domain/entities/Evaluation";
import { toast } from "react-toastify";

export const EvaluationRequestScreen = () => {
  const [open, setOpen] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [kindRequest, setKindRequest] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeFeedbackRound, setActiveFeedbackRound] =
    useState<FeedbackRound>();
  const [evaluationsRequests, setEvaluationsRequests] = useState<Evaluation[]>(
    [],
  );
  const [updatedEvaluations, setUpdatedEvaluations] = useState<Evaluation[]>(
    [],
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchActiveFeedbackRound = async (): Promise<FeedbackRound> => {
    const activeFeedbackRound = await evaluationServices.getActiveFeedbackRound(
      "active",
    );
    return activeFeedbackRound;
  };

  const fetchEvaluationsRequests = async (
    feedbackRoundId: string,
  ): Promise<Evaluation[]> => {
    const evaluations = await evaluationServices.getEvaluationsRequests(
      feedbackRoundId,
    );
    return evaluations;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const activeFeedbackRound = await fetchActiveFeedbackRound();
        const evaluationsRequests = await fetchEvaluationsRequests(
          activeFeedbackRound.id,
        );
        setActiveFeedbackRound(activeFeedbackRound);
        setEvaluationsRequests(evaluationsRequests);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData().catch(() => {});
  }, [open]);

  const handleEvent = (type: string) => {
    setOpen(true);
    setTitleModal(
      type === "approve" ? "Approval confirmation" : "Denial confirmation",
    );
    setKindRequest(type);
  };

  const handleYes = () => {
    const evaluationData =
      kindRequest === "approve"
        ? {
            isEditionRequest: false,
            isDenied: false,
            completionStatus: "pending",
            message: "approved",
            wasApprovedBefore: true,
          }
        : {
            isEditionRequest: true,
            isDenied: true,
            completionStatus: "submitted",
            message: "denied",
            wasApprovedBefore: false,
          };
    const evaluations = updatedEvaluations.map((evaluation) => {
      evaluation.isEditionRequest = evaluationData.isEditionRequest;
      evaluation.completionStatus = evaluationData.completionStatus;
      evaluation.isDenied = evaluationData.isDenied;
      evaluation.wasApprovedBefore = evaluationData.wasApprovedBefore;
      return evaluation;
    });
    updateEvaluations(
      evaluations,
      `Evaluations ${evaluationData.message} successfully`,
    ).finally(() => setOpen(false));
  };

  const handleNo = () => {
    setOpen(false);
  };

  const updateEvaluations = async (
    evaluations: Evaluation[],
    message: string,
  ) => {
    await evaluationServices
      .updateEvaluationsEditionRequests(evaluations)
      .finally(() => {
        toast.success(message);
      });
  };

  const onChangeModel = (userExternalIds: string[]) => {
    const evaluations = userExternalIds.map((userExternalId) => {
      const evaluation: Evaluation = {
        feedbackRoundId: activeFeedbackRound?.id ?? "",
        userExternalId,
        completionStatus: "",
        evaluatorName: "",
        userEmail:
          evaluationsRequests.find(
            (evaluation) => evaluation.userExternalId === userExternalId,
          )?.userEmail ?? "",
        remainingEditionRequests:
          evaluationsRequests.find(
            (evaluation) => evaluation.userExternalId === userExternalId,
          )?.remainingEditionRequests ?? 2,
        wasApprovedBefore:
          evaluationsRequests.find(
            (evaluation) => evaluation.userExternalId === userExternalId,
          )?.wasApprovedBefore ?? false,
      };
      return evaluation;
    });
    setUpdatedEvaluations(evaluations);
  };

  const EvaluationRequestColumns: GridColDef[] = [
    {
      field: "feedbackRoundName",
      flex: 1,
      headerName: "FeedBack Round Name",
      cellClassName: "cell-extra-left-padding",
      headerClassName: "iconless-bold-column-header header-extra-left-padding",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "requestDate",
      flex: 0.8,
      headerName: "Request Date",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header button-icon-right",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "requestBy",
      flex: 0.8,
      headerName: "Requested By",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "comments",
      flex: 1.6,
      headerName: "Comments",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              overflow: "auto",
              maxHeight: "60px",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <UnidimensionalLayout
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          style={{
            width: "100%",
            padding: "2%",
            flexWrap: "wrap",
            rowGap: "5px",
          }}
        >
          <AtomicButton
            type="button"
            size="small"
            className="button-primary-green"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              onChangeModel([String(params.id)]);
              handleEvent("approve");
            }}
          >
            Approve
          </AtomicButton>

          <AtomicButton
            type="button"
            size="small"
            className="button-primary-orange"
            disabled={params.value?.shareFeedback}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              onChangeModel([String(params.id)]);
              handleEvent("deny");
            }}
          >
            Deny
          </AtomicButton>
        </UnidimensionalLayout>
      ),
    },
  ];

  const evaluationsRows: GridRowsProp = evaluationsRequests.map(
    (evaluation) => {
      const gridRow = {
        id: evaluation.userExternalId,
        feedbackRoundName: activeFeedbackRound?.name,
        requestDate: evaluation.lastUpdateAt?.split(" ")[0],
        requestBy: evaluation.evaluatorName,
        comments: evaluation.editionRequest,
      };
      return gridRow;
    },
  );

  return (
    <>
      <ContainerAtom className="container-element">
        <UnidimensionalLayout
          direction="column"
          justifyContent="space-evenly"
          alignItems="stretch"
          style={{ width: "100%" }}
        >
          <TitleAtom
            variant="h4"
            style={{ fontWeight: "bold", margin: "50px 0 20px 0" }}
            gutterBottom
          >
            Evaluation Edition Request
          </TitleAtom>
          <PaperSurfaceAtom styles={{ boxShadow: "none" }}>
            {evaluationsRows.length !== 0 ? (
              <Table
                rows={evaluationsRows}
                columns={EvaluationRequestColumns}
                pageSize={50}
                checkboxSelection={true}
                disableSelectionOnClick={true}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSelectionModelChangeCallback={onChangeModel}
                className="no-bottom-margin"
                autoHeight
                getRowHeight={() => "auto"}
                approveButton={() => handleEvent("approve")}
                denyButton={() => handleEvent("deny")}
                sortingOrder={["asc", "desc"]}
              />
            ) : (
              <>
                <ContainerAtom
                  sx={{
                    borderColor: "#e0e0e0",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "50px",
                    backgroundColor: "white",
                  }}
                >
                  <TitleAtom
                    style={{ color: "#2d8aa0" }}
                    variant="h4"
                    gutterBottom={true}
                    dataTestId="noActiveFeedbackRound"
                  >
                    Looks like there is no Evaluation Edition Requests
                  </TitleAtom>
                </ContainerAtom>
              </>
            )}
          </PaperSurfaceAtom>
        </UnidimensionalLayout>
      </ContainerAtom>
      {open && (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <b>{titleModal}</b>
          </DialogTitle>
          <DialogContent>
            {updatedEvaluations.length > 1
              ? `Are you sure you want to bulk ${kindRequest} these edition requests?`
              : `Are you sure you want to ${kindRequest} this edition request?`}
          </DialogContent>
          <DialogActions>
            <AtomicButton
              type="button"
              size="small"
              className="button-primary-light-blue"
              onClick={() => handleYes()}
              dataTestid={`${kindRequest}-button`}
            >
              {kindRequest === "approve" ? "Approve" : "Deny"}
            </AtomicButton>

            <AtomicButton
              type="button"
              size="small"
              className="button-white"
              onClick={() => handleNo()}
            >
              Cancel
            </AtomicButton>
          </DialogActions>
        </Dialog>
      )}
      <LoadingMolecule condition={isLoading} />
    </>
  );
};
