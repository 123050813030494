import { EmployeeRepository } from "../../domain/repositories/employeeRepository";

export class InsertEmployees {
  private readonly employeeRepository: EmployeeRepository;

  public constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  public async run(
    scenarioId: number,
  ): Promise<{ message: string; error: string }> {
    return await this.employeeRepository.insertEmployees(scenarioId);
  }
}
