import { Container, Col, Row } from "react-bootstrap";
import EmployeeInfoTable from "./EmployeeInfoComponent";
import { Employee } from "../../../domain/entities/Employee";
import ComponentsList from "./ComponentEditComponent";
import DeactivateEmployee from "./DeactivateEmployee";
import ActivateEmployee from "./ActivateEmployee";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../../domain/entities/Component";
import LoadingComponent from "../LoadingComponent";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../../infrastructure/redux/hooks";
import { useNavigate } from "react-router-dom";
import Button from "../../../atoms/Button";

interface Props {
  employee: Employee;
  components: Component[];
  loadingComponents: string;
  loadingComponentsByScenario: string;
  componentsByScenario: ComponentsScenarioEmployee[];
}

const EmployeeDetail = ({
  employee = {} as unknown as Employee,
  components,
  loadingComponents,
  componentsByScenario,
  loadingComponentsByScenario,
}: Props) => {
  const navigate = useNavigate();

  const componentsForEmployee = componentsByScenario.filter(
    (comp) => comp.employeeId === employee.id,
  );

  const currentScenario = useAppSelector(
    (state) => state.scenarioReducer.scenario,
  );

  if (
    loadingComponents === "loading" ||
    loadingComponentsByScenario === "loading"
  )
    return <LoadingComponent />;

  if (Object.values(employee).length === 0)
    return <Typography variant="overline">No data available</Typography>;

  const refreshPage = () => {
    navigate(0);
  };

  return (
    <Container className="mt-5">
      <div className="d-flex justify-content-sm-end">
        <Button
          className="button-primary-light-blue"
          onClick={() => {
            navigate("/bonusCalculation");
          }}
        >
          Bonus Calculation
        </Button>{" "}
      </div>

      <Row className="mt-4">
        <Col className=".col-align-left">
          <h1>Edit Employee</h1>
        </Col>
        <hr />
      </Row>
      <Row>
        <Col>
          <EmployeeInfoTable
            id={employee.id}
            name={employee.name}
            lastSalary={employee.lastSalary}
            monthsIoet={employee.monthsIoet}
            status={employee.status}
          />
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col>
          <div className="button-container">
            {!employee.status ? (
              <ActivateEmployee
                employee={employee}
                scenarioId={Number(currentScenario?.id)}
                refreshPage={refreshPage}
              />
            ) : (
              <DeactivateEmployee
                employee={employee}
                scenarioId={Number(currentScenario?.id)}
                refreshPage={refreshPage}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* TODO: send the correct ids */}
          <ComponentsList
            components={components}
            componentsForEmployee={componentsForEmployee}
            employeeId={employee.id ?? ""}
            scenarioId={Number(currentScenario?.id)}
            employeeStatus={employee.status}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeDetail;
