import { useEffect, useState } from "react";
import { evaluationServices } from "../../services/evaluationServices";
import { Question, QuestionsAllcategory } from "../../domain/entities/Question";
import { ProjectPeers } from "../../domain/entities/Project";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";
import { Evaluation } from "../../domain/entities/Evaluation";
import { toast } from "react-toastify";
import { Answer } from "../../domain/entities/Answer";
import { getUserPermissions } from "../../infrastructure/authorization";

import "./styles.css";
import EvaluationSectionBase from "../../organisms/EvaluationSection";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import TitleAtom from "../../atoms/Title";
import ContainerAtom from "../../atoms/Container";
import AtomicButton from "../../atoms/Button";
import QuestionRenderer from "../../organisms/QuestionRenderer";
import PeerQuestionaries from "../../organisms/PeerQuestionaries";
import OptionalEvaluations from "../../organisms/OptionalEvaluations";
import SubmitEvaluationModal from "../../molecules/SubmitEvaluationModal";
import WarningEvaluationModal from "../../molecules/WarningEvaluationModal";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import { Typography } from "@mui/material";
import LoadingMolecule from "../../molecules/Loading";
import TextAreaAtom from "../../atoms/TextArea";

interface EvaluationScreenProps {
  showRelatedPeers?: boolean;
}

const EvaluationScreenTemp = ({ showRelatedPeers }: EvaluationScreenProps) => {
  const modalTitle = "Evaluation cannot be summited";
  const modalBody = (
    <p>Please, fill out all the mandatory fields to submit the evaluation.</p>
  );
  const includeRelatedPeers = showRelatedPeers ?? false;
  const [state, setState] = useState<boolean>(false);
  const [peerCatalog, setPeerCatalog] = useState<EvaluationEmployee[]>([]);
  const [peerEvaluationQuestions, setPeerEvaluationQuestions] = useState<
    Question[]
  >([]);
  const [selfEvaluationQuestions, setSelfEvaluationQuestions] = useState<
    Question[]
  >([]);
  const [projectPeers, setProjectPeers] = useState<ProjectPeers[]>([]);
  const [peerAnswersDB, setPeerAnswersDB] = useState<Answer[]>([]);
  const [selfAnswersDB, setSelfAnswersDB] = useState<Answer[]>([]);
  const [needToBeReloaded, setNeedToBeReloaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [externalEmployee, setExternalEmployee] =
    useState<EvaluationEmployee>();
  const [newSelfAnswers, setNewSelfAnswers] = useState<Answer[]>([]);
  const [newPeerAnswers, setNewPeerAnswers] = useState<Answer[]>([]);
  const [feedbackRound, setFeedbackRound] = useState<FeedbackRound>();
  const [evaluationStatus, setEvaluationStatus] = useState<string>("");
  const [, setEvaluation] = useState<Evaluation>();
  const [additionalPeers, setAdditionalPeers] = useState<EvaluationEmployee[]>(
    [],
  );
  const [editionRequest, setEditionRequest] = useState<string>("");
  const [wasApprovedBefore, setWasApprovedBefore] = useState(false);
  const [remainingEditionRequests, setRemainingEditionRequests] = useState(2);
  const [isEditionRequest, setIsEditionRequest] = useState(false);
  let additionalPeersWithAnswers = peerCatalog.filter((peer) =>
    peerAnswersDB.some((answer) => answer.receiverUserId === peer.externalId),
  );
  additionalPeersWithAnswers = additionalPeersWithAnswers.sort((prev, acc) => {
    if (prev.name < acc.name) {
      return -1;
    }
    if (prev.name > acc.name) {
      return 1;
    }
    return 0;
  });
  const borderRed = "border-red";
  useEffect(() => {
    const filteredAnswers = newPeerAnswers.filter((answer) => {
      return additionalPeers.some(
        (peer) => peer.externalId === answer.receiverUserId,
      );
    });
    setNewPeerAnswers(filteredAnswers);
  }, [additionalPeers]);

  const handlePeerAnswerClick = createHandleAnswerClick(
    [...newSelfAnswers, ...newPeerAnswers],
    "Evaluation saved successfully",
    true,
  );

  const [showSubmitEvaluationModal, setSubmitEvaluationModal] = useState(false);
  const [showWarningEvaluationModal, setShowWarningEvaluationModal] =
    useState(false);
  const [showEditEvaluationModal, setShowEditEvaluationModal] = useState(false);
  const [showEditionConfirmationModal, setShowEditionConfirmationModal] =
    useState(false);

  const fetchEmployees = async () => {
    const employee = await evaluationServices.getCatalogEmployees();
    return employee;
  };

  const fetchActiveFeedbackRound = async (): Promise<FeedbackRound> => {
    const activeFeedbackRound = await evaluationServices.getActiveFeedbackRound(
      "active",
    );
    return activeFeedbackRound;
  };

  const fetchEvaluationQuestions = async (): Promise<QuestionsAllcategory> => {
    const evaluationQuestions: QuestionsAllcategory =
      await evaluationServices.getEvaluationQuestions(feedbackRound?.id ?? "");
    return evaluationQuestions;
  };

  const fetchProjectPeers = async () => {
    const employeeProjectPeers = await evaluationServices.getProjectPeers(
      "my-id",
    );
    return employeeProjectPeers;
  };

  const fetchExternalEmployee = async () => {
    const getUserAuth = await getUserPermissions();
    const employee = await evaluationServices.getExternalEmployee(
      getUserAuth.email,
    );
    return employee;
  };

  const fetchEvaluationAnswers = async () => {
    if (externalEmployee !== undefined) {
      const answers = await evaluationServices.getMyEvaluationAnswers(
        feedbackRound?.id ?? "",
      );
      return answers;
    }
  };

  const fetchEvaluation = async () => {
    const evaluationStatus = await evaluationServices.getEvaluation(
      feedbackRound?.id ?? "",
      externalEmployee?.externalId ?? "",
    );
    return evaluationStatus;
  };

  const needsAnswersToFillOut = async (totalAnswersToFillOut: number) => {
    return await evaluationServices.needsAnswersToFillOut(
      feedbackRound?.id ?? "",
      externalEmployee?.externalId ?? "",
      totalAnswersToFillOut,
    );
  };

  function createHandleAnswerClick(
    answers: Answer[],
    message: string,
    fromSaved = false,
  ) {
    return async () => {
      try {
        setIsSaved(true);
        if (fromSaved) {
          setSpinner(true);
        }
        await evaluationServices.postEvaluationsAnswers(
          answers,
          feedbackRound?.id ?? "",
        );
        if (message !== "") {
          toast.success(message);
          setAdditionalPeers([]);
          setNeedToBeReloaded(!needToBeReloaded);
        }
        setIsSaved(false);
        setSpinner(false);
        localStorage.removeItem("storedPeerData");
        localStorage.removeItem("storedSelfData");
      } catch (err: any) {
        toast.error(err.message);
      }
    };
  }

  function isSubmitted() {
    return evaluationStatus === "submitted";
  }

  const handleSubmitEvaluation = () => {
    const evaluation: Evaluation = {
      feedbackRoundId: feedbackRound?.id ?? "",
      userExternalId: externalEmployee?.externalId ?? "",
      completionStatus: "submitted",
      evaluatorName: externalEmployee?.name ?? "",
    };

    createSubmitEvaluation(evaluation, "Evaluation submitted successfully")
      .then(() => {
        setSubmitEvaluationModal(false);
      })
      .catch(() => {});
  };

  const handleEditEvaluation = async () => {
    const getUserAuth = await getUserPermissions();
    const evaluation: Evaluation = {
      feedbackRoundId: feedbackRound?.id ?? "",
      userExternalId: externalEmployee?.externalId ?? "",
      completionStatus: "",
      evaluatorName: "",
      editionRequest,
      userEmail: getUserAuth.email,
      wasApprovedBefore,
      remainingEditionRequests,
    };
    updateEvaluation(evaluation)
      .then(() => {
        setShowEditEvaluationModal(false);
        setShowEditionConfirmationModal(true);
      })
      .catch(() => {})
      .finally(() => {
        setNeedToBeReloaded(!needToBeReloaded);
      });
  };

  const onCloseSubmitEvaluationModal = () => {
    setSubmitEvaluationModal(false);
  };

  const onCloseWarningEvaluationModal = () => {
    setShowWarningEvaluationModal(false);
  };

  const onCloseEditEvaluationModal = () => {
    setShowEditEvaluationModal(false);
  };

  const onEditionConfirmationModal = () => {
    setShowEditionConfirmationModal(false);
  };
  const openSubmitEvaluationModal = () => {
    additionalPeersWithAnswers = additionalPeersWithAnswers.map((peer) => {
      peer.className = "";
      return peer;
    });
    const totalAdditionalPeers =
      additionalPeersWithAnswers.length + additionalPeers.length;
    const adjustedTotalAdditionalPeers =
      totalAdditionalPeers === 0 ? 1 : totalAdditionalPeers;
    const totalAnswersToFillOut =
      selfEvaluationQuestions.length +
      peerEvaluationQuestions.length * adjustedTotalAdditionalPeers;

    const savePeerAnswers = createHandleAnswerClick(
      [...newSelfAnswers, ...newPeerAnswers],
      "",
    )();

    const getClassPeer = (answers: Answer[], ap: EvaluationEmployee) => {
      const getAnswer = answers.filter(
        (awDB) => awDB.receiverUserId === ap.externalId,
      );
      if (getAnswer.length < peerEvaluationQuestions.length) {
        ap.className = borderRed;
      } else {
        // eslint-disable-next-line array-callback-return
        getAnswer.map((answer) => {
          if (answer.textResponse?.length === 0 && answer.scoreResponse === 0) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            ap.className = borderRed + ap.className;
            if (ap.className.includes("border-red")) {
              ap.className = borderRed;
            }
          }
        });
      }
    };

    const handleSelfQuestion = (question: Question, answer: Answer) => {
      if (question?.category === "rating") {
        question.className = answer.scoreResponse === 0 ? borderRed : "";
      } else {
        question.className = answer.textResponse?.length === 0 ? borderRed : "";
      }
    };

    const handlePeerQuestion = (
      answers: Answer[],
      peer: EvaluationEmployee,
    ) => {
      const auxBorder: string[] = [];
      // eslint-disable-next-line array-callback-return
      answers.map((answerDB) => {
        if (
          answerDB.scoreResponse === 0 &&
          answerDB.textResponse?.length === 0
        ) {
          auxBorder.push(borderRed);
        }
      });
      if (auxBorder.includes(borderRed)) {
        peer.className = borderRed;
      } else {
        peer.className = "";
      }
    };

    // eslint-disable-next-line array-callback-return
    selfEvaluationQuestions.map((questionSelf) => {
      const newAnswer = newSelfAnswers.find(
        (newSelfAnswerValue) =>
          newSelfAnswerValue.questionId === questionSelf.id,
      );
      const newAnswerDB = selfAnswersDB.find(
        (newSelfAnswerValueDB) =>
          newSelfAnswerValueDB.questionId === questionSelf.id,
      );
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (newAnswer && newAnswerDB) {
        handleSelfQuestion(questionSelf, newAnswer);
      } else {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (newAnswer) {
          handleSelfQuestion(questionSelf, newAnswer);
        } else {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (newAnswerDB) {
            handleSelfQuestion(questionSelf, newAnswerDB);
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!newAnswer && !newAnswerDB) {
        questionSelf.className = borderRed;
      }
    });
    // eslint-disable-next-line array-callback-return
    additionalPeers.map((peer) => {
      const getAnswer = newPeerAnswers.filter(
        (newAnswer) => newAnswer.receiverUserId === peer.externalId,
      );
      if (getAnswer.length < peerEvaluationQuestions.length) {
        peer.className = borderRed;
      } else {
        // eslint-disable-next-line array-callback-return
        getAnswer.map((answer) => {
          const getQuestion = peerEvaluationQuestions.find(
            (question) => question.id === answer.questionId,
          );
          const auxBorder = [];
          if (getQuestion?.category === "rating") {
            const border = answer.scoreResponse === 0 ? borderRed : "";
            auxBorder.push(border);
          } else {
            const border = answer.textResponse?.length === 0 ? borderRed : "";
            auxBorder.push(border);
          }
          if (auxBorder.includes(borderRed)) {
            peer.className = borderRed;
          } else {
            peer.className = "";
          }
        });
      }
    });

    // eslint-disable-next-line array-callback-return
    additionalPeersWithAnswers.map((ap) => {
      // eslint-disable-next-line array-callback-return
      if (peerAnswersDB.length > 0) {
        if (newPeerAnswers.length > 0) {
          const peerAnswer = newPeerAnswers.filter(
            (newAnswer) => newAnswer.receiverUserId === ap.externalId,
          );

          const peerAnswerDB = peerAnswersDB.filter(
            (newAnswer) => newAnswer.receiverUserId === ap.externalId,
          );
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (peerAnswer.length > 0) {
            if (peerAnswer.length === peerEvaluationQuestions.length) {
              handlePeerQuestion(peerAnswerDB, ap);
            } else {
              let mergeAnswers = [];
              mergeAnswers = peerAnswerDB.map(
                (answerDB) =>
                  peerAnswer.find(
                    (answer) =>
                      answer.receiverUserId === answerDB.receiverUserId &&
                      answer.questionId === answerDB.questionId,
                  ) ?? answerDB,
              );
              handlePeerQuestion(mergeAnswers, ap);
            }
          } else {
            if (peerAnswerDB.length === peerEvaluationQuestions.length) {
              handlePeerQuestion(peerAnswerDB, ap);
            } else {
              ap.className = borderRed;
            }
          }
        } else {
          getClassPeer(peerAnswersDB, ap);
        }
      }
    });

    const saveAnswers = savePeerAnswers;

    saveAnswers
      .then(async () => await needsAnswersToFillOut(totalAnswersToFillOut))
      .then((needsAnswersToFillOut) => {
        needsAnswersToFillOut
          ? setShowWarningEvaluationModal(true)
          : setSubmitEvaluationModal(true);
      })
      .catch(() => {});
  };

  const openEditEvaluationModal = () => {
    setShowEditEvaluationModal(true);
  };

  const createSubmitEvaluation = async (
    evaluation: Evaluation,
    message: string,
  ) => {
    await evaluationServices.updateEvaluation(evaluation).then(() => {
      setSubmitEvaluationModal(false);
      toast.success(message);
      setEvaluationStatus(evaluation.completionStatus);
    });
  };

  const loadCacheData = (peerAnswers: Answer[], selfAnswers: Answer[]) => {
    const loadAnswersFromLocalStorage = (key: string): Answer[] => {
      const data = localStorage.getItem(key);
      if (data !== null) {
        const jsonData = JSON.parse(data);
        return jsonData.map((answer: any) => answerItemMaker(answer));
      }
      return [];
    };

    const storedPeerAnswers = loadAnswersFromLocalStorage("storedPeerData");
    const storedSelfAnswers = loadAnswersFromLocalStorage("storedSelfData");
    const filteredPeerAnswers = removeCommonAnswers([
      ...storedPeerAnswers,
      ...peerAnswers,
    ]);
    const filteredSelfAnswers = removeCommonAnswers([
      ...storedSelfAnswers,
      ...selfAnswers,
    ]);
    setNewPeerAnswers(filteredPeerAnswers);
    setNewSelfAnswers(filteredSelfAnswers);
    setPeerAnswersDB(filteredPeerAnswers);
    setSelfAnswersDB(filteredSelfAnswers);
  };

  function removeCommonAnswers(answers: Answer[]): Answer[] {
    const uniqueAnswers = [];
    const seenAnswers = new Set();
    for (const answer of answers) {
      const key = `${answer.senderUserId}-${answer.receiverUserId}-${
        answer.questionId !== undefined ? answer.questionId : ""
      }`;
      if (!seenAnswers.has(key)) {
        seenAnswers.add(key);
        uniqueAnswers.push(answer);
      }
    }
    return uniqueAnswers;
  }

  const answerItemMaker = (answer: any): Answer => {
    return {
      createdBy: answer.createdBy,
      senderUserId: answer.senderUserId,
      receiverUserId: answer.receiverUserId,
      questionId: answer.questionId,
      textResponse: answer.textResponse,
      scoreResponse: answer.scoreResponse,
      optional: answer.optional,
      updatedAt: answer.updatedAt,
      updatedBy: answer.updatedBy,
    };
  };

  const updateEvaluation = async (evaluation: Evaluation) => {
    await evaluationServices
      .updateEvaluationEditionRequest(evaluation)
      .then(() => {
        setShowEditionConfirmationModal(false);
      })
      .catch((error) => toast.error(error.message));
  };

  const shouldBeEnabled = () => {
    return (
      wasApprovedBefore ||
      remainingEditionRequests === 0 ||
      !isSubmitted() ||
      isEditionRequest
    );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const activeFeedbackRound = await fetchActiveFeedbackRound();
        if (activeFeedbackRound !== undefined) {
          const employees = await fetchEmployees();
          const projectPeers = await fetchProjectPeers();
          const externalEmployee = await fetchExternalEmployee();
          setPeerCatalog(employees);
          setFeedbackRound(activeFeedbackRound);
          setProjectPeers(projectPeers);
          const employeeFound = employees.find(
            (employee) => employee.externalId === externalEmployee.externalId,
          );
          setExternalEmployee(employeeFound);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData().catch((error) => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    const fetchAnswers = async () => {
      const evaluation = await fetchEvaluation();
      const evaluationQuestions = await fetchEvaluationQuestions();
      const answers = await fetchEvaluationAnswers();
      if (answers !== undefined) {
        setEvaluation(evaluation);
        setEvaluationStatus(evaluation.completionStatus);
        setWasApprovedBefore(evaluation?.wasApprovedBefore ?? false);
        setIsEditionRequest(evaluation?.isEditionRequest ?? false);
        setRemainingEditionRequests(evaluation?.remainingEditionRequests ?? 2);
        setPeerEvaluationQuestions(evaluationQuestions.peerQuestions);
        setSelfEvaluationQuestions(evaluationQuestions.selfQuestions);
        loadCacheData(answers.peerAnswers, answers.selfAnswers);
        setState(true);
      }
    };
    if (externalEmployee !== undefined) {
      setIsLoading(true);
      fetchAnswers()
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    feedbackRound,
    externalEmployee,
    needToBeReloaded,
    showEditEvaluationModal,
  ]);

  if (!state) {
    return (
      <ContainerAtom>
        <UnidimensionalLayout className="layout-spaces" alignItems="flex-start">
          <LoadingMolecule condition={isLoading} />
          <TitleAtom
            variant="h4"
            gutterBottom={true}
            dataTestId="evaluationsScreenTitle"
          >
            Peer & Self Evaluations
          </TitleAtom>
          <ContainerAtom
            sx={{
              borderColor: "#e0e0e0",
              borderWidth: "1px",
              borderStyle: "solid",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "50px",
              backgroundColor: "white",
            }}
          >
            {!isLoading && (
              <>
                <TitleAtom
                  style={{ color: "#2d8aa0" }}
                  variant="h4"
                  gutterBottom={true}
                  dataTestId="noActiveFeedbackRound"
                >
                  Looks like there is no Active feedback round
                </TitleAtom>
                <Typography>
                  No feedback round has been opened yet. Please try again later.
                </Typography>
              </>
            )}
          </ContainerAtom>
        </UnidimensionalLayout>
      </ContainerAtom>
    );
  } else
    return (
      <ContainerAtom>
        <UnidimensionalLayout className="layout-spaces" alignItems="flex-start">
          <TitleAtom variant="h4" gutterBottom={true}>
            {feedbackRound?.name}
          </TitleAtom>
          {externalEmployee !== undefined && (
            <>
              <EvaluationSectionBase
                evaluationTitle="Self-evaluation"
                evaluationDescription="Please evaluate yourself. All questions must be answered."
                className="section-space"
              >
                <QuestionRenderer
                  questions={selfEvaluationQuestions}
                  dataTestId="selfEval"
                  answers={selfAnswersDB}
                  setAnswers={setNewSelfAnswers}
                  senderUser={externalEmployee}
                  disabled={isSubmitted()}
                  newSelfAnswers={newSelfAnswers}
                />
              </EvaluationSectionBase>
              <EvaluationSectionBase
                evaluationTitle="Peer evaluation"
                evaluationDescription="Please evaluate your team members. All questions must be answered."
                className="section-space"
              >
                {includeRelatedPeers && (
                  <PeerQuestionaries
                    questions={peerEvaluationQuestions}
                    peersToEvaluate={projectPeers}
                    answers={peerAnswersDB}
                    setAnswers={setNewPeerAnswers}
                    senderUser={externalEmployee}
                    disabled={isSubmitted()}
                    newPeerAnswers={newPeerAnswers}
                  />
                )}
                <OptionalEvaluations
                  additionalPeersWithAnswers={additionalPeersWithAnswers}
                  questions={peerEvaluationQuestions}
                  peerCatalog={peerCatalog}
                  answers={peerAnswersDB}
                  setAnswers={setNewPeerAnswers}
                  senderUser={externalEmployee}
                  disabled={isSubmitted()}
                  additionalPeers={additionalPeers}
                  setAdditionalPeers={setAdditionalPeers}
                  showTitle={includeRelatedPeers}
                  newPeerAnswers={newPeerAnswers}
                />
              </EvaluationSectionBase>
            </>
          )}
          <div className="section-buttons">
            <AtomicButton
              className="button-primary-green btn-action"
              onClick={openSubmitEvaluationModal}
              disabled={isSubmitted()}
              size="medium"
            >
              Submit Evaluation
            </AtomicButton>
            <AtomicButton
              variant="contained"
              className="button-primary-light-blue ms-2 btn-action"
              disabled={isSubmitted() || isSaved}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handlePeerAnswerClick}
              size="medium"
              hasSpinner={spinner}
            >
              {spinner ? "Loading..." : "Save Progress"}
            </AtomicButton>
            <AtomicButton
              className="button-primary-light-blue ms-2 btn-action"
              onClick={openEditEvaluationModal}
              disabled={shouldBeEnabled()}
            >
              Edit Evaluation
            </AtomicButton>
          </div>
          <SubmitEvaluationModal
            modalTitle="Submit Evaluation"
            modalBody={
              <p>
                You <strong>cannot go back and edit</strong> your answers once
                you send the evaluation.
                <br /> Are you sure you want to submit it?
              </p>
            }
            sendButtonText="Submit"
            cancelButtonText="Cancel"
            show={showSubmitEvaluationModal}
            onClose={onCloseSubmitEvaluationModal}
            onAccept={handleSubmitEvaluation}
          />
          <WarningEvaluationModal
            modalTitle={modalTitle}
            modalBody={modalBody}
            show={showWarningEvaluationModal}
            onClose={onCloseWarningEvaluationModal}
          />
          <SubmitEvaluationModal
            modalTitle="Please tell us why you need to edit your evaluations"
            modalBody={
              <TextAreaAtom
                placeholder={"Type your thoughts"}
                style={{ width: "100%" }}
                rows={5}
                onChange={(event) => setEditionRequest(event.target.value)}
                inputProps={{ maxLength: 250 }}
              ></TextAreaAtom>
            }
            sendButtonText="Send Request"
            cancelButtonText="Cancel"
            show={showEditEvaluationModal}
            onClose={onCloseEditEvaluationModal}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onAccept={handleEditEvaluation}
          />
          <WarningEvaluationModal
            modalTitle="Your request for editing the form has been sent to an admin. Wait for approval."
            data-testid="approvalMessage"
            modalBody={undefined}
            show={showEditionConfirmationModal}
            onClose={onEditionConfirmationModal}
          />
        </UnidimensionalLayout>
      </ContainerAtom>
    );
};

export default EvaluationScreenTemp;
