import ContainerAtom from "../../atoms/Container";
import TitleAtom from "../../atoms/Title";
import "./styles.css";

interface ContainerBonusPreferencesMoleculeProps {
  form: JSX.Element;
}

const ContainerBonusPreferencesMolecule = ({
  form,
}: ContainerBonusPreferencesMoleculeProps) => {
  return (
    <ContainerAtom dataTestid="BonusPreferences" maxWidth="xl" disableGutters>
      <TitleAtom style={{ fontSize: "26px" }}>Preferences</TitleAtom>
      <hr className="hr-secundary"></hr>
      <ContainerAtom
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {form}
      </ContainerAtom>
    </ContainerAtom>
  );
};

export default ContainerBonusPreferencesMolecule;
