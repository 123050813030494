import { ScenarioAxiosRepository } from "./implementation/scenarioAxiosRepository";
import { GetScenarios } from "../application/use_cases/getScenarios";
import { ComponentAxiosRepository } from "./implementation/componentAxiosRepository";
import { EmployeeAxiosRepository } from "./implementation/employeeAxiosRepository";
import { EditScenario } from "../application/use_cases/editScenario";
import { CreateScenario } from "../application/use_cases/createScenario";
import { EditScenarioPreferences } from "../application/use_cases/editScenarioPreferences";
import { CreateComponent } from "../application/use_cases/createComponent";
import { DeleteComponent } from "../application/use_cases/deleteComponent";
import { UpdateEmployee } from "../application/use_cases/updateEmployee";
import { CheckComponentById } from "../application/use_cases/checkComponentById";
import { GetComponentsByScenario } from "../application/use_cases/getComponentsByScenario";
import { EditComponent } from "../application/use_cases/editComponent";
import { SubComponent } from "../domain/entities/subComponents";
import { GetScenario } from "../application/use_cases/getScenario";
import { UpdateWeightComponents } from "../application/use_cases/updateWeightComponents";
import { Component } from "../domain/entities/Component";
import { GetBonusCalculation } from "../application/use_cases/getBonusCalculation";
import { BonusCalculationResponse } from "../domain/entities/scenario";
import { InsertEmployees } from "../application/use_cases/insertEmployees";
import { DeleteScenario } from "../application/use_cases/deleteScenario";

export async function createScenario(name: string) {
  const scenarioRepository = new ScenarioAxiosRepository();
  const createScenarioUseCase = new CreateScenario(scenarioRepository);
  const resultScenario = await createScenarioUseCase.run(name);
  return resultScenario;
}

export async function getScenarios() {
  const scenarioRepository = new ScenarioAxiosRepository();
  const getScenariosUseCase = new GetScenarios(scenarioRepository);
  const result = await getScenariosUseCase.run();
  return result;
}

export async function editScenario(id: number, name: string) {
  const scenarioRepository = new ScenarioAxiosRepository();
  const editScenarioUseCase = new EditScenario(scenarioRepository);
  const result = await editScenarioUseCase.run(id, name);
  return result;
}

export async function editScenarioPreferences(
  id: number,
  generalBonus: number,
  performanceBonus: number,
  payrollProrated: number,
) {
  const scenarioRepository = new ScenarioAxiosRepository();
  const editScenarioPreferencesUseCase = new EditScenarioPreferences(
    scenarioRepository,
  );
  const result = await editScenarioPreferencesUseCase.run(
    id,
    generalBonus,
    performanceBonus,
    payrollProrated,
  );
  return result;
}

export async function getScenario(id: number) {
  const scenarioRepository = new ScenarioAxiosRepository();
  const getScenarioUseCase = new GetScenario(scenarioRepository);
  const result = await getScenarioUseCase.run(id);
  return result;
}

export async function createComponent(
  scenarioId: number,
  name: string,
  subComponents: SubComponent[],
) {
  const componentRepository = new ComponentAxiosRepository();
  const createComponentUseCase = new CreateComponent(componentRepository);
  const resultComponent = await createComponentUseCase.run(
    scenarioId,
    name,
    subComponents,
  );
  return resultComponent;
}

export async function getComponentsByScenario(scenarioId: number) {
  const componentRepository = new ComponentAxiosRepository();
  const GetComponentsByScenarioUseCase = new GetComponentsByScenario(
    componentRepository,
  );
  const componentList = await GetComponentsByScenarioUseCase.run(scenarioId);
  return componentList;
}

export async function deleteComponent(componentId: number) {
  const componentRepository = new ComponentAxiosRepository();
  const deleteComponentUseCase = new DeleteComponent(componentRepository);
  const componentList = await deleteComponentUseCase.run(componentId);
  return componentList;
}

export async function updateStatusEmployee(
  employeeId: string,
  scenarioId: number,
  status: boolean,
) {
  const employeeRepository = new EmployeeAxiosRepository();
  const updateEmployeeUseCase = new UpdateEmployee(employeeRepository);
  const updatedEmployee = await updateEmployeeUseCase.run(
    employeeId,
    scenarioId,
    status,
  );
  return updatedEmployee;
}

export async function editComponent(
  componentId: number,
  name: string,
  subComponents: SubComponent[],
  scenarioId: number,
) {
  const componentRepository = new ComponentAxiosRepository();
  const editComponentUseCase = new EditComponent(componentRepository);
  const resultComponent = await editComponentUseCase.run(
    componentId,
    name,
    subComponents,
    scenarioId,
  );
  return resultComponent;
}

export async function checkComponentById(componentId: number) {
  const componentRepository = new ComponentAxiosRepository();
  const checkComponentUseCase = new CheckComponentById(componentRepository);
  const componentList = await checkComponentUseCase.run(componentId);
  return componentList;
}

export async function updateWeightComponents(
  componentsList: Component[],
): Promise<{ success: string; error: string }> {
  const componentRepository = new ComponentAxiosRepository();
  const updateWeightUseCase = new UpdateWeightComponents(componentRepository);
  const response = await updateWeightUseCase.run(componentsList);
  return response;
}

export async function getBonusCalculation(
  scenarioId: number,
  performanceAvailable: number,
  percentageProrated: number,
): Promise<BonusCalculationResponse> {
  const scenarioRepository = new ScenarioAxiosRepository();
  const getBonusCalculationUseCase = new GetBonusCalculation(
    scenarioRepository,
  );
  const result = await getBonusCalculationUseCase.run(
    scenarioId,
    performanceAvailable,
    percentageProrated,
  );
  return result;
}

export async function insertEmployees(scenarioId: number): Promise<{
  message: string;
  error: string;
}> {
  const employeeRepository = new EmployeeAxiosRepository();
  const insertEmployeesUseCase = new InsertEmployees(employeeRepository);
  const result = await insertEmployeesUseCase.run(scenarioId);
  return result;
}

export async function deleteScenario(scenarioId: number, scenarioName: string) {
  const scenarioRepository = new ScenarioAxiosRepository();
  const deleteScenarioUseCase = new DeleteScenario(scenarioRepository);
  const respMessage = await deleteScenarioUseCase.run(scenarioId, scenarioName);
  return respMessage;
}
