import { SxProps } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { Theme } from "@mui/material/styles";

export interface BackdropAtomProps {
  open: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function BackdropAtom({
  open,
  children,
  sx,
}: BackdropAtomProps) {
  return (
    <Backdrop open={open} sx={sx}>
      {children}
    </Backdrop>
  );
}

BackdropAtom.defaultProps = {
  children: undefined,
  sx: { color: "#fff", zIndex: (theme: Theme) => theme.zIndex.drawer + 1 },
};
