import CheckboxAtom, { CheckboxAtomProps } from "../../atoms/Checkbox";
import LegendAtom from "../../atoms/Legend";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";

export interface CheckboxAndLabelProps extends CheckboxAtomProps {
  label: string;
}

export default function CheckboxAndLabel(props: CheckboxAndLabelProps) {
  const { label } = props;
  return (
    <UnidimensionalLayout
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <CheckboxAtom {...props} />
      <LegendAtom>{label}</LegendAtom>
    </UnidimensionalLayout>
  );
}
