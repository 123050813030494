import AtomicButton from "../../atoms/Button";
import Modal from "../../atoms/Modals";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";

export interface ConfirmationModalProps {
  feedbackRound: FeedbackRound;
  open: boolean;
  onClose: (shareFeedback: boolean) => void;
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { feedbackRound, open, onClose } = props;
  const title = "Are you sure you want to share feedback?";
  const description = (
    <p>
      This will allow every user to see the feedback they received on{" "}
      <b>{feedbackRound.name}</b>. You can&apos;t undo this action.
    </p>
  );

  const handleClick = (response: boolean) => {
    onClose(response);
  };
  const modalActions = (
    <UnidimensionalLayout
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      style={{
        width: "100%",
      }}
    >
      <AtomicButton
        type="button"
        size="small"
        className="button-primary-green"
        onClick={() => {
          handleClick(true);
        }}
      >
        Yes
      </AtomicButton>
      <AtomicButton
        type="button"
        size="small"
        className="button-primary-orange"
        onClick={() => {
          handleClick(false);
        }}
      >
        No
      </AtomicButton>
    </UnidimensionalLayout>
  );

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal
      modalTitle={title}
      modalContent={description}
      open={open}
      handleClose={handleClose}
      modalActions={modalActions}
    />
  );
}
