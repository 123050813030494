import UnidimensionalLayout from "../../atoms/UnidimensionalLayout/index";
import LegendAtom from "../../atoms/Legend";
import TextAreaAtom from "../../atoms/TextArea";

export interface WritingQuestionProps {
  defaultValue?: any;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  label?: React.ReactNode;
  name?: string;
  placeholder?: string;
  required?: boolean;
  style?: React.CSSProperties;
  maxRows?: number | string;
  minRows?: number | string;
  dataTestId?: string;
  className?: string;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
}

export default function WritingQuestion({
  defaultValue,
  disabled,
  error,
  fullWidth,
  helperText,
  id,
  label,
  maxRows,
  minRows,
  name,
  placeholder,
  required,
  style,
  dataTestId,
  className,
  onChange,
  onBlur,
}: WritingQuestionProps) {
  return (
    <UnidimensionalLayout
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
      style={{
        marginBottom: "15px",
      }}
    >
      <LegendAtom>
        <b>{label}</b>
      </LegendAtom>
      <TextAreaAtom
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        style={style}
        className={className}
        onBlur={onBlur}
        onChange={onChange}
        maxRows={maxRows}
        minRows={minRows}
        dataTestId={dataTestId}
      />
    </UnidimensionalLayout>
  );
}

WritingQuestion.defaultProps = {
  defaultValue: undefined,
  disabled: false,
  error: false,
  fullWidth: false,
  helperText: undefined,
  id: undefined,
  label: undefined,
  maxRows: undefined,
  minRows: undefined,
  placeholder: undefined,
  required: false,
  style: {},
  dataTestId: "questionMolecule",
  onChange: () => {},
  onBlur: () => {},
};
