import { performanceUrls } from "../config/performanceUrls";
import { EmployeeBamboo } from "../domain/entities/EmployeeBamboo";
import ApiHelper from "../infrastructure/implementation/apiHelper";
import {
  InputType,
  dataToCamelCase,
} from "../infrastructure/implementation/helpers";

const api = new ApiHelper();
const resolveEvaluationsUrl = (path: string) => {
  return `${performanceUrls.employee_bamboo}${path}`;
};

const insertEmployees = async (): Promise<EmployeeBamboo[]> => {
  const response = await api.request("post", resolveEvaluationsUrl(""));
  if (response.body === undefined) throw new Error(response);
  const employees: EmployeeBamboo[] = response.body.map((employee: InputType) =>
    dataToCamelCase(employee),
  );
  return employees;
};

export const employeeBambooServices = {
  insertEmployees,
};
