import Paper from "@mui/material/Paper";

export const PaperSurfaceVariants = {
  ELEVATION: "elevation",
  OUTLINED: "outlined",
};

export interface PaperSurfaceProps {
  children?: React.ReactNode;
  elevation?: number;
  square?: boolean;
  styles?: Object;
  variant?: "elevation" | "outlined";
  className?: string;
}

export default function PaperSurfaceAtom({
  children,
  elevation,
  square,
  styles,
  variant,
  className,
}: PaperSurfaceProps) {
  return (
    <Paper
      elevation={elevation}
      square={square}
      style={styles}
      variant={variant}
      className={className}
    >
      {children}
    </Paper>
  );
}

PaperSurfaceAtom.defaultProps = {
  children: "",
  elevation: 1,
  square: false,
  styles: {},
  variant: PaperSurfaceVariants.ELEVATION,
  className: undefined,
};
