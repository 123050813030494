import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export interface AccordionWrapperProps {
  children: NonNullable<React.ReactNode>;
  classes?: Object;
  defaultExpanded?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  expanded?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
}

export default function AccordionAtom({
  children,
  classes,
  defaultExpanded,
  disabled,
  className,
  style,
  expanded,
  onChange,
}: AccordionWrapperProps) {
  return (
    <Accordion
      classes={classes}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      className={className}
      style={style}
      expanded={expanded}
      onChange={onChange}
    >
      {children}
    </Accordion>
  );
}

AccordionAtom.defaultProps = {
  classes: {},
  defaultExpanded: false,
  disabled: false,
  className: undefined,
  style: {},
};
