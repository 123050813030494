import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

const BoxContainer = styled(Box)({
  position: "fixed",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
});

const LoadingComponent = () => {
  return (
    <BoxContainer display="flex" alignItems="center" justifyContent="center">
      <CircularProgress
        data-testid="loading-element"
        size={64}
        disableShrink
        thickness={3}
      />
    </BoxContainer>
  );
};

export default LoadingComponent;
