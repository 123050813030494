import { ScenarioRepository } from "../../domain/repositories/scenarioRepository";
import { Scenario } from "../../domain/entities/scenario";

export class CreateScenario {
  private readonly scenarioRepository: ScenarioRepository;

  public constructor(scenarioRepository: ScenarioRepository) {
    this.scenarioRepository = scenarioRepository;
  }

  public async run(name: string): Promise<Scenario> {
    return await this.scenarioRepository.create(name);
  }
}
