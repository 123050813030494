import {
  GridToolbarContainer,
  GridToolbarExport,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid";
import Table from "../../atoms/Tables/Index";
import { Component } from "../../domain/entities/Component";

interface BonusTableProps {
  columns: Array<GridColDef<Component>>;
  rows: readonly GridValidRowModel[];
  fileName: string;
  className?: string;
}

export default function TableMolecule({
  columns,
  rows,
  fileName,
  className,
}: BonusTableProps) {
  function ExportCSVButton() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: fileName + new Date().toLocaleString(),
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Table
      columns={columns}
      rows={rows}
      sortingOrder={["asc", "desc"]}
      components={{
        Toolbar: ExportCSVButton,
      }}
      className={className}
    />
  );
}
