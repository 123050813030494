import { useEffect, useState } from "react";
import { Component } from "../../../domain/entities/Component";
import { editComponent } from "../../../infrastructure/helpers";
import { SubComponent } from "../../../domain/entities/subComponents";
import { useAppSelector } from "../../../infrastructure/redux/hooks";
import Modal from "../../../atoms/Modals";
import { useForm } from "react-hook-form";
import FormComponentModalMolecule from "../../../molecules/FormComponentModal";

interface Props {
  disabled: boolean;
  show: boolean;
  data: Component;
  onClose: () => void;
  onAccept: () => void;
  onRefresh?: () => Promise<void>;
}

export default function EditComponentModal({
  show,
  data,
  onClose,
  onAccept,
}: Props) {
  const currentScenario = useAppSelector(
    (state) => state.scenarioReducer.scenario,
  );
  const [componentId, setComponentId] = useState<number>(0);
  const [componentName, setComponentName] = useState("");
  const [subComponentForm, setSubComponentName] = useState<SubComponent[]>([]);
  const [responseAxios, setResponseAxios] = useState("");

  const { setValue } = useForm();

  useEffect(() => {
    setValue("editComponentName", componentName);
  }, [componentName]);
  useEffect(() => {
    setComponentName(data.name);
    setSubComponentName(data.subComponents);
    setComponentId(data.id ?? 0);
  }, [data]);

  const addSubComponent = () => {
    const newSubComponent = { name: "", status: true };
    setSubComponentName([...subComponentForm, newSubComponent]);
  };

  const removeSubComponents = (index: number) => {
    const data = [...subComponentForm];
    data[`${index}`].status = false;
    setSubComponentName(data);
  };

  const handleFormChange = (index: number, value: string) => {
    const data = [...subComponentForm];
    data[`${index}`].name = value;
    data[`${index}`].status = true;
    setSubComponentName(data);
  };

  const submitChanges = () => {
    editComponent(
      componentId,
      componentName,
      subComponentForm,
      currentScenario?.id ?? 0,
    )
      .then(() => {
        setResponseAxios("");
        onAccept();
      })
      .catch((error) => {
        setResponseAxios(error.message);
        console.log(error);
      });
  };

  const modalBody = (
    <FormComponentModalMolecule
      submitForm={submitChanges}
      controllerName="editComponentName"
      onChange={(event: any) => {
        setComponentName(event.target.value);
        setResponseAxios("");
      }}
      responseError={responseAxios}
      subComponentForm={subComponentForm}
      handleFormChange={handleFormChange}
      removeSubComponents={removeSubComponents}
      addSubComponent={addSubComponent}
      buttonText="Edit"
      componentName={componentName}
    ></FormComponentModalMolecule>
  );

  return (
    <Modal
      open={show}
      handleClose={onClose}
      dataTestid="editComponentModal"
      modalTitle="Edit Component"
      modalContent={modalBody}
      classNameTitle="modal-title"
      bootstrapTitleStyle={true}
    />
  );
}
