import { mapValues, camelCase, snakeCase, mapKeys } from "lodash";

export type InputType = {
  [key in string | number]:
    | number[]
    | string[]
    | string
    | number
    | boolean
    | undefined
    | null;
};

export function dataToCamelCase(input: InputType) {
  if (Array.isArray(input)) {
    return input.map((item) => {
      if (typeof item !== "object") return item;
      return mapKeys(item, (_, key: string) => camelCase(key));
    });
  }

  const resp = mapKeys(input, (_, key: string) => camelCase(key)) as InputType;
  const respCamelCase = mapValues(resp, (item: InputType) => {
    if (Array.isArray(item) || typeof item === "object") {
      return dataToCamelCase(item);
    }
    return item;
  }) as InputType;

  return Object.keys(respCamelCase).length > 0 ? respCamelCase : input;
}

export function dataToSnakeCase(input: InputType) {
  if (Array.isArray(input)) {
    return input.map((item) => {
      if (typeof item !== "object") return item;
      return mapKeys(item, (_, key: string) => snakeCase(key));
    });
  }

  const resp = mapKeys(input, (_, key: string) => snakeCase(key)) as InputType;
  const respSnakeCase = mapValues(resp, (item: InputType) => {
    if (Array.isArray(item) || typeof item === "object") {
      return dataToSnakeCase(item);
    }
    return item;
  }) as InputType;

  return Object.keys(respSnakeCase).length > 0 ? respSnakeCase : input;
}

export const toSnakeCase = (obj: any) => {
  const newObj = mapKeys(obj, (value: any, key: any) => {
    return snakeCase(key);
  });
  return newObj;
};
