import { ClassNameMap, List, ListClasses, SxProps, Theme } from "@mui/material";

interface ListAtomProps {
  children: React.ReactNode;
  classes?: Partial<ListClasses> & Partial<ClassNameMap<never>>;
  dense?: boolean;
  disablePadding?: boolean;
  subheader?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const ListAtom = ({
  children,
  classes,
  dense,
  disablePadding,
  subheader,
  sx,
}: ListAtomProps) => {
  return (
    <List
      classes={classes}
      dense={dense}
      disablePadding={disablePadding}
      subheader={subheader}
      sx={sx}
    >
      {children}
    </List>
  );
};

export default ListAtom;
