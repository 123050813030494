import { ComponentRepository } from "../../domain/repositories/componentRepository";
import {
  AxiosResponse,
  Component,
  ComponentsScenarioEmployee,
} from "../../domain/entities/Component";
import { SubComponent } from "../../domain/entities/subComponents";
import { dataToCamelCase } from "./helpers";
import PerformanceApi from "./performanceApi";

export class ComponentAxiosRepository implements ComponentRepository {
  private readonly performanceApi = new PerformanceApi();

  async create(
    scenarioId: number,
    name: string,
    subcomponent: SubComponent[],
  ): Promise<AxiosResponse> {
    const response = await this.performanceApi.createComponent(
      scenarioId,
      name,
      subcomponent,
    );
    return response;
  }

  async updateComponents(
    componentList: any[],
    scenarioId: number,
  ): Promise<string> {
    const response = await this.performanceApi.updateComponents(
      componentList,
      scenarioId,
    );
    return response;
  }

  async updateComponent(
    component: ComponentsScenarioEmployee,
    scenarioId: number,
  ): Promise<string> {
    const response = await this.performanceApi.updateComponent(
      component,
      scenarioId,
    );
    return response;
  }

  async updateWeightComponents(
    componentList: Component[],
  ): Promise<{ success: string; error: string }> {
    const response = await this.performanceApi.updateWeightComponents(
      componentList,
    );
    return response;
  }

  async getComponentsByScenario(scenarioId: number): Promise<Component[]> {
    const response = await this.performanceApi.getComponentsByScenario(
      scenarioId,
    );
    const componentList: Component[] = [];
    for (const componentResponse of response.body) {
      const component: Component = {
        id: componentResponse.id,
        name: componentResponse.name,
        weight: componentResponse.weight,
        description: componentResponse.description,
        subComponents: componentResponse.sub_components,
      };
      componentList.push(component);
    }
    return componentList;
  }

  async deleteComponent(componentId: number): Promise<string> {
    const response = await this.performanceApi.deleteComponent(componentId);
    return response;
  }

  async editComponent(
    componentId: number,
    name: string,
    subcomponent: SubComponent[],
    scenarioId: number,
  ): Promise<AxiosResponse> {
    const response = await this.performanceApi.editComponent(
      componentId,
      name,
      subcomponent,
      scenarioId,
    );
    return response;
  }

  async checkComponentById(componentId: number) {
    const response = await this.performanceApi.checkComponentById(componentId);
    const componentResponse: number = response.body.number_of_coincidences;
    return componentResponse;
  }

  async getComponentsScenarioEmployee(
    scenarioId: number,
  ): Promise<ComponentsScenarioEmployee[]> {
    const response = await this.performanceApi.getComponentsScenarioEmployee(
      scenarioId,
    );
    const componentList: ComponentsScenarioEmployee[] = [];
    for (const componentResponse of response.body) {
      const component = dataToCamelCase(componentResponse);
      componentList.push(component as unknown as ComponentsScenarioEmployee);
    }
    return componentList;
  }
}
