import { EmployeeRepository } from "../../domain/repositories/employeeRepository";
import { Employee } from "../../domain/entities/Employee";

export class UpdateEmployee {
  private readonly employeeRepository: EmployeeRepository;

  public constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  public async run(
    employeeId: string,
    scenarioId: number,
    status: boolean,
  ): Promise<Employee> {
    return await this.employeeRepository.updateEmployeeStatus(
      employeeId,
      scenarioId,
      status,
    );
  }
}
