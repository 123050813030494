import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanelBase from "../../atoms/TabPanel";

export interface BasicTabsProps {
  tabInfo: Array<{
    tabLabel: string;
    tabChildren?: React.ReactNode;
  }>;
  selectedTab: number;
  setSelectedTab: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
  ) => void;
}
export default function BasicTabs({
  tabInfo,
  selectedTab,
  setSelectedTab,
}: BasicTabsProps) {
  function propsGenerator(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={setSelectedTab}>
          {tabInfo.map((val, index) => {
            return (
              <Tab
                data-testid={val.tabLabel}
                label={val.tabLabel}
                {...propsGenerator(index)}
                key={`tabs-${index}`}
              />
            );
          })}
        </Tabs>
      </Box>

      {tabInfo.map((val, index) => {
        return (
          <TabPanelBase value={selectedTab} index={index} key={`tabs-${index}`}>
            {val.tabChildren}
          </TabPanelBase>
        );
      })}
    </Box>
  );
}
