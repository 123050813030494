import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export interface AccordionSummaryAtomProps {
  children: React.ReactNode;
  classes?: Object;
  className?: string;
  dataTestId?: string;
}

export default function AccordionSummaryAtom({
  children,
  classes,
  className,
  dataTestId,
}: AccordionSummaryAtomProps) {
  return (
    <AccordionSummary
      className={className}
      classes={classes}
      data-testid={dataTestId}
    >
      {children}
    </AccordionSummary>
  );
}

AccordionSummaryAtom.defaultProps = {
  classes: {},
  className: "",
  dataTestId: "accordionSummary",
};
