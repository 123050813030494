import Rating from "@mui/material/Rating";
import StarBorder from "@mui/icons-material/StarBorder";
import Star from "@mui/icons-material/Star";

export const RatingSizes = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const EMPTY_ICON = <StarBorder fontSize="inherit" />;
const ICON = <Star fontSize="inherit" />;
const DEFAULT_PRESICION = 1;

export interface RatingProps {
  classes?: Object;
  defaultValue?: number;
  disabled?: boolean;
  emptyIcon?: React.ReactNode;
  emptyLabelText?: React.ReactNode;
  getLabelText?: (value: number) => string;
  highlightSelectedOnly?: boolean;
  icon?: React.ReactNode;
  max?: number;
  name?: string;
  onChange: (event: React.SyntheticEvent, value: number | null) => void;
  precision: number;
  readOnly?: boolean;
  size: "small" | "medium" | "large";
  value?: number;
  dataTestId?: string;
}

export default function RatingAtom({
  classes,
  defaultValue,
  disabled,
  emptyIcon,
  emptyLabelText,
  getLabelText,
  highlightSelectedOnly,
  icon,
  max,
  name,
  onChange,
  precision,
  readOnly,
  size,
  value,
  dataTestId,
}: RatingProps) {
  return (
    <Rating
      classes={classes}
      defaultValue={defaultValue}
      disabled={disabled}
      emptyIcon={emptyIcon}
      emptyLabelText={emptyLabelText}
      getLabelText={getLabelText}
      highlightSelectedOnly={highlightSelectedOnly}
      icon={icon}
      max={max}
      name={name}
      onChange={onChange}
      precision={precision}
      readOnly={readOnly}
      size={size}
      value={value}
      data-testid={dataTestId}
    />
  );
}

RatingAtom.defaultProps = {
  classes: {},
  defaultValue: undefined,
  disabled: false,
  emptyIcon: EMPTY_ICON,
  emptyLabelText: "Empty",
  getLabelText: (value: number) => `${value} Star${value !== 1 ? "s" : ""}`,
  highlightSelectedOnly: false,
  icon: ICON,
  name: undefined,
  onChange: () => {},
  precision: DEFAULT_PRESICION,
  readOnly: false,
  size: RatingSizes.MEDIUM,
  value: undefined,
  dataTestId: "ratingInput",
};
