import { ComponentsScenarioEmployee } from "../../domain/entities/Component";
import { ComponentRepository } from "../../domain/repositories/componentRepository";

export class UpdateComponentsUseCase {
  private readonly componentRepository: ComponentRepository;

  public constructor(componentRepository: ComponentRepository) {
    this.componentRepository = componentRepository;
  }

  public async run(
    componentsList: ComponentsScenarioEmployee[],
    scenarioId: number,
  ) {
    return await this.componentRepository.updateComponents(
      componentsList,
      scenarioId,
    );
  }
}
