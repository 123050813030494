import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CreateScenarioModal from "../../scenario/CreateScenarioModal";
import { toast } from "react-toastify";
import { useUserAPI } from "../../../../context/userAPIContext";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  navigateIfAuthenticated,
  changeStateIfAuthenticated,
} from "../../../../utils/authChecker";
import Button from "../../../../atoms/Button";
import { employeeBambooServices } from "../../../../services/employeeBambooServices";

const HomeBonusComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const { checkUserAuthentication } = useUserAPI();
  const navigate = useNavigate();

  const onClose = () => {
    setShowModal(false);
  };

  const onCreate = () => {
    toast.success("Scenario created successfully");
    onClose();
  };

  function insertEmployees(message: string) {
    return async () => {
      try {
        await employeeBambooServices.insertEmployees();
        if (message !== "") {
          toast.success(message);
        }
      } catch (err: any) {
        toast.error(err.message);
      }
    };
  }

  const handleInsertEmployees = insertEmployees(
    "Employees inserted successfully",
  );

  useEffect(() => {
    checkUserAuthentication();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="vh-100 text-center">
      <Row className="position-relative top-50 start-50 translate-middle">
        <Col>
          <Button
            className="button-primary-light-blue"
            dataTestid="selectScenario"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated("/ListScenarios", navigate);
              })();
            }}
          >
            Select scenario
          </Button>{" "}
        </Col>
        <Col>
          <Button
            className="button-primary-light-blue"
            dataTestid="createNewScenario"
            onClick={() => {
              void (async () => {
                await changeStateIfAuthenticated({
                  myState: setShowModal,
                  stateValue: true,
                  myNavigate: navigate,
                });
              })();
            }}
          >
            Create new scenario
          </Button>{" "}
        </Col>
        <Col>
          <Button
            className="button-primary-light-blue"
            dataTestid="getEmployee"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleInsertEmployees}
          >
            Get employees
          </Button>
        </Col>
      </Row>
      <CreateScenarioModal
        show={showModal}
        close={onClose}
        onCreate={onCreate}
      />
    </Container>
  );
};
export default HomeBonusComponent;
