import { Checkbox, CheckboxProps } from "@mui/material";

export interface CheckboxAtomProps extends CheckboxProps {
  dataTestId?: string;
}

export default function CheckboxAtom(props: CheckboxAtomProps) {
  const { dataTestId } = props;
  const checkboxBaseProps = { ...props };
  delete checkboxBaseProps.dataTestId;
  return <Checkbox {...checkboxBaseProps} data-testid={dataTestId} />;
}

CheckboxAtom.defaultProps = {
  dataTestId: "checkbox",
};
