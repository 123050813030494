import { Scenario } from "../../../domain/entities/scenario";
import * as constScenario from "./constants";

const sortScenarios = (scenarios: Scenario[], orderBy: "asc" | "desc") => {
  switch (orderBy) {
    case "asc":
      return scenarios.sort((firstScenario, secondScenario) => {
        if (firstScenario.creationDate == null) {
          return constScenario.DATE_SECOND_SCENARIO_IS_GREATER;
        } else if (secondScenario.creationDate == null) {
          return constScenario.DATE_FIRST_SCENARIO_IS_GREATER;
        } else if (
          firstScenario.creationDate == null &&
          secondScenario.creationDate == null
        ) {
          return constScenario.SCENARIOS_WITH_EQUAL_DATES;
        } else if (
          new Date(firstScenario.creationDate) >
          new Date(secondScenario.creationDate)
        ) {
          return constScenario.DATE_FIRST_SCENARIO_IS_GREATER;
        } else if (
          new Date(secondScenario.creationDate) >
          new Date(firstScenario.creationDate)
        ) {
          return constScenario.DATE_SECOND_SCENARIO_IS_GREATER;
        } else {
          return constScenario.SCENARIOS_WITH_EQUAL_DATES;
        }
      });

    case "desc":
      return scenarios.sort((firstScenario, secondScenario) => {
        if (firstScenario.creationDate == null) {
          return constScenario.DATE_SECOND_SCENARIO_IS_LESS;
        } else if (secondScenario.creationDate == null) {
          return constScenario.DATE_FIRST_SCENARIO_IS_LESS;
        } else if (
          firstScenario.creationDate == null &&
          secondScenario.creationDate == null
        ) {
          return constScenario.SCENARIOS_WITH_EQUAL_DATES;
        } else if (
          new Date(firstScenario.creationDate) <
          new Date(secondScenario.creationDate)
        ) {
          return constScenario.DATE_FIRST_SCENARIO_IS_LESS;
        } else if (
          new Date(secondScenario.creationDate) <
          new Date(firstScenario.creationDate)
        ) {
          return constScenario.DATE_SECOND_SCENARIO_IS_LESS;
        } else {
          return constScenario.SCENARIOS_WITH_EQUAL_DATES;
        }
      });
  }
};
export { sortScenarios };
