import React, { useCallback, useRef, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Snackbar, Typography } from "@mui/material";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../../domain/entities/Component";
import SubComponentListComponent from "./SubComponentEditComponent";
import "../../styles/employeeDetail/ComponentEditStyle.css";
import { debounce, isEqual, uniqBy, uniqWith } from "lodash";
import {
  updateComponentsThunk,
  updateComponentThunk,
  componentsUpdate,
} from "../../../infrastructure/redux/features/componentsSlice";
import "react-toastify/dist/ReactToastify.css";
import { useTypedDispatch } from "../../../infrastructure/redux/store";
import { onlyWholeNumber } from "../../helpers/unput-utils";
import {
  ERRORSCOREMSG,
  ERRORCOMMENTMSG,
  MAXSCORE,
  MINSCORE,
} from "../constants";
import InputAtom from "../../../atoms/Input";

interface Props {
  components: Component[];
  employeeId: string;
  scenarioId: number;
  componentsForEmployee: ComponentsScenarioEmployee[];
  employeeStatus: boolean;
}

export function updateComponentDescription(
  ComponentList: React.MutableRefObject<Component[]>,
  value: string,
  componentIndex: number,
) {
  const currentValues = [...ComponentList.current];
  try {
    currentValues[`${componentIndex}`].description = value;
    ComponentList.current = currentValues;
  } catch (exception) {
    console.log(exception);
  }
}

export function updateComponentScore(
  ComponentList: React.MutableRefObject<Component[]>,
  score: number,
  componentIndex: number,
) {
  const currentValues = [...ComponentList.current];

  try {
    currentValues[`${componentIndex}`].score = score;
    ComponentList.current = currentValues;
  } catch (exception) {
    console.log(exception);
  }
}

const ComponentsList = ({
  components,
  employeeId,
  scenarioId,
  componentsForEmployee,
  employeeStatus,
}: Props) => {
  const dispatch = useTypedDispatch();

  const componentsByEmployee = useRef<ComponentsScenarioEmployee[]>([]);
  const [open, setOpen] = React.useState(false);

  //  ------------------------------------------------------------ HANDLERS ------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  function saveChanges(scenarioId: number) {
    void dispatch(updateComponentsThunk({ scenarioId }));
  }

  function saveComponentChange(
    scenarioId: number,
    componentEmployee: ComponentsScenarioEmployee,
  ) {
    void dispatch(
      updateComponentThunk({
        scenarioId,
        componentEmployee,
      }),
    );
  }

  function saveChangesHandler() {
    const components = componentsByEmployee.current;

    const componentsToSave = uniqBy(components, "componentId");

    const isValid = componentsToSave.some(
      (comp) => comp.score < MINSCORE || comp.score > MAXSCORE,
    );

    if (!isValid) {
      dispatch(componentsUpdate(componentsToSave));
      saveChanges(scenarioId);
      setOpen(true);
    }
  }

  function saveComponentEmployee(
    componentIndex: number,
    componentEmployee: ComponentsScenarioEmployee,
  ) {
    const components = componentsByEmployee.current;
    components[`${componentIndex}`] = componentEmployee;
    const componentsToSave = uniqBy(components, "componentId");

    dispatch(componentsUpdate(componentsToSave));
    saveComponentChange(scenarioId, componentEmployee);
    setOpen(true);
  }

  const debouncedSaveChanges = useCallback(
    debounce(saveChangesHandler, 1000),
    [],
  );

  const loadComponents = (component: any) => {
    const prevComponents = uniqWith(
      [...componentsByEmployee.current, component],
      isEqual,
    );
    componentsByEmployee.current = prevComponents.filter(
      (comp) => comp.employeeId === employeeId,
    );
  };

  const updateComponentScoreHandler =
    (
      componentIndex: number,
      setShowScoreMessage: React.Dispatch<React.SetStateAction<boolean>>,
      setShowCommentMessage: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>
    (event: any) => {
      const { value, name } = event.target;
      setShowScoreMessage(false);
      setShowCommentMessage(false);
      if (name === "score" && (value < MINSCORE || value > MAXSCORE))
        return setShowScoreMessage(true);
      try {
        const currentComponentByEmployee =
          componentsByEmployee.current[`${componentIndex}`];
        const updatedComponentByEmployee =
          name === "comment"
            ? {
                ...currentComponentByEmployee,
                comment: value,
              }
            : {
                ...currentComponentByEmployee,
                score: value,
              };
        if (updatedComponentByEmployee.score === null) {
          componentsByEmployee.current[`${componentIndex}`] =
            updatedComponentByEmployee;
          return setShowCommentMessage(true);
        }
        saveComponentEmployee(componentIndex, updatedComponentByEmployee);
      } catch (exception) {
        console.log(exception);
      }
    };

  const handleChangeSubComponent =
    (componentIndex: number) => (event: any, subComponentIndex: number) => {
      event.preventDefault();
      try {
        const refCopy = [...componentsByEmployee.current];
        const subComponentsCopy = [
          ...refCopy[`${componentIndex}`].subComponents,
        ];

        subComponentsCopy[`${subComponentIndex}`] = {
          ...subComponentsCopy[`${subComponentIndex}`],
          comment: event.target.value,
        };

        refCopy[`${componentIndex}`] = {
          ...refCopy[`${componentIndex}`],
          subComponents: subComponentsCopy,
        };

        componentsByEmployee.current[`${componentIndex}`] =
          refCopy[`${componentIndex}`];
        debouncedSaveChanges();
      } catch (exception) {
        console.log(exception);
      }
    };

  const getSubComponents = (subComponents: any[]) => {
    if (subComponents !== undefined)
      return subComponents.map((subComp: any) => {
        return {
          id: null,
          subComponentId: subComp.id,
          comment: "",
        };
      });
    return [];
  };

  const loadSubComponents = (subComponents: any, subComponentsData: any) => {
    if (subComponents !== undefined) {
      return subComponents.map((subComp: any) => {
        const indexSubData = subComponentsData.findIndex(
          (compData: any) => compData.subComponentId === subComp.id,
        ) as number;
        return {
          id:
            subComponentsData[`${indexSubData}`] !== undefined
              ? subComponentsData[`${indexSubData}`].id
              : null,
          comment:
            subComponentsData[`${indexSubData}`] !== undefined
              ? subComponentsData[`${indexSubData}`].comment
              : "",
          subComponentId:
            subComponentsData[`${indexSubData}`] !== undefined
              ? subComponentsData[`${indexSubData}`].subComponentId
              : subComp.id ?? 0,
        };
      });
    }
    return [];
  };

  //  ------------------------------------------------------------ SECONDARY COMPONENTS ------------------------------------------------------------

  const ComponentListComponent = () => {
    return (
      <Container>
        {components?.map((component, componentIndex) => {
          const [showScoreMessage, setShowScoreMessage] =
            useState<boolean>(false);
          const [showCommentMessage, setShowCommentMessage] =
            useState<boolean>(false);
          const componentData = componentsForEmployee.find(
            (comp) => comp.componentId === component.id,
          );
          componentData !== undefined
            ? loadComponents({
                ...componentData,
                subComponents: loadSubComponents(
                  component.subComponents,
                  componentData.subComponents,
                ),
              })
            : loadComponents({
                id: null,
                score: null,
                scenarioId,
                componentId: component.id,
                employeeId,
                comment: "",
                subComponents: getSubComponents(component.subComponents),
              });
          return (
            <Container
              className="component-container"
              key={`${employeeId}-${component.id}-${scenarioId}`}
            >
              <Row>
                <Col md="auto" className="d-flex align-items-center">
                  <h4>Component {component.name}</h4>
                  <Typography className="component-weight">{`(${component.weight}%)`}</Typography>
                </Col>
                <Col>
                  {showScoreMessage && (
                    <p
                      className="error-message"
                      data-testid="error-msg-score-out-range"
                    >
                      {ERRORSCOREMSG}
                    </p>
                  )}
                  {showCommentMessage && (
                    <p
                      className="error-message"
                      data-testid="error-msg-score-out-range"
                    >
                      {ERRORCOMMENTMSG}
                    </p>
                  )}
                </Col>
                <Col className="d-flex align-items-center" xs={3}>
                  <h4>Score </h4>
                  <InputAtom
                    style={{ width: "45%" }}
                    size="small"
                    className="score-input"
                    dataTestid="scoreInputField"
                    type="number"
                    min="0"
                    max="3"
                    name="score"
                    onKeyPress={(event) => {
                      onlyWholeNumber(event);
                    }}
                    defaultValue={componentData?.score}
                    onBlur={updateComponentScoreHandler(
                      componentIndex,
                      setShowScoreMessage,
                      setShowCommentMessage,
                    )}
                  />
                </Col>
                <hr />
              </Row>
              <Row>
                <span className="component-span">
                  <textarea
                    className="component-input mb-4"
                    aria-label={component.name}
                    defaultValue={componentData?.comment}
                    name="comment"
                    onBlur={updateComponentScoreHandler(
                      componentIndex,
                      setShowScoreMessage,
                      setShowCommentMessage,
                    )}
                  />
                </span>
              </Row>
              <SubComponentListComponent
                subComponents={component.subComponents}
                subComponentsForEmployee={componentData?.subComponents}
                onChangeSubComponents={handleChangeSubComponent(componentIndex)}
              />
            </Container>
          );
        })}
      </Container>
    );
  };

  //  ------------------------------------------------------------  MAIN COMPONENT ------------------------------------------------------------
  return (
    <Container>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={600}
        message="Saving..."
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Row className="mt-1">
        <Col className="d-flex align-items-md-start">
          <h3>Components</h3>
        </Col>
        <hr />
      </Row>

      {employeeStatus ? (
        <ComponentListComponent />
      ) : (
        <Typography variant="overline">
          Currently you can not fill this user data. User deactivated.
        </Typography>
      )}
    </Container>
  );
};

export default ComponentsList;
