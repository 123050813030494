import ListItemMolecule from "../../molecules/ListItem";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import ListAtom from "../../atoms/List";

interface SharedFeedbackListProps {
  sharedFeedbackRounds: FeedbackRound[];
  onClick: React.Dispatch<React.SetStateAction<string>>;
  selectedFeedbackRoundId: string;
}

const SharedFeedbackList = ({
  sharedFeedbackRounds,
  onClick,
  selectedFeedbackRoundId,
}: SharedFeedbackListProps) => {
  return (
    <ListAtom>
      {sharedFeedbackRounds.map((sharedFeedbackRound, index) => (
        <ListItemMolecule
          primary={sharedFeedbackRound.name}
          onClick={() => onClick(sharedFeedbackRound.id)}
          key={index}
          selected={selectedFeedbackRoundId === sharedFeedbackRound.id}
          sx={{
            "&:hover": {
              background: "#30BCED",
            },
            "&.Mui-selected": {
              backgroundColor: "#30BCED",
            },
          }}
        />
      ))}
    </ListAtom>
  );
};

export default SharedFeedbackList;
