import { useState } from "react";
import { SubComponent } from "../../../domain/entities/subComponents";
import { useAppSelector } from "../../../infrastructure/redux/hooks";
import { useTypedDispatch } from "../../../infrastructure/redux/store";
import { createComponentThunk } from "../../../infrastructure/redux/features/componentsSlice";
import Modal from "../../../atoms/Modals";
import { useForm } from "react-hook-form";
import FormComponentModalMolecule from "../../../molecules/FormComponentModal";

interface Props {
  show: boolean;
  onClose: () => void;
  onAccept: () => void;
  onRefresh?: () => Promise<void>;
}

export default function CreateComponentModal({
  show,
  onClose,
  onAccept,
}: Props) {
  const dispatch = useTypedDispatch();
  const currentScenario = useAppSelector(
    (state) => state.scenarioReducer.scenario,
  );
  const [subComponentForm, setSubComponentName] = useState<SubComponent[]>([]);
  const [responseAxios, setResponseAxios] = useState("");

  const { reset: resetForm } = useForm();

  const addSubComponent = () => {
    const newSubComponent = { name: "" };

    setSubComponentName([...subComponentForm, newSubComponent]);
  };

  const removeSubComponents = (index: number) => {
    const data = [...subComponentForm];
    data.splice(index, 1);
    setSubComponentName(data);
  };

  const handleFormChange = (index: number, value: string) => {
    const data = [...subComponentForm];
    data[`${index}`] = { name: value };
    setSubComponentName(data);
  };

  const successCallback = (
    successResponse: boolean = false,
    errorMessage: string = "",
  ) => {
    if (successResponse) {
      setResponseAxios("");
      setSubComponentName([]);
      onAccept();
    } else {
      setResponseAxios(errorMessage);
    }
  };

  const submitForm = (data: any) => {
    void dispatch(
      createComponentThunk({
        scenarioId: currentScenario?.id ?? 0,
        componentName: data.componentNameInput,
        subComponents: subComponentForm,
        successCallback,
      }),
    );
    resetForm();
  };

  const modalBody = (
    <FormComponentModalMolecule
      submitForm={submitForm}
      controllerName="componentNameInput"
      onChange={() => setResponseAxios("")}
      responseError={responseAxios}
      subComponentForm={subComponentForm}
      handleFormChange={handleFormChange}
      removeSubComponents={removeSubComponents}
      addSubComponent={addSubComponent}
      buttonText="Create"
    ></FormComponentModalMolecule>
  );

  return (
    <Modal
      open={show}
      handleClose={onClose}
      dataTestid="createComponentModal"
      modalTitle="Create New Component"
      modalContent={modalBody}
      classNameTitle="modal-title"
      bootstrapTitleStyle={true}
    />
  );
}
