import { createContext, useContext, useEffect, useState } from "react";
import { getUserPermissions } from "../infrastructure/authorization";
import { createTheme } from "@mui/material";
import { purple } from "@mui/material/colors";
import EnvManager from "../config/envManager";

import * as CryptoTS from "crypto-ts";

interface contextValues {
  user?: any;
  checkUserAuthentication: any;
  theme?: any;
  setTheme?: any;
  defaultTheme?: any;
}

const contextReturnValues: contextValues = {
  checkUserAuthentication: () => Promise<void>,
};

const UserAPIContext = createContext(contextReturnValues);

const baseTheme = createTheme({
  palette: {
    primary: {
      main: purple[500],
    },
  },
});

export const UserAPIContextProvider = ({ children }: any) => {
  const [user, setUser] = useState(
    sessionStorage.getItem("user") !== null
      ? JSON.parse(
          CryptoTS.AES.decrypt(
            sessionStorage.getItem("user") ?? "",
            EnvManager.SESSION_STORAGE_KEY,
          ).toString(CryptoTS.enc.Utf8) ?? "",
        )
      : null,
  );

  const [theme, setTheme] = useState(baseTheme);

  const fetchAndCheckUserPermissions = async () => {
    const user = await getUserPermissions();
    if (user != null) {
      const userData = JSON.stringify(user);
      const cipherUser = await CryptoTS.AES.encrypt(
        userData,
        EnvManager.SESSION_STORAGE_KEY,
      );
      const cipherUserStr = cipherUser.toString();
      sessionStorage.setItem("user", cipherUserStr);
      setUser(user);
    } else {
      sessionStorage.clear();
      setUser(null);
    }
  };

  useEffect(() => {
    fetchAndCheckUserPermissions().catch((error) => console.log(error));
  }, []);

  return (
    <UserAPIContext.Provider
      value={{
        user,
        checkUserAuthentication: fetchAndCheckUserPermissions,
        theme,
        setTheme,
        defaultTheme: baseTheme,
      }}
    >
      {children}
    </UserAPIContext.Provider>
  );
};

export const useUserAPI = () => {
  const context = useContext(UserAPIContext);
  if (context == null) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
