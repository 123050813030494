import { useEffect } from "react";
import DatePickerAtom from "../../atoms/DatePicker";
import InputAtom from "../../atoms/Input";
import UnidemensionalLayout from "../../atoms/UnidimensionalLayout";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import dayjs from "dayjs";

interface FeedbackRoundInfoProps {
  setSelectStartDate: (date: string) => void;
  setSelectEndDate: (date: string) => void;
  setFeedbackRoundName: (value: React.SetStateAction<string>) => void;
  selectStartDate: string;
  clearDataFeedBackRoundInfo: boolean;
  feedbackRound?: FeedbackRound;
}

export default function FeedBackRoundInfo({
  setFeedbackRoundName,
  setSelectEndDate,
  setSelectStartDate,
  selectStartDate,
  clearDataFeedBackRoundInfo,
  feedbackRound,
}: FeedbackRoundInfoProps) {
  useEffect(() => {
    if (clearDataFeedBackRoundInfo) {
      setFeedbackRoundName("");
      setSelectEndDate("");
      setSelectStartDate("");
    }
  }, [clearDataFeedBackRoundInfo]);

  return (
    <>
      <InputAtom
        placeholder={"Feedback round name"}
        required={true}
        size="medium"
        defaultValue={feedbackRound?.name}
        onChange={(event) => setFeedbackRoundName(event.target.value)}
      />

      <UnidemensionalLayout
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <DatePickerAtom
          className="date-input-spacing"
          label="Start date"
          defaultValue={
            feedbackRound?.startDate === undefined
              ? ""
              : dayjs(feedbackRound?.startDate)
          }
          onChange={(date) => setSelectStartDate(date)}
        />
        <div>-</div>
        <DatePickerAtom
          className="date-input-spacing"
          label="End date"
          minDate={selectStartDate}
          onChange={(date) => setSelectEndDate(date)}
          defaultValue={
            feedbackRound?.endDate === undefined
              ? ""
              : dayjs(feedbackRound?.endDate)
          }
        />
      </UnidemensionalLayout>
    </>
  );
}
