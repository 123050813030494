import { ScenarioRepository } from "../../domain/repositories/scenarioRepository";

export class DeleteScenario {
  private readonly scenarioRepository: ScenarioRepository;

  public constructor(scenarioRepository: ScenarioRepository) {
    this.scenarioRepository = scenarioRepository;
  }

  public async run(scenarioId: number, scenarioName: string): Promise<string> {
    return await Promise.resolve(
      this.scenarioRepository.deleteScenario(scenarioId, scenarioName),
    );
  }
}
