import UnidimensionalLayout from "../../atoms/UnidimensionalLayout/index";
import RatingAtom from "../../atoms/Rating";

export interface RatingAnswerProps {
  description: string;
  averageScore: number;
  max: number;
}

export default function RatingAnswer({
  description,
  averageScore,
  max,
}: RatingAnswerProps) {
  return (
    <UnidimensionalLayout
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={10}
    >
      <b>{`${description}${averageScore}`}</b>
      <RatingAtom
        size="large"
        value={averageScore}
        precision={0.1}
        readOnly={true}
        max={max}
      />
    </UnidimensionalLayout>
  );
}
