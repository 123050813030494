import { Component } from "../../domain/entities/Component";
import { ComponentRepository } from "../../domain/repositories/componentRepository";

export class UpdateWeightComponents {
  private readonly componentRepository: ComponentRepository;

  public constructor(componentRepository: ComponentRepository) {
    this.componentRepository = componentRepository;
  }

  public async run(
    componentsList: Component[],
  ): Promise<{ success: string; error: string }> {
    return await this.componentRepository.updateWeightComponents(
      componentsList,
    );
  }
}
