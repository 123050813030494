import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import ContainerAtom from "../../atoms/Container";
import InputAtom from "../../atoms/Input";
import "./styles.css";

const BonusInputFieldMolecule = (
  describedText: string,
  state: string,
  setState?: React.Dispatch<React.SetStateAction<string>>,
) => {
  const newValue = state.replace(/,/g, "");
  const isStateLess = Number(newValue) < 0;
  const { control } = useForm();
  const lastValue = useRef("");
  const transformToIntlFormat = (value: string = "") => {
    const parsedValue = value.replace(/,/g, "");
    return new Intl.NumberFormat().format(Math.abs(Number(parsedValue)));
  };
  const saveInputState = (inputValue: string) => setState?.(inputValue);
  return (
    <ContainerAtom
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        width: "100%",
        padding: "10px",
        alignItems: "center",
        justifyItems: "start",
      }}
    >
      <p>{describedText}</p>

      <Controller
        control={control}
        name={describedText}
        render={({ field: { onChange, value, name } }) => {
          if (newValue !== "" && value === undefined) {
            value = newValue;
          }
          let formattedValue = transformToIntlFormat(value);
          if (formattedValue !== "NaN") {
            lastValue.current = formattedValue;
          } else {
            formattedValue = lastValue.current;
          }
          return (
            <InputAtom
              name={name}
              onChange={onChange}
              onBlur={() => saveInputState(formattedValue)}
              size="small"
              className="input-alignment"
              dataTestid="generalTextInputField"
              type="text"
              error={isStateLess}
              value={formattedValue}
            />
          );
        }}
      />
    </ContainerAtom>
  );
};

export default BonusInputFieldMolecule;
