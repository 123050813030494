import { EmployeeRepository } from "../../domain/repositories/employeeRepository";
import { Employee } from "../../domain/entities/Employee";
import { dataToCamelCase } from "./helpers";
import PerformanceApi from "./performanceApi";

export class EmployeeAxiosRepository implements EmployeeRepository {
  private readonly performanceApi = new PerformanceApi();

  async getEmployees(scenarioId: number): Promise<Employee[]> {
    const response = await this.performanceApi.getEmployees(scenarioId);
    const employeeList: Employee[] = [];
    for (const employeeResponse of response.body) {
      const employee = dataToCamelCase(employeeResponse);
      employeeList.push(employee as unknown as Employee);
    }
    return employeeList;
  }

  async updateEmployeeStatus(
    employeeId: string,
    scenarioId: number,
    status: boolean,
  ): Promise<Employee> {
    const response = await this.performanceApi.updateEmployeeStatus(
      employeeId,
      scenarioId,
      status,
    );
    const updatedEmployee: Employee = response.body;
    return updatedEmployee;
  }

  async insertEmployees(
    scenarioId: number,
  ): Promise<{ message: string; error: string }> {
    const response = await this.performanceApi.insertEmployees(scenarioId);
    return response;
  }
}
