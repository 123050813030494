import { ComponentRepository } from "../../domain/repositories/componentRepository";
import { Component } from "../../domain/entities/Component";

export class GetComponentsByScenario {
  private readonly componentRepository: ComponentRepository;

  public constructor(componentRepository: ComponentRepository) {
    this.componentRepository = componentRepository;
  }

  public async run(scenarioId: number): Promise<Component[]> {
    return await Promise.resolve(
      this.componentRepository.getComponentsByScenario(scenarioId),
    );
  }
}
