import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import * as gridDataGenerator from "../../presentation/components/bonusCalculation/gridDataGenerator";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../domain/entities/Component";
import { Employee } from "../../domain/entities/Employee";
import { BonusCalculationApi } from "../../domain/entities/scenario";
import TableMolecule from "../../molecules/Table";
import "./styles.css";
import ScoreBonusTabPanelMolecule from "../../molecules/ScoreBonusTabPanel";

interface ScoreBonusProps {
  employeeList: Employee[];
  componentsByScenario: ComponentsScenarioEmployee[];
  allComponents: Component[];
  bonusData: BonusCalculationApi[];
}

export const ScoreBonus = ({
  employeeList,
  componentsByScenario,
  allComponents,
  bonusData,
}: ScoreBonusProps) => {
  const [tabOption, setTabOption] = useState(0);
  const currentActiveEmployees = employeeList.filter(
    (employee) => employee.status,
  );
  const gridScoreCol = gridDataGenerator.setColumnsScoreFields(allComponents);
  const gridColScoreFields: string[] = gridScoreCol.reduce(
    (dinamicGridColFields: string[], gridScoreCol) => {
      dinamicGridColFields.push(gridScoreCol.field);
      return dinamicGridColFields;
    },
    [],
  );
  const handleChange = (event: React.SyntheticEvent, newTabOption: number) => {
    setTabOption(newTabOption);
  };
  return (
    <ScoreBonusTabPanelMolecule
      tabs={
        <Tabs
          value={tabOption}
          onChange={handleChange}
          aria-label="Bonus tabs"
          className="mb-5 basic-tabs"
        >
          <Tab
            label="Score"
            id="score-tab"
            aria-controls="score-panel"
            className="tab-label"
            sx={{ color: tabOption === 0 ? "black !important" : "inherit" }}
          />
          <Tab
            label="Bonus"
            id="bonus-tab"
            aria-controls="bonus-panel"
            className="tab-label"
            sx={{ color: tabOption === 1 ? "black !important" : "inherit" }}
          />
        </Tabs>
      }
      tabOption={tabOption}
      scoreTable={
        <TableMolecule
          columns={gridScoreCol}
          rows={gridDataGenerator.getGridScoreRows(
            gridColScoreFields,
            componentsByScenario,
            currentActiveEmployees,
            allComponents,
          )}
          fileName="Score_Table_"
          className="no-bottom-margin"
        />
      }
      bonusTable={
        <TableMolecule
          columns={gridDataGenerator.bonusColumns}
          rows={gridDataGenerator.getGridBonusRows(bonusData)}
          fileName="Bonus_Table_"
          className="no-bottom-margin"
        />
      }
    />
  );
};

export default ScoreBonus;
