import Button from "../../atoms/Button";
import Modal from "../../atoms/Modals";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";

interface Props {
  show: boolean;
  onClose: () => void;
  modalTitle: string;
  modalBody: JSX.Element | undefined;
}

export default function WarningEvaluationModal({
  show,
  onClose,
  modalTitle,
  modalBody,
}: Props) {
  const modalActions = (
    <UnidimensionalLayout
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      style={{
        width: "100%",
      }}
    >
      <Button
        type="button"
        className="button-primary-orange"
        size="small"
        dataTestid="warningEvaluation"
        onClick={onClose}
      >
        Ok
      </Button>
    </UnidimensionalLayout>
  );

  return (
    <Modal
      open={show}
      handleClose={onClose}
      dataTestid="submitEvaluationModal"
      modalTitle={modalTitle}
      modalContent={modalBody}
      modalActions={modalActions}
    />
  );
}
