import InputAtom from "../../atoms/Input";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import Button from "../../atoms/Button";
import Grid from "@mui/material/Grid";
import { SubComponent } from "../../domain/entities/subComponents";
import deleteIcon from "../../presentation/assets/icons-square-delete.png";
import addIcon from "../../presentation/assets/icons-add.png";
import "./formComponentModal.css";

interface FormComponentModalProps {
  submitForm: SubmitHandler<FieldValues>;
  controllerName: string;
  onChange: (value: React.SetStateAction<string>) => void;
  responseError: string;
  subComponentForm: SubComponent[];
  handleFormChange: (index: number, value: string) => void;
  removeSubComponents: (index: number) => void;
  addSubComponent: () => void;
  buttonText: string;
  componentName?: string;
}

export default function FormComponentModalMolecule({
  submitForm,
  controllerName,
  onChange,
  responseError,
  subComponentForm,
  handleFormChange,
  removeSubComponents,
  addSubComponent,
  buttonText,
  componentName,
}: FormComponentModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isComponentNameInput = controllerName === "componentNameInput";
  const error = isComponentNameInput
    ? Boolean(errors?.componentNameInput)
    : Boolean(errors?.editComponentName);
  const helperText = isComponentNameInput
    ? errors.componentNameInput?.message?.toString()
    : errors.editComponentName?.message?.toString();

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(submitForm)}
      data-testid="component-form"
      className="centered-form"
    >
      <Controller
        control={control}
        name={controllerName}
        rules={{
          required:
            componentName === "" || isComponentNameInput
              ? "Please enter a name"
              : "",
          pattern: {
            value: /\w{3,}[\w ]*/,
            message: "Please enter a valid name",
          },
          onChange,
        }}
        render={({ field: { onChange, name } }) => {
          return (
            <>
              <InputAtom
                label="Component Name"
                name={name}
                value={componentName}
                type="text"
                className="full-width-element"
                dataTestid="componentNameInput"
                placeholder="Component X"
                onChange={onChange}
                autoFocus={true}
                error={error}
                helperText={helperText}
              />
              {responseError.length > 0 ? (
                <p
                  data-testid="errorComponentMessage"
                  className="error-message"
                >
                  {responseError}
                </p>
              ) : null}
            </>
          );
        }}
      />
      <Grid className="full-width-element">
        <h4 id="subComponentTitle">Subcomponents</h4>
      </Grid>
      {subComponentForm.map((input, index) => {
        if ((input.status != null && input.status) || isComponentNameInput) {
          const elementName: string = isComponentNameInput
            ? `subCompNum${index}`
            : `esubComp${input.name}Num${index}`;
          return (
            <div className="mb-2 mt-2 full-width-div" key={index}>
              <Controller
                control={control}
                name={elementName}
                rules={{
                  required:
                    input.name === "" || isComponentNameInput
                      ? "Please enter a name"
                      : "",
                  pattern: {
                    value: /\w{3,}[\w ]*/,
                    message: "Please enter a valid name",
                  },
                  onChange: (event) =>
                    handleFormChange(index, event.target.value),
                  value: input.name,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <>
                      <InputAtom
                        className="input-atom-width"
                        label="Subcomponent Name"
                        name={name}
                        value={
                          value === undefined && isComponentNameInput
                            ? ""
                            : input.name
                        }
                        type="text"
                        dataTestid="subcomponentNameInput"
                        placeholder="Subcomponent X"
                        onChange={onChange}
                        autoFocus={true}
                        error={
                          Boolean(errors[String(elementName)]) ?? undefined
                        }
                        helperText={errors[
                          String(elementName)
                        ]?.message?.toString()}
                      />
                    </>
                  );
                }}
              />
              <img
                alt="Delete"
                src={deleteIcon}
                width="20"
                height="20"
                className="position-absolute top-0 end-0"
                onClick={() => removeSubComponents(index)}
              />
            </div>
          );
        }
        return null;
      })}
      <Grid container className="add-subcomponent-button">
        <Grid
          item
          xs={7}
          sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}
        >
          <h5>add subcomponent</h5>
        </Grid>
        <Grid item xs={2}>
          <img
            alt="Add"
            src={addIcon}
            width="30"
            height="30"
            data-testid="addSubComponentIcon"
            onClick={addSubComponent}
          />
        </Grid>
      </Grid>
      <Button
        dataTestid="componentModalButton"
        type="submit"
        className="button-primary-light-blue"
      >
        {buttonText}
      </Button>
    </form>
  );
}
