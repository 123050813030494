import CircularProgressAtom from "../../atoms/CircularProgress";
import BackdropAtom from "../../atoms/Backdrop";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import TitleAtom from "../../atoms/Title";

export interface LoadingMoleculeProps {
  condition: boolean;
  loadingMessage?: string;
}

export default function LoadingMolecule({
  condition,
  loadingMessage,
}: LoadingMoleculeProps) {
  return (
    <BackdropAtom open={condition}>
      <UnidimensionalLayout
        alignItems="center"
        direction="row"
        justifyContent="center"
        style={{ height: "100%" }}
        spacing={1}
      >
        <CircularProgressAtom />
        <TitleAtom variant="h4">{loadingMessage}</TitleAtom>
      </UnidimensionalLayout>
    </BackdropAtom>
  );
}

LoadingMolecule.defaultProps = {
  loadingMessage: "Loading...",
};
