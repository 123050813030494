import TextField from "@mui/material/TextField";
import { StyledEngineProvider } from "@mui/material";
import { useRef, useEffect } from "react";

export const InputVariant = {
  STANDARD: "standard",
  FILLED: "filled",
  OUTLINED: "outlined",
};

export const InputSize = {
  SMALL: "small",
  MEDIUM: "medium",
  NORMAL: undefined,
};

export interface InputProps {
  variant?: "standard" | "filled" | "outlined";
  size?: "small" | "medium";
  id?: string;
  className?: string;
  dataTestid?: string;
  type: string;
  value?: string;
  disabled: boolean;
  defaultValue?: any;
  placeholder?: string;
  min?: number | string;
  max?: number | string;
  key?: number;
  name?: string;
  autoFocus: boolean;
  required: boolean;
  style?: React.CSSProperties;
  error: boolean;
  label?: string;
  helperText?: string;
  onChange: (event: any) => void;
  onKeyPress: (event: any) => void;
  onBlur: (event: any) => void;
}

export default function InputAtom(props: InputProps) {
  const {
    variant,
    size,
    id,
    className,
    dataTestid,
    type,
    value,
    disabled,
    defaultValue,
    min,
    max,
    key,
    name,
    autoFocus,
    placeholder,
    required,
    error,
    label,
    helperText,
    style,
    onChange,
    onKeyPress,
    onBlur,
  } = props;
  const inputElementRef = useRef<any>();
  useEffect(() => {
    if (dataTestid !== undefined) {
      inputElementRef.current.setAttribute("data-testid", dataTestid);
    }
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <TextField
        variant={variant}
        size={size}
        id={id}
        className={className}
        type={type}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        key={key}
        name={name}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        placeholder={placeholder}
        style={style}
        error={error}
        helperText={helperText}
        inputProps={{ min, max }}
        autoFocus={autoFocus}
        required={required}
        inputRef={inputElementRef}
        label={label}
      />
    </StyledEngineProvider>
  );
}

InputAtom.defaultProps = {
  variant: InputVariant.OUTLINED,
  size: InputSize.NORMAL,
  id: undefined,
  className: undefined,
  dataTestid: "inputId",
  type: "text",
  value: undefined,
  disabled: false,
  defaultValue: undefined,
  placeholder: undefined,
  min: undefined,
  max: undefined,
  key: undefined,
  name: undefined,
  autoFocus: false,
  required: false,
  error: false,
  helperText: undefined,
  label: undefined,
  style: {},
  onChange: () => {},
  onKeyPress: () => {},
  onBlur: () => {},
};
