import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../infrastructure/redux/hooks";
import { editScenarioThunk } from "../../../infrastructure/redux/features/scenarioSlice";
import "react-toastify/dist/ReactToastify.css";
import {
  actionIfAuthenticated,
  navigateIfAuthenticated,
} from "../../../utils/authChecker";
import { useTypedDispatch } from "../../../infrastructure/redux/store";
import { onNotify } from "../../helpers/notification-utils";
import Button from "../../../atoms/Button";
import "../../styles/scenario/scenarioBody.css";
import FormScenarioModalMolecule from "../../../molecules/FormScenarioModal";
import EditIcon from "@mui/icons-material/Edit";
import { insertEmployees } from "../../../infrastructure/helpers";
import { toast } from "react-toastify";
import LoaderContainer from "../Loader/LoaderContainer";

const ScenarioBody = () => {
  const currentScenario = useAppSelector(
    (state) => state.scenarioReducer.scenario,
  );
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [responseError, setResponseError] = useState("");
  const message = { success: "", error: "" };

  function loadEmployeeData() {
    setIsLoading(true);
    if (currentScenario?.id !== undefined) {
      insertEmployees(currentScenario?.id)
        .then((response) => {
          toast.success(response.message);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("An error has occurred to insert employees");
          console.log(error);
        });
    }
  }
  const onSubmit = (data: any) => {
    if (currentScenario?.id !== undefined) {
      void dispatch(
        editScenarioThunk({
          scenarioEditedId: currentScenario.id,
          scenarioEditedName: data?.editedScenarioNameInput,
          successToast: showSuccessMessage,
        }),
      );
    }
  };

  const showSuccessMessage = (
    successEdited: boolean = false,
    errorMessage: string = "",
  ) => {
    if (successEdited) {
      message.success = "scenario updated successfully";
      message.error = "";
      setResponseError("");
    } else {
      message.success = "";
      message.error = "error updating scenario name";
      setResponseError(errorMessage);
    }
    onNotify(message);
  };

  return (
    <LoaderContainer conditions={[isLoading]} loadingMessage="Loading...">
      <Container className="vh-100">
        <br></br>
        <h2>Options</h2>
        <hr className="line"></hr>
        <Container>
          <FormScenarioModalMolecule
            submitForm={onSubmit}
            formClassName="grid-form"
            controllerName="editedScenarioNameInput"
            setResponseError={setResponseError}
            divClassName="grid-div"
            responseError={responseError}
            buttonClassName="button-primary-light-blue third-column"
            button={<EditIcon />}
            inputAtomClassName="full-width-input-atom"
            currentScenarioName={currentScenario?.name}
          ></FormScenarioModalMolecule>
        </Container>
        <br></br>
        <h2>Features</h2>
        <hr className="line"></hr>

        <Row className="justify-content-center">
          <Col className="text-center text-md-right">
            <Button
              className="button-primary-light-blue"
              onClick={() => {
                void (async () => {
                  await navigateIfAuthenticated("/components", navigate);
                })();
              }}
            >
              Components
            </Button>{" "}
          </Col>
          <Col className="text-center ">
            <Button
              className="button-primary-light-blue"
              dataTestid="loadEmployeeData"
              onClick={() => {
                void (async () => {
                  await actionIfAuthenticated({
                    myFunction: loadEmployeeData,
                    myNavigate: navigate,
                  });
                })();
              }}
            >
              Load employee data
            </Button>{" "}
          </Col>
          <Col className="text-center text-md-right">
            <Button
              className="button-primary-light-blue"
              onClick={() => {
                void (async () => {
                  await navigateIfAuthenticated("/bonusCalculation", navigate);
                })();
              }}
            >
              Bonus Calculation
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </LoaderContainer>
  );
};

export default ScenarioBody;
