import Container from "@mui/material/Container";
import { SxProps, Theme } from "@mui/material";
export interface ContainerProps {
  children?: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fixed?: boolean;
  disableGutters?: boolean;
  className?: string;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  dataTestid?: string;
}

export default function ContainerAtom({
  children,
  maxWidth,
  fixed,
  disableGutters,
  className,
  style,
  sx,
  dataTestid,
}: ContainerProps) {
  return (
    <Container
      maxWidth={maxWidth}
      fixed={fixed}
      disableGutters={disableGutters}
      className={className}
      style={style}
      sx={sx}
      data-testid={dataTestid}
    >
      {children}
    </Container>
  );
}

ContainerAtom.defaultProps = {
  maxWidth: "lg",
  fixed: false,
  disableGutters: false,
  className: undefined,
  style: {},
};
