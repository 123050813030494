import { Container, Table } from "react-bootstrap";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../../domain/entities/Component";
import { Employee } from "../../../domain/entities/Employee";
import {
  getEmployeeComponent,
  getComponentScore,
} from "../../helpers/components-utils";
import "../../styles/employeeDetail/EmployeeListStyle.css";

interface Props {
  employeeList: Employee[];
  currentEmployee: Employee;
  selectEmployee: Function;
  componentsByScenario: ComponentsScenarioEmployee[];
  allComponents: Component[];
}

const EmployeeListComponent = ({
  employeeList,
  currentEmployee,
  selectEmployee,
  componentsByScenario,
  allComponents,
}: Props) => {
  const EmployeeTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className="top_text">Employees</th>
          <th className="top_text centered_text">Total Weigthed Score</th>
        </tr>
      </thead>
    );
  };

  const EmployeeTableBody = () => {
    return (
      <tbody>
        {employeeList.map((employee) => {
          const employeeComponents = getEmployeeComponent({
            employee,
            componentsByScenario,
            allComponents,
          });
          const componentsScore =
            getComponentScore(employeeComponents).toFixed(2);
          const isSelected = employee.id === currentEmployee?.id;
          const emptyScore = Number(componentsScore) === -1;

          let isAvailable: boolean = true;
          if (!employee.status) {
            isAvailable = false;
          }
          return (
            <tr
              key={employee.id}
              className={`${isSelected ? "selected" : ""}
              ${emptyScore ? "dark_red" : ""}
              ${!isAvailable ? "deactivated" : ""} `}
            >
              <td
                data-testid="employeeNameCell"
                className="pointer_cursor"
                onClick={() => {
                  selectEmployee(employee);
                }}
              >
                {employee.name}
              </td>
              <td className="centered_text">
                {emptyScore || !employee.status ? "-" : componentsScore}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <Container className="mt-5">
      <Table hover>
        <EmployeeTableHeader />
        <EmployeeTableBody />
      </Table>
    </Container>
  );
};

export default EmployeeListComponent;
