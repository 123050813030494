import UnidemensionalLayout from "../../atoms/UnidimensionalLayout";
import TitleAtom from "../../atoms/Title";

export interface TitleAndCaptionProps {
  titleText: string;
  caption: string;
  style?: React.CSSProperties;
}

export default function TitleAndCaptionMolecule({
  titleText,
  caption,
  style,
}: TitleAndCaptionProps) {
  return (
    <UnidemensionalLayout
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <TitleAtom variant="h5">{titleText}</TitleAtom>
      <TitleAtom variant="caption" style={style}>
        {caption}
      </TitleAtom>
    </UnidemensionalLayout>
  );
}

TitleAndCaptionMolecule.defaultProps = {
  style: {},
};
