import { Autocomplete } from "@mui/material";
import { ReactNode } from "react";

export const AutocompleteSize = {
  SMALL: "small",
  MEDIUM: "medium",
};

export interface AutocompleteProps {
  renderInput: (params: any) => ReactNode;
  options: Array<{ label: string; value: string }> | [];
  autoHighlight?: boolean;
  classes?: Object;
  className?: string;
  disabled?: boolean;
  disablePortal?: boolean;
  fullWidth?: boolean;
  groupBy: ((value: any) => string) | undefined;
  getOptionLabel?:
    | ((value: { label: string; value: string }) => string)
    | undefined;
  id?: string;
  onChange: (event: any, data: { label: string; value: string } | null) => void;
  size: "small" | "medium";
  style?: React.CSSProperties;
  value?: { label: string; value: string } | null | undefined;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function AutocompleteAtom({
  renderInput,
  options,
  autoHighlight,
  classes,
  className,
  disabled,
  disablePortal,
  fullWidth,
  groupBy,
  getOptionLabel,
  id,
  onChange,
  size,
  style,
  value,
  open,
  onOpen,
  onClose,
}: AutocompleteProps) {
  return (
    <Autocomplete
      renderInput={renderInput}
      options={options}
      autoHighlight={autoHighlight}
      classes={classes}
      className={className}
      disabled={disabled}
      disablePortal={disablePortal}
      fullWidth={fullWidth}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      id={id}
      isOptionEqualToValue={
        options.length !== 0
          ? (option, value) => option.value === value.value
          : undefined
      }
      onChange={onChange}
      size={size}
      style={style}
      value={value}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
}

AutocompleteAtom.defaultProps = {
  renderInput: () => {},
  autoHighlight: true,
  classes: {},
  className: undefined,
  disablePortal: true,
  disabled: false,
  fullWidth: false,
  groupBy: undefined,
  getOptionLabel: undefined,
  id: undefined,
  onChange: () => {},
  required: false,
  style: {},
  size: AutocompleteSize.SMALL,
  value: undefined,
  open: true,
  onOpen: () => {},
  onClose: () => {},
};
