import { UpdateComponentsUseCase } from "../../application/use_cases/UpdateComponentsUseCase";
import { UpdateComponentUseCase } from "../../application/use_cases/UpdateComponentUseCase";
import { ComponentsScenarioEmployee } from "../../domain/entities/Component";
import { ComponentAxiosRepository } from "../implementation/componentAxiosRepository";

export async function updateComponents(
  componentsList: ComponentsScenarioEmployee[],
  scenarioId: number,
) {
  const componentRepository = new ComponentAxiosRepository();
  const saveComponentsUseCase = new UpdateComponentsUseCase(
    componentRepository,
  );
  const result = await saveComponentsUseCase.run(componentsList, scenarioId);
  return result;
}

export async function updateComponent(
  component: ComponentsScenarioEmployee,
  scenarioId: number,
) {
  const componentRepository = new ComponentAxiosRepository();
  const saveComponentUseCase = new UpdateComponentUseCase(componentRepository);
  const result = await saveComponentUseCase.run(component, scenarioId);
  return result;
}
