import employeeImg from "../../assets/employee_info_logo.png";

const EmployeeInfoThumbnail = () => {
  return (
    <img
      src={employeeImg}
      className="employee-thumbnail"
      width="120"
      height="auto"
    />
  );
};

export default EmployeeInfoThumbnail;
