import { styled } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export interface AccordionDetailProps {
  children: React.ReactNode;
  classes?: Object;
  className?: string;
  style?: React.CSSProperties;
}

export default function AccordionDetailAtom({
  children,
  classes,
  className,
  style,
}: AccordionDetailProps) {
  return (
    <AccordionDetails className={className} style={style} classes={classes}>
      {children}
    </AccordionDetails>
  );
}

AccordionDetailAtom.defaultProps = {
  classes: {},
  className: undefined,
  style: {},
};
