import { useState, createContext } from "react";
import { Question } from "../domain/entities/Question";
import { evaluationServices } from "../services/evaluationServices";
import { toast } from "react-toastify";

interface ItemProps {
  children: string | JSX.Element | JSX.Element[];
}

export const RemovedQuestionContext = createContext({
  removedQuestions: [] as Array<Partial<Question>>,
  addRemovedQuestion: (
    newRemovedQuestion: Partial<Question>,
    removeFromUI: (id: string) => void,
    updateQuestions: Function,
  ) => {},
  ClearRemovedQuestionsContext: () => {},
});

export const RemovedQuestionsContext = ({ children }: ItemProps) => {
  const [removedQuestions, setRemovedQuestions] = useState<
    Array<Partial<Question>>
  >([]);

  function addRemovedQuestion(
    newRemovedQuestion: Partial<Question>,
    removeFromUI: (id: string) => void,
    updateQuestions: Function,
  ) {
    setRemovedQuestions((prevItems) => [...prevItems, newRemovedQuestion]);
    if (
      newRemovedQuestion.id !== undefined &&
      newRemovedQuestion.type !== undefined &&
      newRemovedQuestion.feedbackRoundId !== undefined
    ) {
      const validatedQuestionId: string = newRemovedQuestion.id;
      evaluationServices
        .deleteDraftQuestions(
          newRemovedQuestion.feedbackRoundId,
          validatedQuestionId.replace(/-/g, ""),
          newRemovedQuestion.type,
        )
        .then((response) => {
          removeFromUI(validatedQuestionId);
          updateQuestions();
          toast.success(response);
        })
        .catch(() => {
          toast.error("error deleting the question");
        });
    }
  }

  function setEmpty(newRemovedQuestion: Question[]) {
    setRemovedQuestions(newRemovedQuestion);
  }

  function ClearRemovedQuestionsContext() {
    setEmpty([]);
  }

  return (
    <RemovedQuestionContext.Provider
      value={{
        removedQuestions,
        addRemovedQuestion,
        ClearRemovedQuestionsContext,
      }}
    >
      {children}
    </RemovedQuestionContext.Provider>
  );
};
