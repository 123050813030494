import ContainerAtom from "../../atoms/Container";
import TabPanelBase from "../../atoms/TabPanel";
import "./styles.css";

interface ScoreBonusTabPanelMoleculeProps {
  tabs: JSX.Element;
  tabOption: number;
  scoreTable: JSX.Element;
  bonusTable: JSX.Element;
}

export const ScoreBonusTabPanelMolecule = ({
  tabs,
  tabOption,
  scoreTable,
  bonusTable,
}: ScoreBonusTabPanelMoleculeProps) => {
  return (
    <ContainerAtom dataTestid="BonusTabTables" maxWidth="xl" disableGutters>
      {tabs}
      <TabPanelBase index={0} value={tabOption} className="zero-padding">
        <ContainerAtom className="gridDimension" maxWidth="xl" disableGutters>
          {scoreTable}
        </ContainerAtom>
      </TabPanelBase>
      <TabPanelBase index={1} value={tabOption} className="zero-padding">
        <ContainerAtom className="gridDimension" maxWidth="xl" disableGutters>
          {bonusTable}
        </ContainerAtom>
      </TabPanelBase>
    </ContainerAtom>
  );
};

export default ScoreBonusTabPanelMolecule;
