import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Container } from "react-bootstrap";
import { Component } from "../../../domain/entities/Component";
import "../../styles/employeeDetail/EmployeeListStyle.css";

interface Props {
  componentList: Component[];
  currentComponent: Component;
  selectComponent: Function;
}

const ComponentListComponent = ({
  componentList,
  currentComponent,
  selectComponent,
}: Props) => {
  return (
    <Container className="mt-5">
      <Typography variant="h5">Components List</Typography>
      <List component="nav" aria-label="component folder">
        <ListItem secondaryAction={<Typography>Weight</Typography>}>
          <ListItemText primary="Name" />
        </ListItem>
      </List>
      <List component="nav" aria-label="component folder">
        {componentList.map((component) => {
          const isSelected = component.id === currentComponent?.id;
          return (
            <ListItem
              key={component.id}
              secondaryAction={
                <Typography data-testid="component-weight-element">
                  {component.weight}
                </Typography>
              }
              disablePadding
            >
              <ListItemButton
                selected={isSelected}
                onClick={() => {
                  selectComponent(component);
                }}
              >
                <ListItemText
                  primary={
                    <Typography data-testid="component-name-element">
                      {component.name}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default ComponentListComponent;
