import PaperSurfaceAtom from "../../atoms/PaperSurface";
import TitleAtom from "../../atoms/Title";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import "./styles.css";

export interface FeedBackRoundSectionProps {
  evaluationTitle: string;
  className: string;
  children?: React.ReactNode;
}

export default function FeedBackRoundSectionBase({
  evaluationTitle,
  className,
  children,
}: FeedBackRoundSectionProps) {
  return (
    <PaperSurfaceAtom
      className={`paper-container ${className ?? ""}`}
      square={true}
      variant="outlined"
      elevation={0}
    >
      <UnidimensionalLayout
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <TitleAtom variant="h5" gutterBottom={true}>
          {evaluationTitle}
        </TitleAtom>
        {children}
      </UnidimensionalLayout>
    </PaperSurfaceAtom>
  );
}

FeedBackRoundSectionBase.defaultProps = {};
