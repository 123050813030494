import { useEffect, useState } from "react";
import ContainerAtom from "../../atoms/Container";
import TitleAtom from "../../atoms/Title";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import { Answer } from "../../domain/entities/Answer";
import { evaluationServices } from "../../services/evaluationServices";
import { Question, QuestionsAllcategory } from "../../domain/entities/Question";
import LoadingMolecule from "../../molecules/Loading";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import SharedFeedbackList from "../../organisms/SharedFeedbackList";
import DividerAtom from "../../atoms/Divider";
import AnswerContainerOrganism from "../../organisms/AnswerContainer";
import "./styles.css";

const FeedbackScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [peerQuestions, setPeerQuestions] = useState<Question[]>([]);
  const [selfQuestions, setSelfQuestions] = useState<Question[]>([]);
  const [peerAnswers, setPeerAnswers] = useState<Answer[]>([]);
  const [selfAnswers, setSelfAnswers] = useState<Answer[]>([]);
  const [sharedFeedbackRounds, setSharedFeedbackRounds] = useState<
    FeedbackRound[]
  >([]);
  const [selectedFeedbackRoundId, setselectedFeedbackRoundIdId] =
    useState<string>("");

  const fetchQuestions = async (
    feedbackRoundId: string,
  ): Promise<QuestionsAllcategory> => {
    const questions: QuestionsAllcategory =
      await evaluationServices.getEvaluationQuestions(feedbackRoundId);
    return questions;
  };

  const fetchAnswers = async (feedbackRoundId: string) => {
    return await evaluationServices.getMyFeedbackRoundAnswers(feedbackRoundId);
  };

  const fetchSharedFeedbackRounds = async () => {
    return await evaluationServices.getSharedFeedbackRounds();
  };

  const fetchQuestionsAndAnswers = async () => {
    const questions = await fetchQuestions(selectedFeedbackRoundId);
    const answers = await fetchAnswers(selectedFeedbackRoundId);
    return { questions, answers };
  };

  const fetchFeedbacksAndEmployee = async () => {
    const sharedFeedbackRounds = await fetchSharedFeedbackRounds();
    sharedFeedbackRounds.sort(
      (currentFeedbackRound, nextFeedbackRound) =>
        new Date(nextFeedbackRound.endDate).getTime() -
        new Date(currentFeedbackRound.endDate).getTime(),
    );
    setSharedFeedbackRounds(sharedFeedbackRounds);
  };

  useEffect(() => {
    fetchFeedbacksAndEmployee().finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedFeedbackRoundId !== "") {
      setIsLoading(true);
      fetchQuestionsAndAnswers()
        .then((responseData) => {
          setSelfQuestions(responseData.questions.selfQuestions);
          setPeerQuestions(responseData.questions.peerQuestions);
          setSelfAnswers(responseData.answers.selfAnswers);
          setPeerAnswers(responseData.answers.peerAnswers);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedFeedbackRoundId]);

  return (
    <>
      <ContainerAtom disableGutters maxWidth="xl">
        <TitleAtom variant="h3" gutterBottom className="centered-top-padding">
          Feedback review
        </TitleAtom>
        <DividerAtom flexItem />
        <UnidimensionalLayout className="grid-layout" alignItems="flex-start">
          <ContainerAtom disableGutters>
            <TitleAtom variant="h5" gutterBottom className="padding-small">
              Feedback Rounds
            </TitleAtom>
            <SharedFeedbackList
              sharedFeedbackRounds={sharedFeedbackRounds}
              onClick={setselectedFeedbackRoundIdId}
              selectedFeedbackRoundId={selectedFeedbackRoundId}
            />
          </ContainerAtom>
          <DividerAtom orientation="vertical" flexItem />
          <ContainerAtom disableGutters>
            {selectedFeedbackRoundId !== "" ? (
              <>
                <AnswerContainerOrganism
                  answers={selfAnswers}
                  questions={selfQuestions}
                  title="Self-evaluation"
                  className="spacing-bottom-px"
                  message="You do not have a self-evaluation"
                />
                <AnswerContainerOrganism
                  answers={peerAnswers}
                  questions={peerQuestions}
                  title="Peer feedback"
                  className="spacing-bottom-px spacing-bottom-percentage"
                  message="You have not received feedback"
                />
              </>
            ) : sharedFeedbackRounds.length > 0 ? (
              <p className="centered-spacing">
                Select a Feedback round to show results
              </p>
            ) : (
              <p className="centered-spacing">
                The Feedback round has not been shared yet
              </p>
            )}
          </ContainerAtom>
        </UnidimensionalLayout>
      </ContainerAtom>
      <LoadingMolecule condition={isLoading} />
    </>
  );
};

export default FeedbackScreen;
