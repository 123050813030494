import UnidimensionalLayout from "../../atoms/UnidimensionalLayout/index";
import RatingAtom from "../../atoms/Rating";
import LegendAtom from "../../atoms/Legend";
import { useState } from "react";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";
import { Answer } from "../../domain/entities/Answer";

export interface RatingQuestionProps {
  questionTitle: string;
  disabled?: boolean;
  dataTestId?: string;
  score: number;
  questionId?: string;
  questionIndex: number;
  employeeId?: string;
  maxRating: number;
  className?: string;
  handleChangeAnswer: (
    index: number,
    textResponse: string,
    scoreResponse: number,
    senderUser: EvaluationEmployee,
    response: (
      value: Answer,
      index: number,
      array: Answer[],
    ) => number | string | undefined,
    questionType?: string,
    questionId?: string,
    employeeId?: string,
    optionalEvaluation?: string,
  ) => void;
  senderUser: EvaluationEmployee;
}

export default function RatingQuestion({
  questionTitle,
  dataTestId,
  score,
  questionId,
  questionIndex,
  employeeId,
  handleChangeAnswer,
  senderUser,
  maxRating,
  disabled,
  className,
}: RatingQuestionProps) {
  const [value, setValue] = useState<number>(score);
  const handleOnChange = (newScore: number) => {
    if (newScore !== null) {
      setValue(newScore);
      handleChangeAnswer(
        questionIndex,
        "",
        newScore,
        senderUser,
        (answer) => answer.scoreResponse,
        questionId,
        employeeId,
        dataTestId,
      );
    }
  };

  return (
    <UnidimensionalLayout
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      style={{
        marginBottom: "15px",
      }}
    >
      <LegendAtom>
        <b>{questionTitle}</b>
      </LegendAtom>
      <div className={className}>
        <RatingAtom
          size="large"
          dataTestId={dataTestId}
          value={value}
          max={maxRating}
          disabled={disabled}
          onChange={(event, newScore) => {
            if (newScore !== null) {
              handleOnChange(newScore);
            }
          }}
        />
      </div>
    </UnidimensionalLayout>
  );
}

RatingQuestion.defaultProps = {
  dataTestId: "questionMolecule",
};
