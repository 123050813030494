import { ComponentsScenarioEmployee } from "../../domain/entities/Component";
import { ComponentRepository } from "../../domain/repositories/componentRepository";

export class UpdateComponentUseCase {
  private readonly componentRepository: ComponentRepository;

  public constructor(componentRepository: ComponentRepository) {
    this.componentRepository = componentRepository;
  }

  public async run(component: ComponentsScenarioEmployee, scenarioId: number) {
    return await this.componentRepository.updateComponent(
      component,
      scenarioId,
    );
  }
}
