import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import RadioBase from "../../atoms/Radio";

export interface RadioGroupProps {
  legend: string;
  className?: string;
  name: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => void;
  value?: string;
  options: Array<{
    value: string;
    label: string;
    labelPlacement: "bottom" | "end" | "start" | "top";
  }>;
  row?: boolean;
}

export default function RadioGroupBase({
  legend,
  className,
  value,
  name,
  options,
  onChange,
  row,
}: RadioGroupProps) {
  return (
    <>
      <FormControl component="fieldset" className={className ?? ""}>
        <FormLabel component="legend">{legend}</FormLabel>
        <RadioGroup
          row={row}
          aria-label="position"
          name={name}
          value={value ?? ""}
          onChange={onChange}
        >
          {options.map((option, index) => {
            return (
              <RadioBase
                value={option.value}
                label={option.label}
                labelPlacement={option.labelPlacement}
                key={`radio-${legend}-${index}`}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
}

RadioGroupBase.defaultProps = {
  legend: "",
  className: undefined,
  name: "",
  onChange: () => {},
  value: "",
  options: [],
  row: false,
};
