import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const EmployeeDetailHeader = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Row>
        <Col md="auto">
          <a className="redirect" href="/">
            Home
          </a>
        </Col>
        <Col md="auto">
          <a
            className="redirect"
            onClick={() => {
              navigate("scenarioSelected");
            }}
          >
            Scenario menu
          </a>
        </Col>
        <Col md="auto">
          <a className="redirect" href="/">
            Options
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeDetailHeader;
