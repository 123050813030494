import { ScenarioRepository } from "../../domain/repositories/scenarioRepository";
import { Scenario } from "../../domain/entities/scenario";

export class EditScenarioPreferences {
  private readonly scenarioRepository: ScenarioRepository;

  public constructor(scenarioRepository: ScenarioRepository) {
    this.scenarioRepository = scenarioRepository;
  }

  public async run(
    id: number,
    generalBonus: number,
    performanceBonus: number,
    payrollProrated: number,
  ): Promise<Scenario> {
    return await this.scenarioRepository.editPreferences(
      id,
      generalBonus,
      performanceBonus,
      payrollProrated,
    );
  }
}
