import { Col, Row } from "react-bootstrap";
import Button from "../../../atoms/Button";
import Modal from "../../../atoms/Modals";
import { deleteScenarioThunk } from "../../../infrastructure/redux/features/scenarioSlice";
import { useTypedDispatch } from "../../../infrastructure/redux/store";
import "../../styles/componentView/createComponentModal.css";
import "../../styles/confirmationModal.css";

interface Props {
  id: number;
  name: string;
  show: boolean;
  confirmationToast: (confirmation: string) => void;
  onClose: () => void;
  onAccept: () => void;
}

export default function DeleteScenarioModal({
  id,
  name,
  show,
  confirmationToast,
  onClose,
  onAccept,
}: Props) {
  const dispatch = useTypedDispatch();

  const handleSubmit = () => {
    void dispatch(
      deleteScenarioThunk({
        scenarioDeletedId: id,
        scenarioDeletedName: name,
        confirmationToast,
      }),
    );
  };

  const modalBody = (
    <>
      <Row id="confirmationModalRow">
        Are you sure you want to delete {name} scenario?
      </Row>
      <Row style={{ padding: 10 }}>
        <Col className="text-center">
          <Button
            className="button-primary-light-blue"
            dataTestid="confirmationDeleteScenario"
            onClick={handleSubmit}
            onClickCapture={onAccept}
          >
            Yes
          </Button>
        </Col>
        <Col className="text-center">
          <Button
            className="button-primary-light-blue"
            dataTestid="noDeleteScenario"
            onClick={onClose}
          >
            No
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Modal
      open={show}
      handleClose={onClose}
      dataTestid="deleteScenarioModal"
      modalContent={modalBody}
      classNameContent="modal-content"
    />
  );
}
