import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BonusCalculationResponse,
  Scenario,
} from "../../../domain/entities/scenario";
import { sortScenarios } from "../../../presentation/components/scenario/sortScenarios";
import { getScenarioStorage } from "../../../presentation/helpers/localStorage-utils";
import {
  createScenario,
  deleteScenario,
  editScenario,
  editScenarioPreferences,
  getBonusCalculation,
  getScenarios,
} from "../../helpers";

interface BonusRequirements {
  scenarioId: number;
  performanceBonus: number;
  payrollProrated: number;
}

interface ScenarioState {
  scenario?: Scenario | null;
  generalBonusAvailable?: BonusCalculationResponse | null;
  status?: string;
  scenarios: Scenario[] | null;
}

const initialState: ScenarioState = {
  scenario: getScenarioStorage(),
  generalBonusAvailable: { general_bonus_total: 0, bonus_calculation: [] },
  status: "",
  scenarios: [],
};

export const getGeneralBonusAvailableThunk = createAsyncThunk(
  "scenario/updategeneralBonusAvailableThunk",
  async ({
    scenarioId,
    performanceBonus,
    payrollProrated,
  }: BonusRequirements) => {
    try {
      const bonusData = await getBonusCalculation(
        scenarioId,
        performanceBonus,
        payrollProrated,
      );
      return bonusData as unknown as BonusCalculationResponse;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const editScenarioPreferecesThunk = createAsyncThunk(
  "scenario/editScenarioPreferecesThunk",
  async (
    {
      scenarioId,
      generalBonus,
      newPerformanceBonus,
      newGeneralBonus,
      successCallback,
    }: any,
    { getState },
  ) => {
    try {
      const scenarioReturned = await editScenarioPreferences(
        scenarioId,
        generalBonus,
        newPerformanceBonus,
        newGeneralBonus,
      );
      successCallback();
      return scenarioReturned as unknown as Scenario;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const createScenarioThunk = createAsyncThunk(
  "scenario/createScenarioThunk",
  async ({ scenarioName, callBack }: any, { getState }) => {
    try {
      const scenarioCreated = await createScenario(scenarioName);
      callBack();
      return scenarioCreated as unknown as Scenario;
    } catch (error) {
      callBack(String(error));
      console.log(error);
      return null;
    }
  },
);

export const getScenariosThunk = createAsyncThunk(
  "scenario/getScenariosThunk",
  async ({ sortOrder }: any, { getState }) => {
    try {
      const scenarios = await getScenarios();
      const scenariosSorted = sortScenarios(scenarios, sortOrder);
      return scenariosSorted as unknown as Scenario[];
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const deleteScenarioThunk = createAsyncThunk(
  "scenario/deleteScenarioThunk",
  async (
    { scenarioDeletedId, scenarioDeletedName, confirmationToast }: any,
    { getState },
  ) => {
    const state: any = getState();
    const actualScenarios: Scenario[] = state.scenarioReducer.scenarios;
    const updatedScenarios: Scenario[] = actualScenarios.filter(
      (scenario: Scenario) => scenario.id !== scenarioDeletedId,
    );
    try {
      await deleteScenario(scenarioDeletedId, scenarioDeletedName);
      confirmationToast("deleted");
      return updatedScenarios as unknown as Scenario[];
    } catch (error) {
      confirmationToast();
      console.log(error);
      return null;
    }
  },
);

export const editScenarioThunk = createAsyncThunk(
  "scenario/editScenarioThunk",
  async (
    { scenarioEditedId, scenarioEditedName, successToast }: any,
    { getState },
  ) => {
    const state: any = getState();
    const actualScenario: Scenario = state.scenarioReducer.scenario;
    try {
      const scenarioEdited = await editScenario(
        scenarioEditedId,
        scenarioEditedName,
      );
      successToast(true);
      return scenarioEdited as unknown as Scenario;
    } catch (error) {
      successToast(false, String(error));
      console.log(error);
      return actualScenario;
    }
  },
);

export const scenarioSlice = createSlice({
  name: "scenarioSlice",
  initialState,
  reducers: {
    scenarioUpdate: (state, action: PayloadAction<Scenario>) => {
      state.scenario = action.payload;
      localStorage.setItem("scenario", JSON.stringify(action.payload));
    },
    clearBonusResults: (state) => {
      state.generalBonusAvailable = {
        general_bonus_total: 0,
        bonus_calculation: [],
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGeneralBonusAvailableThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getGeneralBonusAvailableThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.generalBonusAvailable = action.payload;
      })
      .addCase(getGeneralBonusAvailableThunk.rejected, (state, action) => {
        state.status = "failed";
      });

    builder
      .addCase(editScenarioPreferecesThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(editScenarioPreferecesThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scenario = action.payload;
        localStorage.setItem("scenario", JSON.stringify(action.payload));
      })
      .addCase(editScenarioPreferecesThunk.rejected, (state, action) => {
        state.status = "failed";
      });

    builder
      .addCase(createScenarioThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createScenarioThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scenario = action.payload;
        localStorage.setItem("scenario", JSON.stringify(action.payload));
      })
      .addCase(createScenarioThunk.rejected, (state, action) => {
        state.status = "failed";
      });

    builder
      .addCase(getScenariosThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getScenariosThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scenarios = action.payload;
      })
      .addCase(getScenariosThunk.rejected, (state, action) => {
        state.status = "failed";
      });

    builder
      .addCase(deleteScenarioThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteScenarioThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scenarios = action.payload;
      })
      .addCase(deleteScenarioThunk.rejected, (state, action) => {
        state.status = "failed";
      });

    builder
      .addCase(editScenarioThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(editScenarioThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scenario = action.payload;
      })
      .addCase(editScenarioThunk.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { scenarioUpdate, clearBonusResults } = scenarioSlice.actions;
export default scenarioSlice.reducer;
