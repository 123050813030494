import { ComponentRepository } from "../../domain/repositories/componentRepository";
import { ComponentsScenarioEmployee } from "../../domain/entities/Component";

export class GetComponentsScenarioEmployee {
  private readonly componentRepository: ComponentRepository;

  public constructor(componentRepository: ComponentRepository) {
    this.componentRepository = componentRepository;
  }

  public async run(scenarioId: number): Promise<ComponentsScenarioEmployee[]> {
    return await this.componentRepository.getComponentsScenarioEmployee(
      scenarioId,
    );
  }
}
