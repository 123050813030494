import Button from "../../atoms/Button";
import Modal from "../../atoms/Modals";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";

interface Props {
  modalTitle: string;
  modalBody: JSX.Element;
  sendButtonText: string;
  cancelButtonText: string;
  show: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export default function SubmitEvaluationModal({
  modalTitle,
  modalBody,
  sendButtonText,
  cancelButtonText,
  show,
  onClose,
  onAccept,
}: Props) {
  const modalActions = (
    <UnidimensionalLayout
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      style={{
        width: "100%",
      }}
    >
      <Button
        type="button"
        className="button-primary-green"
        size="small"
        dataTestid="confirmationSendEvaluation"
        onClick={onAccept}
      >
        {sendButtonText}
      </Button>
      <Button
        type="button"
        size="small"
        className="button-primary-orange"
        dataTestid="notSendEvaluation"
        onClick={onClose}
      >
        {cancelButtonText}
      </Button>
    </UnidimensionalLayout>
  );

  return (
    <Modal
      open={show}
      handleClose={onClose}
      dataTestid="submitEvaluationModal"
      modalTitle={modalTitle}
      modalContent={modalBody}
      modalActions={modalActions}
    />
  );
}
