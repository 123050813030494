import AccordionMolecule from "../../molecules/Accordion";
import UnidemensionalLayout from "../../atoms/UnidimensionalLayout";
import "./styles.css";
import AnswerRenderer from "../AnswerRenderer";
import { Answer } from "../../domain/entities/Answer";
import { Question } from "../../domain/entities/Question";
import { AccordionStates } from "../../templates/FeedbackRoundResultsScreen/FeedbackRoundResultsScreen";

export interface ReceivedAnswersProps {
  receivedAnswers: Answer[];
  questions: Question[];
  isOpen?: boolean;
  accordionStates?: AccordionStates;
  setAccordionStates?: React.Dispatch<React.SetStateAction<AccordionStates>>;
}

export default function ReceivedAnswers({
  receivedAnswers,
  questions,
  isOpen,
  accordionStates,
  setAccordionStates,
}: ReceivedAnswersProps) {
  const toggleAccordion = (employeeId: string) => {
    if (setAccordionStates !== undefined) {
      setAccordionStates((prevState: AccordionStates) => ({
        ...prevState,
        [employeeId]: !prevState[`${employeeId}`],
      }));
    }
  };

  return (
    <>
      {questions.map((question, index) => {
        const title = (
          <div className="title-container">
            <span className="first-space">
              <b>Question:</b> {question.description}
            </span>
          </div>
        );
        const children = (
          <UnidemensionalLayout
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <AnswerRenderer
              answers={receivedAnswers}
              employeeId={`${index}`}
              question={question}
            />
          </UnidemensionalLayout>
        );
        return (
          <AccordionMolecule
            titleProps={{
              children: title,
            }}
            detailProps={{
              children,
            }}
            key={index}
            className="borders-accordion"
            expanded={
              accordionStates !== undefined
                ? accordionStates[`${index}`] || isOpen
                : undefined
            }
            onChange={() => {
              return toggleAccordion(`${index}`);
            }}
          />
        );
      })}
    </>
  );
}
