import {
  ClassNameMap,
  ListItemButton,
  ListItemButtonClasses,
  SxProps,
  Theme,
} from "@mui/material";

interface ListItemButtonProps {
  children: React.ReactNode;
  alignItems?: "center" | "flex-start";
  autoFocus?: boolean;
  classes?: Partial<ListItemButtonClasses> & Partial<ClassNameMap<never>>;
  dense?: boolean;
  disabled?: boolean;
  disableGutters?: boolean;
  divider?: boolean;
  focusVisibleClassName?: string;
  selected?: boolean;
  sx?: SxProps<Theme>;
}

const ListItemButtonAtom = ({
  children,
  alignItems,
  autoFocus,
  classes,
  dense,
  disabled,
  disableGutters,
  divider,
  focusVisibleClassName,
  selected,
  sx,
}: ListItemButtonProps) => {
  return (
    <ListItemButton
      alignItems={alignItems}
      autoFocus={autoFocus}
      classes={classes}
      dense={dense}
      disabled={disabled}
      disableGutters={disableGutters}
      divider={divider}
      focusVisibleClassName={focusVisibleClassName}
      selected={selected}
      sx={sx}
    >
      {children}
    </ListItemButton>
  );
};

export default ListItemButtonAtom;
