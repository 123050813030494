import Stack from "@mui/material/Stack";

export interface UnidimensionalLayoutProps {
  children?: React.ReactNode;
  direction?: "column-reverse" | "column" | "row-reverse" | "row";
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  spacing?: number | string;
  style?: React.CSSProperties;
  className?: string;
}

export default function UnidemensionalLayout({
  children,
  direction,
  alignItems,
  justifyContent,
  spacing,
  style,
  className,
}: UnidimensionalLayoutProps) {
  return (
    <Stack
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      spacing={spacing}
      style={style}
      className={className}
    >
      {children}
    </Stack>
  );
}

UnidemensionalLayout.defaultProps = {
  children: "",
  direction: "column",
  alignItems: "baseline",
  justifyContent: "flex-start",
  spacing: 0,
  style: {},
  className: undefined,
};
