import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";

const validateStatus = (status: number): boolean => {
  return status >= 200 && status < 300;
};

async function parseAxiosResponse(resp: AxiosResponse) {
  const status = resp.status;
  const data = await resp.data;

  if (validateStatus(status) && validateStatus(data.statusCode)) {
    return resp.data;
  }

  const error = data.error ?? "We’ve done something wrong";
  if (resp.status !== 200) toast.error(error, { toastId: error });
  return error;
}

export default class ApiHelper {
  private readonly withCredentials = true;

  public async request(method: string, url: string, payload?: any) {
    try {
      const axiosRequest = await axios({
        method,
        url,
        data: payload,
        withCredentials: this.withCredentials,
      });
      return await parseAxiosResponse(axiosRequest);
    } catch (error: any) {
      const errorMessage = error.response.data.detail;
      toast.error(errorMessage, {
        toastId: errorMessage,
      });
      return error;
    }
  }
}
