import React from "react";
import ReactDOM from "react-dom/client";
import "./presentation/styles/index.css";
import App from "./presentation/components/App";
import reportWebVitals from "./presentation/common/reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./infrastructure/redux/store";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    {/* <Router> */}
    <Provider store={store}>
      <App />
      <ToastContainer
        data-testid="toastContainer"
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Provider>
    {/* </Router> */}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
