import { SxProps, Theme } from "@mui/material";
import ListItemButtonAtom from "../../atoms/ListItemButton";
import ListItemTextAtom from "../../atoms/ListItemText";

interface ListItemButtonProps {
  primary: string;
  onClick: () => void;
  selected: boolean;
  sx?: SxProps<Theme>;
}

const ListItemMolecule = ({
  primary,
  onClick,
  selected,
  sx,
}: ListItemButtonProps) => {
  return (
    <ListItemButtonAtom selected={selected} sx={sx}>
      <ListItemTextAtom primary={primary} onClick={onClick} />
    </ListItemButtonAtom>
  );
};

export default ListItemMolecule;
