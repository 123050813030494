import RatingAnswer from "../../molecules/RatingAnswer";
import TextAreaAtom from "../../atoms/TextArea";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import "./style.css";
import { Answer } from "../../domain/entities/Answer";
import { Question } from "../../domain/entities/Question";

export interface AnswerRendererProps {
  answers: Answer[];
  employeeId?: string;
  question: Question;
}

const MAX_LINES_BEFORE_SCROLL = 3;
const NUMBER_OF_DECIMALS = 2;

export default function AnswerRenderer({
  answers,
  employeeId,
  question,
}: AnswerRendererProps) {
  let renderedAnswer: JSX.Element = <></>;
  const answersToQuestion = answers.filter((answer) => {
    return answer.questionId === question.id;
  });

  function calculateAverageScore(answers: Answer[]) {
    const totalScore = answers
      .map((answer) => {
        return answer.scoreResponse ?? 0;
      })
      .reduce((accumulatedScore, answerScore) => {
        return accumulatedScore + answerScore;
      }, 0);
    const scoreAverage = (totalScore / answers.length).toFixed(
      NUMBER_OF_DECIMALS,
    );
    return +scoreAverage;
  }

  const sharingOption = question.sharingScoreOptions ?? "";
  switch (question.category) {
    case "text":
      renderedAnswer = (
        <>
          {answersToQuestion.map((answer, index) => {
            const key = `q${index}Container${employeeId ?? ""}`;
            const textResponse = answer.textResponse ?? "";
            return (
              textResponse !== "" && (
                <UnidimensionalLayout
                  className="answer-container"
                  direction="column"
                  alignItems="stretch"
                  justifyContent="flex-start"
                  key={key}
                >
                  <TextAreaAtom
                    variant="standard"
                    maxRows={MAX_LINES_BEFORE_SCROLL}
                    value={textResponse}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{ readOnly: true }}
                  />
                </UnidimensionalLayout>
              )
            );
          })}
        </>
      );
      break;
    case "rating":
      if (sharingOption === "shareIndividual") {
        renderedAnswer = (
          <>
            {answersToQuestion.map((answer, index) => {
              const key = `q${index}Container${employeeId ?? ""}`;
              const scoreResponse = answer.scoreResponse ?? -1;
              return (
                scoreResponse >= 0 && (
                  <UnidimensionalLayout
                    className="answer-container"
                    direction="column"
                    alignItems="stretch"
                    justifyContent="flex-start"
                    key={key}
                  >
                    <RatingAnswer
                      key={employeeId}
                      description="Your received score: "
                      averageScore={Number(answer.scoreResponse)}
                      max={Number(question.scoreRangeTo)}
                    />
                  </UnidimensionalLayout>
                )
              );
            })}
          </>
        );
      } else {
        renderedAnswer = (
          <RatingAnswer
            key={employeeId}
            description="Your average score: "
            averageScore={calculateAverageScore(answersToQuestion)}
            max={Number(question.scoreRangeTo)}
          />
        );
      }
      break;
    default:
      renderedAnswer = <></>;
      break;
  }

  return <>{renderedAnswer}</>;
}
