import { useState, createContext } from "react";
import { Question } from "../domain/entities/Question";

interface ItemProps {
  children: string | JSX.Element | JSX.Element[];
}

export const ItemContext = createContext({
  items: [] as Array<Partial<Question>>,
  setItems: (newItems: Question[]) => {},
  addItem: (newItem: Partial<Question>) => {},
  updateItem: (newItem: Partial<Question>) => {},
  updateItems: (
    updaterFunction: (
      prevItems: Array<Partial<Question>>,
    ) => Array<Partial<Question>>,
  ) => {},
  removeItem: (id: string) => {},
  ClearContext: () => {},
});

export const QuestionsContext = ({ children }: ItemProps) => {
  const [items, setItems] = useState<Array<Partial<Question>>>([]);

  function setAllItems(newItem: Question[]) {
    setItems(newItem);
  }

  function addItem(newItem: Partial<Question>) {
    setItems((prevItems) => [...prevItems, newItem]);
  }

  function updateItem(newItem: Partial<Question>) {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === newItem.id ? newItem : item)),
    );
  }

  function updateItems(
    updaterFunction: (
      prevItems: Array<Partial<Question>>,
    ) => Array<Partial<Question>>,
  ) {
    setItems((prevItems) => {
      const updatedItems = updaterFunction(prevItems);
      const updatedItemIds = new Set(
        updatedItems.map((item) => String(item.id)),
      );
      const notUpdatedItems = prevItems.filter(
        (item) => !updatedItemIds.has(String(item.id)),
      );
      return [...notUpdatedItems, ...updatedItems];
    });
  }

  function removeItem(id: string) {
    setItems((prevItems) => prevItems.filter((value) => value.id !== id));
  }

  function ClearContext() {
    setAllItems([]);
  }

  return (
    <ItemContext.Provider
      value={{
        items,
        setItems,
        addItem,
        updateItem,
        updateItems,
        removeItem,
        ClearContext,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};
