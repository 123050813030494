import { Route, Routes, Navigate } from "react-router-dom";
import Unauthorized from "./Unauthorized";
import HomeComponent from "../home/Home";
import ComponentView from "../component/ComponentView";
import ScenarioBody from "../scenario/ScenarioBody";
import BonusCalculation from "../bonusCalculation/BonusCalculation";
import ListScenarios from "../scenario/ListScenarios";
import EmployeeDetail from "../employeeDetail/EmployeeDetailContainer";
import ComponentDetail from "../component/ComponentDetailContainer";
import Login from "../login/Login";
import { ADMIN_ROLE, ANY_ROLE } from "../../../utils/constants";
import { useUserAPI } from "../../../context/userAPIContext";
import { ThemeProvider } from "@emotion/react";
import EvaluationScreen from "../../../templates/EvaluationScreen/index";
import FeedBackRoundScreen from "../../../templates/FeedBackRoundScreen";
import HomeBonusComponent from "../home/bonus/HomeBonus";
import FeedbackScreen from "../../../templates/FeedbackScreen";
import YourFeedbackRoundView from "../../../templates/YourFeedbackRoundScreen/YourFeedbackRoundView";
import { QuestionsContext } from "../../../utils/feedbackRoundContext";
import FeedbackRoundResultsPage from "../../../pages/FeedbackRoundResultsScreenPage";
import { RemovedQuestionsContext } from "../../../utils/removeQuestionsContext";
import { EvaluationRequestScreen } from "../../../templates/EvaluationRequestScreen";

const ProtectedRoute = ({ element, role = ANY_ROLE }: any) => {
  const { user, theme } = useUserAPI();
  if (user != null && user.detail === "Could not validate credentials") {
    return <Navigate to="/login" replace />;
  }
  if (
    user != null &&
    (role === ANY_ROLE || user.roles.performance.includes(role) === true)
  ) {
    return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
  }
  if (
    user != null &&
    role !== ANY_ROLE &&
    user.roles.performance.includes(role) === false
  ) {
    return <Navigate to="/401" replace />;
  }
  return <Navigate to="/login" replace />;
};

export const LoginRoute = () => {
  const { user, theme } = useUserAPI();
  if (user == null || user.detail === "Could not validate credentials") {
    return (
      <ThemeProvider theme={theme}>
        <Login />
      </ThemeProvider>
    );
  }
  return <Navigate to="/home" />;
};

function Body() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<LoginRoute />} />
      <Route path="/401" element={<Unauthorized />} />
      <Route path="/evaluations" element={<EvaluationScreen />} />
      <Route path="/feedback" element={<FeedbackScreen />} />
      <Route
        path="/feedback-round"
        element={
          <ProtectedRoute
            element={
              <RemovedQuestionsContext>
                <QuestionsContext>
                  <FeedBackRoundScreen />
                </QuestionsContext>
              </RemovedQuestionsContext>
            }
            role={ADMIN_ROLE}
          />
        }
      />
      <Route
        path="/home"
        element={<ProtectedRoute element={<HomeComponent />} role={ANY_ROLE} />}
      />
      <Route
        path="/homeBonus"
        element={
          <ProtectedRoute element={<HomeBonusComponent />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/scenarioSelected"
        element={
          <ProtectedRoute element={<ScenarioBody />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/components"
        element={
          <ProtectedRoute element={<ComponentView />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/scenario"
        element={
          <ProtectedRoute element={<ScenarioBody />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/bonusCalculation"
        element={
          <ProtectedRoute element={<BonusCalculation />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/listScenarios"
        element={
          <ProtectedRoute element={<ListScenarios />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/employee-detail"
        element={
          <ProtectedRoute element={<EmployeeDetail />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/component-detail"
        element={
          <ProtectedRoute element={<ComponentDetail />} role={ADMIN_ROLE} />
        }
      />
      <Route
        path="/your-feedback-rounds"
        element={
          <ProtectedRoute
            element={<YourFeedbackRoundView />}
            role={ADMIN_ROLE}
          />
        }
      />
      <Route
        path="/feedback-results/:feedbackRoundId"
        element={
          <ProtectedRoute
            element={<FeedbackRoundResultsPage />}
            role={ADMIN_ROLE}
          />
        }
      />
      <Route
        path="/evaluation-request"
        element={
          <ProtectedRoute
            element={<EvaluationRequestScreen />}
            role={ADMIN_ROLE}
          />
        }
      />
    </Routes>
  );
}

export default Body;
