import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { capitalizeFirsLetter } from "../../presentation/helpers/string-utils";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridColDef,
  GridRowsProp,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import moment from "moment";
import { getUserPermissions } from "../../infrastructure/authorization";
import { EvaluationEmployee } from "../../domain/entities/EvaluationEmployee";
import AtomicButton from "../../atoms/Button";
import ContainerAtom from "../../atoms/Container";
import PaperSurfaceAtom from "../../atoms/PaperSurface";
import Table from "../../atoms/Tables/Index";
import TitleAtom from "../../atoms/Title";
import { toast } from "react-toastify";
import "./styles.scss";
import { evaluationServices } from "../../services/evaluationServices";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import { FeedbackRoundCompletionStatus } from "../../domain/constants/FeedbackRoundCompletionStatus";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import LoadingMolecule from "../../molecules/Loading";
import ConfirmationModal from "../../molecules/ShareFeedbackConfirmationModal";
import { useNavigate } from "react-router-dom";
import { navigateIfAuthenticated } from "../../utils/authChecker";

interface actionCellProps {
  feedbackRound: FeedbackRound;
  shareFeedback: boolean;
}

function YourFeedbackRoundView() {
  const navigate = useNavigate();
  function ExportCSVButton() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          style={{ color: "blue" }}
          csvOptions={{
            fileName: "Your_Feedback_Rounds",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const [selectedFeedbackRound, setSelectedFeedbackRound] =
    useState<FeedbackRound>();
  const handleShareFeedbackClick = (feedbackRound: FeedbackRound) => {
    setSelectedFeedbackRound(feedbackRound);
    setOpen(true);
  };
  const feedbackRoundColumns: GridColDef[] = [
    {
      field: "name",
      flex: 0.9,
      headerName: "Name",
      cellClassName: "cell-extra-left-padding",
      headerClassName: "iconless-bold-column-header header-extra-left-padding",
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "startDate",
      flex: 0.6,
      headerName: "Start date",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "endDate",
      flex: 0.6,
      headerName: "End date",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "evaluationStatus",
      flex: 0.7,
      headerName: "Evaluation status",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "status",
      flex: 0.4,
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      headerClassName: "iconless-bold-column-header",
      disableColumnMenu: true,
      sortable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams<actionCellProps>) => (
        <UnidimensionalLayout
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          style={{
            width: "100%",
            padding: "2%",
            flexWrap: "wrap",
            rowGap: "5px",
          }}
        >
          <AtomicButton
            type="button"
            size="small"
            className="button-primary-orange"
            onClick={() => {
              void (async () => {
                await navigateIfAuthenticated(
                  `/feedback-results/${String(params.value?.feedbackRound.id)}`,
                  navigate,
                );
              })();
            }}
          >
            See Results
          </AtomicButton>

          <AtomicButton
            type="button"
            size="small"
            className="button-primary-light-blue"
            disabled={params.value?.shareFeedback}
            onClick={() => {
              if (params.value?.feedbackRound !== undefined) {
                handleShareFeedbackClick(params.value.feedbackRound);
              }
            }}
          >
            Share Feedback
          </AtomicButton>
        </UnidimensionalLayout>
      ),
    },
  ];

  const [feedbackRounds, setFeedbackRounds] = useState<FeedbackRound[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [externalEmployee, setExternalEmployee] =
    useState<EvaluationEmployee>();

  const fetchExternalEmployee = async () => {
    const getUserAuth = await getUserPermissions();
    const employee = await evaluationServices.getExternalEmployee(
      getUserAuth.email,
    );
    return employee;
  };

  const handleFeedbackUpdate = (updatedFeedbackRound: FeedbackRound) => {
    const nextFeedbackRounds = feedbackRounds.map((feedbackRound) => {
      if (feedbackRound.id === updatedFeedbackRound.id) {
        return updatedFeedbackRound;
      }
      return feedbackRound;
    });
    setFeedbackRounds(nextFeedbackRounds);
  };

  const handleClose = (shareFeedback: boolean) => {
    setOpen(false);
    if (shareFeedback) {
      if (selectedFeedbackRound !== undefined) {
        setIsLoading(true);
        const updatedAt = moment().utc().format("YYYY-MM-DD HH:mm:ss");
        const updatedFeedbackRound: FeedbackRound = {
          ...selectedFeedbackRound,
          shareFeedback: true,
          updatedAt,
          updatedBy: externalEmployee?.externalId ?? "",
        };
        evaluationServices
          .updateFeedbackRoundSharingStatus(updatedFeedbackRound)
          .then(() => {
            handleFeedbackUpdate(updatedFeedbackRound);
            toast.success("Feedback round shared successfully");
          })
          .catch((error) => {
            toast.error(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    Promise.allSettled([
      evaluationServices.getAllFeedbackRounds(),
      fetchExternalEmployee(),
    ])
      .then((results) => {
        const [feedbackRoundResults, externalEmployeeResults] = results;
        if (feedbackRoundResults.status === "fulfilled") {
          const newFeedbackRounds = [
            ...feedbackRounds,
            ...feedbackRoundResults.value,
          ];
          setFeedbackRounds(newFeedbackRounds);
        } else {
          toast.error(feedbackRoundResults.reason.message);
          console.error(feedbackRoundResults.reason.message);
        }
        if (externalEmployeeResults.status === "fulfilled") {
          setExternalEmployee(externalEmployeeResults.value);
        } else {
          toast.error(externalEmployeeResults.reason.message);
          console.error(externalEmployeeResults.reason.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => {
        const loadingState = !isLoading;
        setIsLoading(loadingState);
      });
  }, []);

  const isButtonDisabled = (feedbackRound: FeedbackRound) => {
    return (
      feedbackRound.shareFeedback ||
      feedbackRound.activeToShow === FeedbackRoundCompletionStatus.ACTIVE ||
      feedbackRound.activeToShow === FeedbackRoundCompletionStatus.PENDING
    );
  };

  const feedbackRoundRows: GridRowsProp = feedbackRounds.map(
    (feedbackRound) => {
      const actionCellProps: actionCellProps = {
        feedbackRound,
        shareFeedback: isButtonDisabled(feedbackRound),
      };
      const gridRow = {
        id: feedbackRound.id,
        name: feedbackRound.name,
        startDate: feedbackRound.startDate,
        endDate: feedbackRound.endDate,
        evaluationStatus: `${feedbackRound.currentEvaluationsPerformed} out of ${feedbackRound.expectedEvaluationsToPerform} (${feedbackRound.completionPercentage}%)`,
        status: capitalizeFirsLetter(feedbackRound.activeToShow),
        actions: actionCellProps,
      };
      return gridRow;
    },
  );

  return (
    <>
      <ContainerAtom className="container-element">
        <UnidimensionalLayout
          direction="column"
          justifyContent="space-evenly"
          alignItems="stretch"
          style={{ width: "100%" }}
        >
          <TitleAtom
            variant="h4"
            style={{ fontWeight: "bold", margin: "50px 0 20px 0" }}
            gutterBottom
          >
            History of feedback rounds
          </TitleAtom>
          <PaperSurfaceAtom styles={{ boxShadow: "none" }}>
            <Table
              rows={feedbackRoundRows}
              columns={feedbackRoundColumns}
              pageSize={50}
              components={{ Toolbar: ExportCSVButton }}
              className="no-bottom-margin"
              autoHeight
              getRowHeight={() => "auto"}
              sortingOrder={["asc", "desc"]}
            />
          </PaperSurfaceAtom>
          <AtomicButton
            type="button"
            size="small"
            className="custom-button"
            href="/feedback-round"
            dataTestid="add-button"
          >
            <Add />
          </AtomicButton>
        </UnidimensionalLayout>
      </ContainerAtom>
      {selectedFeedbackRound !== undefined ? (
        <ConfirmationModal
          feedbackRound={selectedFeedbackRound}
          open={open}
          onClose={handleClose}
        />
      ) : null}

      <LoadingMolecule condition={isLoading} />
    </>
  );
}

export default YourFeedbackRoundView;
