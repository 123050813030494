import React from "react";
import "./style.css";
import classnames from "classnames";
import {
  Box,
  Button,
  CircularProgress,
  StyledEngineProvider,
} from "@mui/material";
import { lightBlue } from "@mui/material/colors";
export const ButtonType = {
  BUTTON: "button",
  RESET: "reset",
  SUBMIT: "submit",
};

export const ButtonVariant = {
  TEXT: "text",
  OUTLINED: "outlined",
  CONTAINED: "contained",
};

export const ButtonSize = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

interface Props {
  type: "button" | "reset" | "submit" | undefined;
  variant: "text" | "outlined" | "contained" | undefined;
  size: "small" | "medium" | "large" | undefined;
  onClick: () => void;
  onClickCapture: () => void;
  children: React.ReactNode;
  className: string;
  disabled: boolean;
  dataTestid: string;
  href: string;
  hasSpinner?: boolean;
}

const AtomicButton = (props: Props) => {
  const {
    type,
    variant,
    size,
    onClick,
    onClickCapture,
    children,
    className,
    disabled,
    dataTestid,
    href,
    hasSpinner = false,
  } = props;
  const classProps: string = classnames(className);
  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ position: "relative" }}>
        <Button
          style={{ textTransform: "none", marginLeft: 2, marginRight: 2 }}
          type={type}
          variant={variant}
          size={size}
          onClick={onClick}
          onClickCapture={onClickCapture}
          className={classProps}
          disabled={disabled}
          data-testid={dataTestid}
          href={href}
        >
          {children}
        </Button>
        {hasSpinner && (
          <CircularProgress
            size={24}
            sx={{
              color: lightBlue[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </StyledEngineProvider>
  );
};

AtomicButton.defaultProps = {
  type: ButtonType.BUTTON,
  variant: ButtonVariant.CONTAINED,
  size: ButtonSize.MEDIUM,
  onClick: null,
  onClickCapture: null,
  className: "",
  disabled: false,
  dataTestid: "buttonId",
  href: "",
};

export default AtomicButton;
