import {
  ClassNameMap,
  Divider,
  DividerClasses,
  SxProps,
  Theme,
} from "@mui/material";

interface DividerProps {
  absolute?: boolean;
  classes?: Partial<DividerClasses> & Partial<ClassNameMap<never>>;
  flexItem?: boolean;
  light?: boolean;
  orientation?: "horizontal" | "vertical";
  sx?: SxProps<Theme>;
  textAlign?: "left" | "right" | "center";
  variant?: "inset" | "fullWidth" | "middle";
}

const DividerAtom = ({
  absolute,
  classes,
  flexItem,
  light,
  orientation,
  sx,
  textAlign,
  variant,
}: DividerProps) => {
  return (
    <Divider
      absolute={absolute}
      classes={classes}
      flexItem={flexItem}
      light={light}
      orientation={orientation}
      sx={sx}
      textAlign={textAlign}
      variant={variant}
    />
  );
};

export default DividerAtom;
