import { Container, Col, Row } from "react-bootstrap";
import { Employee } from "../../../domain/entities/Employee";
import EmployeeInfoThumbnail from "./EmployeeInfoThumbnail";

const EmployeeInfoTable = (employee: Employee) => {
  return (
    <Container>
      <Row className="mt-3">
        <Col className="col-align-left">
          <h3>Employee Data</h3>
        </Col>
        <hr />
      </Row>
      <Row className="mt-1">
        <Col>
          <Row className="mt-2" md={1}>
            <Col className="col-align-left">
              <p>Name:</p>
            </Col>
            <Col className="col-align-left">
              <p>Last Salary:</p>
            </Col>
            <Col className="col-align-left">
              <p>Months At Ioet:</p>
            </Col>
            <Col className="col-align-left">
              <p>Status:</p>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="mt-2" md={1}>
            <Col className="col-align-left">
              <p>{employee.name}</p>
            </Col>
            <Col className="col-align-left">
              <p>${employee.lastSalary}</p>
            </Col>
            <Col className="col-align-left">
              <p>{employee.monthsIoet}</p>
            </Col>
            <Col className="col-align-left">
              <p>{String(employee.status)}</p>
            </Col>
          </Row>
        </Col>
        <Col>
          <EmployeeInfoThumbnail />
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeInfoTable;
