import { FormControlLabel, Radio, RadioProps } from "@mui/material";

const LABEL_PLACEMENT = {
  BOTTOM: "bottom",
  END: "end",
  START: "start",
  TOP: "top",
};

export interface RadioPropsBase {
  label: React.ReactNode;
  labelPlacement: "bottom" | "end" | "start" | "top";
  onChange: (event: object) => void;
  radioProps?: RadioProps;
  value: string;
}

export default function RadioBase({
  label,
  labelPlacement,
  onChange,
  radioProps,
  value,
}: RadioPropsBase) {
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      onChange={onChange}
      control={<Radio {...radioProps} />}
      value={value}
    />
  );
}

RadioBase.defaultProps = {
  label: "",
  labelPlacement: LABEL_PLACEMENT.END,
  onChange: () => {},
  radioProps: { color: "primary", size: "small" },
  value: undefined,
};
