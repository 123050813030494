import {
  Component,
  ComponentsScenarioEmployee,
} from "../../domain/entities/Component";
import { Employee } from "../../domain/entities/Employee";

interface GetEmployeeComponents {
  employee: Employee;
  componentsByScenario: ComponentsScenarioEmployee[];
  allComponents: Component[];
}

export const getEmployeeComponent = ({
  employee,
  componentsByScenario,
  allComponents,
}: GetEmployeeComponents) => {
  const componets: Component[] = [];
  componentsByScenario.forEach((comp) => {
    allComponents.forEach((allComp) => {
      if (allComp.id === comp.componentId && comp.employeeId === employee.id) {
        componets.push({
          ...allComp,
          description: comp.comment,
          score: comp.score,
          scenarioId: comp.scenarioId,
        });
      }
    });
  });

  return componets;
};

export const getComponentScore = (components: Component[]) => {
  const score = components.map((component) => component.score);
  return components.reduce(
    (total, { score, weight }) =>
      total + (score !== undefined ? score * (weight / 100) : 0),
    String(score) === "" ? -1 : 0,
  );
};
