import {
  Backdrop,
  CircularProgress,
  Container,
  styled,
  Typography,
} from "@mui/material";

const LoadingContainer = styled(Container)(
  () => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,
);

const CircularProgressLoading = styled(CircularProgress)(
  () => `
    margin-right: 1em;
  `,
);

interface LoaderProps {
  conditions: boolean[];
  children: JSX.Element;
  loadingMessage?: string;
}

const LoaderContainer = ({
  conditions,
  children,
  loadingMessage = "Loading...",
}: LoaderProps) => {
  return (
    <>
      {children}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={conditions.some(Boolean)}
      >
        <LoadingContainer>
          <CircularProgressLoading />
          <Typography variant="h4">{loadingMessage}</Typography>
        </LoadingContainer>
      </Backdrop>
    </>
  );
};
export default LoaderContainer;
