import axios from "axios";
import EnvManager from "../config/envManager";

const backend = axios.create({
  baseURL: EnvManager.AUTH_URL,
  withCredentials: true,
});

export const logoutUser = async () => {
  try {
    await backend.get("/authn/logout");
  } catch (error) {
    console.log(error);
  }
};
