import React from "react";
import Grid from "@mui/material/Grid";

import "../../styles/login.css";
import IoetLogo from "../layout/IoetLogo";
import EnvManager from "../../../config/envManager";
import { APP_NAME } from "../../../utils/constants";
import Button from "../../../atoms/Button";

const loginURL = `${EnvManager.AUTH_URL}authn/login/${APP_NAME}`;

function Login() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "80vh" }}
    >
      <IoetLogo />
      <Button
        className="login-button"
        variant="contained"
        size="large"
        href={loginURL}
      >
        Login
      </Button>
    </Grid>
  );
}

export default Login;
