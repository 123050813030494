import { ComponentRepository } from "../../domain/repositories/componentRepository";

export class CheckComponentById {
  private readonly componentRepository: ComponentRepository;

  public constructor(componentRepository: ComponentRepository) {
    this.componentRepository = componentRepository;
  }

  public async run(componentId: number): Promise<number> {
    return await this.componentRepository.checkComponentById(componentId);
  }
}
