import { Col, Row } from "react-bootstrap";
import Button from "../../../atoms/Button";
import Modal from "../../../atoms/Modals";
import "../../styles/componentView/createComponentModal.css";
import "../../styles/confirmationModal.css";

interface Props {
  activateUser: () => void;
  show: boolean;
  onClose: () => void;
}

export default function ActivateEmployeeModal({
  activateUser,
  show,
  onClose,
}: Props) {
  const modalBody = (
    <>
      <Row id="confirmationModalRow">
        Are you sure that you want activate this User?
      </Row>
      <Row style={{ padding: 10 }}>
        <Col className="text-center">
          <Button
            className="button-primary-light-blue"
            onClick={activateUser}
            onClickCapture={onClose}
          >
            Yes
          </Button>
        </Col>
        <Col className="text-center">
          <Button className="button-primary-light-blue" onClick={onClose}>
            No
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Modal
      open={show}
      handleClose={onClose}
      dataTestid="confirmationModalActiveEmp"
      modalContent={modalBody}
      classNameContent="modal-content"
    />
  );
}
