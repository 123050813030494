import logoIoet from "../../assets/performance-logo.png";
import { useLocation } from "react-router-dom";

const IoetLogo = () => {
  const location = useLocation();
  const path = location.pathname;
  switch (path) {
    case "/login": {
      return (
        <img
          alt="Performance"
          src={logoIoet}
          width="500"
          height="auto"
          style={{ margin: "50px" }}
        />
      );
    }
    default:
      return (
        <img
          alt="Performance"
          src={logoIoet}
          width="250"
          height="auto"
          style={{ marginBottom: "20px", marginTop: "20px" }}
        />
      );
  }
};

export default IoetLogo;
