import InputAtom from "../../atoms/Input";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import Typography from "@mui/material/Typography";
import "./style.css";

interface ScoringRangeSelectorProps {
  from: (value: React.SetStateAction<string>) => void;
  to: (value: React.SetStateAction<string>) => void;
  fromValue: string;
  toValue: string;
}
export default function ScoringRangeSelector({
  from,
  to,
  fromValue,
  toValue,
}: ScoringRangeSelectorProps) {
  return (
    <UnidimensionalLayout
      direction="column"
      alignItems="stretch"
      justifyContent="center"
      spacing={1.5}
    >
      <div className="legend">
        <Typography>Select scoring range</Typography>
      </div>
      <UnidimensionalLayout
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <InputAtom
          placeholder="From"
          required={true}
          type="number"
          min={1}
          className="input-width"
          onChange={(event) => from(event.target.value)}
          value={fromValue}
        />
        <InputAtom
          placeholder="To"
          required={true}
          type="number"
          min={1}
          className="input-width"
          onChange={(event) => to(event.target.value)}
          value={toValue}
        />
      </UnidimensionalLayout>
    </UnidimensionalLayout>
  );
}
